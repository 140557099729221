<template>
  <div class="payouts-table">
    <div class="payouts-header">
      <div class="date">Date</div>
      <div class="invoice">Invoice #</div>
      <div class="status">Status</div>
      <div class="value">Value</div>
    </div>
    <div class="payouts-row" v-for="(item, index) in payouts" :key="index">
      <div class="date">{{item.created_at.format("DD/MM/YYYY h:mm a")}}</div>
      <div class="invoice">#{{item.id}}</div>
      <div class="status">{{item.status_name}}</div>
      <div class="value">{{formatAmount(item.amount)}}</div>
    </div>
  </div>
</template>
<script>
import Payout from "../models/Payout";
export default {
  data: function () {
    return {
      payouts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadPayouts();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    formatAmount(value) {
      if(!value && isNaN(value)) return ''
      return (value/100).toFixed(1) + process.env.VUE_APP_CURRENCY_SIGN
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPayouts();
      }
    },
    loadPayouts() {
      this.isLoading = true;
      this.$get(
        "/payouts?page=" + this.page,
        (data) => {
          let payouts = [...this.payouts];
          for (let obj of data.data) {
            payouts.push(new Payout(obj));
          }
          this.payouts = payouts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.payouts-table {
  .payouts-header, .payouts-row {
    display: flex;
    border-bottom: 1px solid #D9D9D9;
    padding: 10px 0;
  }


  > div {
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    
  }

  .date {
    width: 80px;
    text-align: center;
  }

  .invoice {
    width: 100px;
    color: #02B5F5;
    padding-left: 20px;
  }

  .status {
    width: calc(100% - 200px);
    padding-left: 20px;
  }

  .value {
    width: 100px;
    padding-left: 20px;
  }
}

@media (max-width: 576px) { 
  .payouts-table {
    .payouts-header, .payouts-row {
      padding: 5px 0;
    }


    > div {
      font-size: 70%;
      line-height: 10px;
      
    }

    .date {
      width: 70px;
      text-align: center;
    }

    .invoice {
      width: 80px;
      color: #02B5F5;
      padding-left: 5px;
    }

    .status {
      width: 100px;
      padding-left: 5px;
    }

    .value {
      width: 70px;
      padding-left: 5px;
    }
  }
}
</style>
