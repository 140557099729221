import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class ChargeBack {


    id = 0
    amount = 0
    info = {}
    created_at = dayjs()
    updated_at = dayjs()
    status_name = ''
    username=''
    penalty = 0

    constructor(data) {
        this.id = data.id
        this.created_at = dayjs(data.created_at)
        this.updated_at = dayjs(data.updated_at)
        this.info = data.info
        this.amount = data.amount
        this.username = data.user.username
        this.status_name = data.event_status
        this.penalty = data.penalty
    }

}