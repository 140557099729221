<template>
<div class="nd-landing">
    <div class="nd-landing-container">
      <div class="nd-landing-bg hide-on-mobile">
        <b-img class="nd-landing-fire2" :src="fanspicyFire2"/>
        <div class="nd-landing-logo-container">
          <b-img class="nd-landing-logo" :src="logoFanspicy"/>
          <div class="nd-landing-slogan">{{ /*$t("general.signup-slogan")*/ 'your favorite creators' }}</div>
          <b-img class="nd-landing-fire" :src="fanspicyFire"/>
        </div>
      </div>
      <div class="nd-landing-form">
        <b-img class="nd-landing-form-fire2 hide-on-desktop" :src="fanspicyFire2"/>
        <div class="nd-landing-mobile-logo hide-on-desktop">
          <b-img :src="logoFanspicy"/>
          <div class="nd-landing-mobile-slogan">{{ /*$t("general.signup-slogan")*/ 'your favorite creators' }}</div>
        </div>
        <router-view @handleError="handleError" @setMobileHeight="setMobileHeight" name="login"></router-view>
      </div>
    </div>
    <div class="nd-landing-footer">
      <app-footer></app-footer>
    </div>
</div>
</template>
<style lang="scss">
.nd-landing {
  background: #F5F5FB;
  width: 100vw;
  min-height: 100vh;

  .nd-landing-container {
    display: flex;
    min-height: calc(100vh - 74px);

    .nd-landing-bg {
      position: relative;
      display: flex;
      flex-direction:column;
      width: 52vw;
      align-items: center;
      height: inherit;
      overflow: hidden;

      .nd-landing-logo-container{
        position: absolute;
        bottom: 0;
        text-align: center;
        .nd-landing-logo {
          width: 384px;
          height: 156px; 
        }

        .nd-landing-fire {
          width: 292px;
          height: 470px;
          display: block;
          margin-top: 50px;
          margin-left: auto;
          margin-right: 1px;
      
        }

        .nd-landing-slogan {
          margin-top: 13px;
          font-style: normal;
          font-weight: 500;
          font-size: 36px;
          line-height: 47px;
          color: #333333;
        }

      }

      .nd-landing-fire2{
        position: absolute;
        width: 700px;
        height: auto;
        right: 297px;
        bottom: -120px;
      }


    }

    .nd-landing-form {
      width: 48vw;
      height: inherit;
      display: flex;
      align-items: center;
      justify-content: left;
      padding-top: 100px;
      padding-bottom: 100px;
    }
  }

  .nd-landing-footer {
    z-index: 1;
  }
}

@media (max-width: 1200px) {
  .nd-landing {
    .nd-landing-container {
      min-height: calc(100vh - 174px);
      width: 100%;

      .nd-landing-form {
        width: 100%;
        align-items: center;
        flex-direction: column;
        margin: 0 16px;
        padding-top: 0;
        padding-bottom: 0;

        .nd-landing-form-fire2 {
          position: absolute;
          width: 434px;
          height: 699px;
          left: -102px;
          top: 90px;
          z-index: 0;
        }
      }
    }

    .nd-landing-footer {

    }
  }

  .nd-landing-mobile-logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 70px 0 10px 0;
    img {
      width: 160px;
      height: 65px;
    }

    .nd-landing-mobile-slogan {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #333333;
    }
  }
}
</style>

<script>
import AppFooter from "../AppFooter.vue";
export default {
  components: { AppFooter },
  data() {
    return {
      isErrorState: false,
      mobileBlockStandartHeight: 0,
      mobileBlockCurrentHeight: 0
    }
  },
  mounted() {
   /* this.$nextTick(() => {
      if(!this.mobileBlockStandartHeight) this.mobileBlockStandartHeight = this.$refs.mobileBlock.clientHeight
    })*/
  },
  methods: {
    setMobileHeight(height) {
      this.mobileBlockCurrentHeight = height
    },

    handleError() {
      this.isErrorState = true;
    }
  },
  computed: {
    appname() {
      return process.env.VUE_APP_APP_NAME;
    },
    logoFanspicy() {
      return require("@/assets/logo-fanspicy.svg");
    },
    fanspicyFire() {
      return require("@/assets/icons/new-design/nd-fanspicy-fire.svg");
    },

    fanspicyFire2() {
      return require("@/assets/icons/new-design/nd-fanspicy-fire2.svg");
    },
  },
};
</script>
