<template>
  <b-card>
    <div v-if="data.user.verification" class="mb-3">
      <b-row class="my-1" v-for="(row, key) in data.user.verification.info" :key="key">
        <b-col sm="3">
          <label :for="`row-${key}`">{{ $t('general.'+key) }}:</label>
        </b-col>
        <b-col sm="9">
          <b-form-input disabled :id="`row-${key}`" :value="row"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="my-1">
        <b-col sm="3">
          <label :for="`row-country`">{{ $t('general.country') }}:</label>
        </b-col>
        <b-col sm="9">
          <b-form-input disabled :id="`row-country`" :value="data.user.verification.country"></b-form-input>
        </b-col>
      </b-row>
    </div>


    <b-card no-body v-if="data.info">
      <b-tabs card>
        <b-tab :title="$t('admin.payment') + ': ' + $t('admin.type_'+paymentTypes[data.info.type] ? paymentTypes[data.info.type] : '')" active>
          <b-row class="my-1" v-for="(row, key) in data.info.info" :key="key">
            <b-col sm="3">
              <label :for="`row-${key}`">{{ $t('admin.'+key) }}:</label>
            </b-col>
            <b-col sm="9">
              <b-form-input disabled :id="`row-${key}`" :value="row"></b-form-input>
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </b-card>
  </b-card>
</template>

<script>
import {mapTypes} from "../models/Payout";

export default {
  name: "UiAdminPayoutDetail",
  props: {
    data: {
      type: Object
    },
  },
  computed: {
    paymentTypes() {
      return mapTypes()
    }
  }
}
</script>

<style scoped>

</style>