<template>
<div class="nd-background nd-form-uni nd-start-form fogot">
  <div>
    <h1>Forgot password</h1>
    <b-form class="nd-form-uni-body" @submit.prevent="beforeSubmit" id="forgot_form">
      <div v-if="errors._ && errors._.length > 0">
        <div class="nd-error-message" v-for="error in errors._" :key="error">
          {{ error }}
        </div>
      </div>
      <div v-if="errors['g-recaptcha-response']">
        <div class="nd-error-message" v-for="error in errors['g-recaptcha-response']" :key="error">
          {{ error }}
        </div>
      </div>

      <ui-form-input
        type="text"
        name="email"
        v-model="email"
        :errors="errors"
        :label="$t('general.email')"
      />

      <div class="g-recaptcha"
          :data-sitekey="recaptcha"
          data-callback="_onFormSubmit"
          data-size="invisible"
      ></div>

      <div class="form-group">
        <div class="nd-button big-btn w-100" @click="beforeSubmit">
          <span>{{ $t("general.restore-password")}}</span>
        </div>
      </div>

      <div class="form-group text-center">
        <a class="nd-fogot-link" href="/">Sign in</a>
        <span class="gray">|</span>
        <a class="nd-fogot-link" href="/signup">{{ $t("general.create-new-account") }}</a>
      </div>
    </b-form>
  </div>
</div>
</template>

<style lang="scss">
.nd-fogot-link {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  color: #1E76FF;
  padding: 0 5px;
}

@media (max-width: 1200px)  {
  .nd-fogot-link {
    font-size: 12px;
  }
}
</style>


<script>
import UiFormInput from "../ui/UiFormInput.vue";
export default {
  components: { UiFormInput },
  data() {
    return {
      email: "",
      errors: {},
    };
  },
  computed: {
    recaptcha() {
      return process.env.VUE_APP_RECAPTCHAV2_SITEKEY;
    }
  },
  created() {
    window._onFormSubmit = this.submitForm
  },
  methods: {
    beforeSubmit() {
      window.grecaptcha.execute();
    },
    submitForm(token) {
      this.errors = {};
      let fields = {}
      fields.email = this.email;
      fields.action = 'reset_password';
      fields['g-recaptcha-response'] = token;
      this.$post("/forgot-password", fields,
        () => {
          this.$bvToast.toast(this.$t("general.password-reset-link-sent"), {
            autoHideDelay: 2000,
            title: this.$t("general.success"),
            solid: true,
            toaster: "b-toaster-bottom-left",
          });

          window.grecaptcha.reset();
        },
        (errors) => {
          this.errors = errors;
          window.grecaptcha.reset();
        }
      );
    },
  },
};
</script>