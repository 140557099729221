<template>
  <fieldset class="form-select">
    <legend class="col-form-label pt-0">{{label}}</legend>
    <b-form-select
        v-model="inputVal"
        :options="options"
        :name="name"
        class="mr-2"
    />
  </fieldset>
</template>

<script>
export default {
  name: "UiSelect",

  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    value: [String, Number],
    options: [Array, Object],
  },

  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
}
</script>

<style scoped>
.form-select{
  display: flex;
  flex-direction: column;
}

select {
  margin: 0;
}
</style>