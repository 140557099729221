import LayoutUser from '../layout/user/Layout.vue';
import LayoutAdmin from '../layout/admin/Layout.vue';
import PagePost from '../pages/PagePost.vue';
import PageLogin from '../pages/PageLogin.vue';
import PageHome from '../pages/PageHome.vue';
import PageSearch from '../pages/PageSearch.vue';
import PageNewPost from '../pages/PageNewPost.vue';
import PageSettings from '../pages/PageSettings.vue';
import PagePassword from '../pages/PagePassword.vue';
import PageEmail from '../pages/PageEmail.vue';
import PageSignup from '../pages/PageSignup.vue';
import PagePasswordReset from '../pages/PagePasswordReset.vue';
import PageForgot from '../pages/PageForgot.vue';
import PageUser from '../pages/PageUser.vue';
import PageBookmarks from '../pages/PageBookmarks.vue';
import PageBecomeAModel from '../pages/PageBecomeAModel.vue';
import PageLists from '../pages/PageLists.vue';
import PageList from '../pages/PageList.vue';
import PageMessages from '../pages/PageMessages.vue';
import PagePrice from '../pages/PagePrice.vue';
import PagePaymentSuccess from '../pages/PagePaymentSuccess.vue';
import PagePaymentFailure from '../pages/PagePaymentFailure.vue';
import PageSubscriptions from '../pages/PageSubscriptions.vue';
import PageNotifications from '../pages/PageNotifications.vue';
import PageCreators from '../pages/PageCreators.vue';
import PagePayouts from '../pages/PagePayouts.vue';
import PagePayments from '../pages/PagePayments.vue';
import VerifyEmail from '../pages/VerifyEmail.vue';
import AppSide from '../layout/AppSide.vue';

import PageLoginAdmin from '../pages/admin/PageLogin';
import PageDashboardAdmin from '../pages/admin/PageDashboard';
import PageUsersAdmin from '../pages/admin/PageUsers';
import PageUsersEditAdmin from '../pages/admin/PageUsersEdit';
import PageSubscriptionsAdmin from '../pages/admin/PageSubscriptions';
import PagePaymentsAdmin from '../pages/admin/PagePayments';
import PagePayoutsAdmin from '../pages/admin/PagePayouts';
import PagePostsAdmin from '../pages/admin/PagePosts';

import PagePrivacy from "../pages/public/PagePrivacy";
import PageTerms from "../pages/public/PageTerms";
import PageContract from "../pages/public/PageContract";
import PageCookie from "../pages/public/PageCookie";
import PageDMCA from "../pages/public/PageDMCA";
import PageUSC from "../pages/public/PageUSC";

export default [
    {
        path: "/admin",
        component: LayoutAdmin,
        children: [
            {
                path: '/admin',
                components: {
                    default: PageDashboardAdmin,
                    login: PageLoginAdmin
                },
                meta:{ isPublicPage: true }
            },
            {
                path: '/admin/users/:type?',
                component: PageUsersAdmin
            },
            {
                path: '/admin/users/edit/:id',
                component: PageUsersEditAdmin
            },
            {
                path: '/admin/subscriptions/:type?',
                component: PageSubscriptionsAdmin
            },
            {
                path: '/admin/payments/:type?',
                component: PagePaymentsAdmin
            },
            {
                path: '/admin/payouts/:type?',
                component: PagePayoutsAdmin
            },
            {
                path: '/admin/posts',
                component: PagePostsAdmin
            },
        ]
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: PagePrivacy,
        meta:{ isPublicPage: true }
    },
    {
        path: '/terms',
        name: 'terms',
        component: PageTerms,
        meta:{ isPublicPage: true }
    },
    {
        path: '/contract',
        name: 'contract',
        component: PageContract,
        meta:{ isPublicPage: true }
    },
    {
        path: '/cookie',
        name: 'cookie',
        component: PageCookie,
        meta:{ isPublicPage: true }
    },
    {
        path: '/dmca',
        name: 'dmca',
        component: PageDMCA,
        meta:{ isPublicPage: true }
    },
    {
        path: '/usc',
        name: 'usc',
        component: PageUSC,
        meta:{ isPublicPage: true }
    },
    {
        path: '/',
        component: LayoutUser,
        children: [
            {
                path: '/verify-email',
                component: VerifyEmail,
            },           
            {
                path: '/payments',
                component: PagePayments,
            },
            {
                path: '/payouts',
                component: PagePayouts,
            },
            {
                path: '/notifications',
                components: {
                    default: PageNotifications,
                    side: AppSide
                },
                name: 'notifications',
            },
            {
                path: '/subscriptions',
                component: PageSubscriptions,
                name: 'subscriptions',
            },
            {
                path: '/creators',
                components: {
                    default: PageCreators,
                    side: AppSide
                },
                name: 'creators',
            },
            {
                path: '/payment/failure',
                component: PagePaymentFailure
            },
            {
                path: '/payment/success/:gateway',
                component: PagePaymentSuccess
            },
            {
                path: '/messages/:id',
                component: PageMessages,
                name: 'messages',
            },
            {
                path: '/messages',
                component: PageMessages,
                name: 'messages',
            },
            {
                path: '/become-a-model',
                component: PageBecomeAModel,
                name: 'become-a-model',
                meta:{ isBecomeAModelPage: true, isPublicPage: true }
            },
            {
                path: '/lists',
                component: PageLists
            },
            {
                path: '/lists/:id',
                component: PageList,
                name: 'list'
            },
            {
                path: '/bookmarks',
                component: PageBookmarks,
                name: 'bookmarks'
            },
            {
                path: '/forgot',
                components: {
                    default: PageForgot,
                    login: PageForgot
                },
                meta:{ isPublicPage: true }
            },
            {
                path: '/password-reset',
                components: {
                    default: PagePasswordReset,
                    login: PagePasswordReset
                },
                meta:{ isPublicPage: true }
            },
            {
                path: '/signup',
                components: {
                    default: PageSignup,
                    login: PageSignup
                },
                meta:{ isPublicPage: true }
            },
            {
                path: '/settings/price',
                component: PagePrice
            },
            {
                path: '/settings/email',
                component: PageEmail
            },
            {
                path: '/settings/password',
                component: PagePassword
            },
            {
                path: '/settings',
                component: PageSettings
            },
            {
                path: '/posts/create',
                component: PageNewPost
            },
            {
                path: '/posts/edit/:id',
                component: PageNewPost
            },
            {
                path: '/search',
                components: {
                    default: PageSearch,
                    side: AppSide
                },
                name: 'search'
            },
            {
                path: '/:username',
                component: PageUser,
                meta:{ isUserPage: true, isPublicPage: true }
            },
            {
                path: '/:id/:username',
                component: PagePost,
            },
            {
                path: '/',
                name: 'home',
                components: {
                    default: PageHome,
                    login: PageLogin,
                    side: AppSide
                }
            },
        ]
    },
];