<template>
  <div class="nd-background nd-form-uni nd-start-form sign-up">
    <div>
      <h1>{{$t("general.signup")}}</h1>
      <b-form class="nd-form-uni-body" @submit.prevent="beforeSubmit" id="signup_form">
        <div v-if="errors._ && errors._.length > 0">
          <div class="nd-error-message" v-for="error in errors._" :key="error">
            {{ error }}
          </div>
        </div>
    
        <ui-form-input
            type="text"
            name="email"
            v-model="email"
            :errors="errors"
            :label="$t('general.email')"
        />
    
        <ui-form-input
            type="password"
            name="password"
            v-model="password"
            :errors="errors"
            :label="$t('general.password')"
        />
    
        <ui-form-input
            type="text"
            name="name"
            v-model="name"
            :errors="errors"
            :label="$t('general.name')"
        />
    
        <i18n path="general.signup-agree" tag="div" class="nd-text-info">
          <template v-slot:terms>
            <b-link :href="url + '/terms.html'" target="_blank">{{
                $t("general.terms-of-use")
              }}
            </b-link>
          </template>
          <template v-slot:privacy>
            <b-link :href="url + '/privacy.html'" target="_blank">{{
                $t("general.privacy-policy")
              }}
            </b-link>
          </template>
        </i18n>
    
        <div class="nd-button big-btn w-100" @click="beforeSubmit">
          <span>{{$t("general.signup")}}</span>
        </div>

    
        <div class="g-recaptcha"
             :data-sitekey="recaptcha"
             data-callback="_onFormSubmit"
             data-expired-callback="_onExpiredCallback"
             data-error-callback="_onErrorCallback"
             data-size="invisible"
        ></div>
    
        <div class="nd-text-info nd-bottom-text">
          {{ $t("general.already-have-an-account") }}
          <a href="/">{{ $t("general.login") }}</a>
    <!--      <b-link to="/">{{ $t("general.login") }}</b-link>-->
        </div>
      </b-form>
    </div>
  </div>
</template>
<script>
import UiFormInput from "../ui/UiFormInput.vue";

export default {
  components: {UiFormInput},
  data() {
    return {
      email: "",
      password: "",
      name: "",
      errors: {},
      token: null,
    };
  },
  created() {
    window._onFormSubmit = this.submitForm
    window._onExpiredCallback = this.onExpiredCallback
    window._onErrorCallback = this.onErrorCallback
  },
  computed: {
    url() {
      return process.env.VUE_APP_APP_URL;
    },
    recaptcha() {
      return process.env.VUE_APP_RECAPTCHAV2_SITEKEY;
    }
  },
  methods: {

    beforeSubmit() {
      window.grecaptcha.execute()
    },

    onExpiredCallback(msg) {
      console.log('onExpiredCallback', msg)
    },

    onErrorCallback(msg) {
      console.log('onErrorCallback', msg)

    },

    submitForm(response) {
      this.errors = {}
      const formData = new FormData(document.getElementById('signup_form'));
      formData.append('action', 'register');
      formData.append('g-recaptcha-response', response);

      this.$post(
          "/auth/signup",
          Object.fromEntries(formData.entries()),
          (data) => {
            this.$saveToken(data.token);
            this.$saveUser(data.user);
            location = process.env.VUE_APP_APP_URL;
            let message = 'User successfully signed...<br>'
            this.$toasted.success(message).goAway(5000);
            this.$router.replace("/");
          },
          (errors) => {
            let message = 'Error during signing up...<br>'
            'email' in errors ? message += errors.email + '<br>' : null;
            'password' in errors ? message += errors.password + '<br>' : null;
            'g-recaptcha-response' in errors ? message += errors['g-recaptcha-response'] + '<br>' : null;

            this.$toasted.error(message).goAway(5000);
            this.errors = errors;

            if(this.errors?._?.length) {
              this.$emit('setMobileHeight', this.errors._.length * 74)
            }
          
            window.grecaptcha.reset()
          }
      );

    },
  },
};
</script>