<template>
  <b-link v-if="asLink" :to="user.url" class="d-flex align-items-start nd-username-link" :class="cardGridName? 'card-grid-name' : '' ">
    <div class="username">{{
      user.name
    }}</div>
    <span class="text-muted username ml-1 d-flex justify-content-center" v-if="full">{{
      "@" + user.username
    }}</span>
  </b-link>

  <div v-else class="d-flex align-items-start black">
    <div class="username">{{
      user.name
    }}</div>
    <span class="text-muted username ml-1" v-if="full">{{
      "@" + user.username
    }}</span>
  </div>
</template>

<style scoped lang="scss">
  .nd-username-link{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1E76FF;
  }
  .card-grid-name {
    justify-content: center;
    .username {
      text-transform: uppercase;
      text-align: center;
      height: 28px;
      width: min-content;
      white-space: normal;
      text-overflow: initial;
      font-size: 14px;
      line-height: 14px;
      font-weight: 500;
    }
  }
</style>
<script>
import User from "../models/User";
export default {
  props: {
    user: User,
    full: {
      type: Boolean,
      default: false,
    },
    asLink: {
      type: Boolean,
      default: true,
    },
    bold: {
      type: Boolean,
      default: true,
    },
    cardGridName: {
      type: Boolean,
      default: false
    }
  },
};
</script>
