<template>
  <b-nav-item v-bind="$attrs">
    <div class="nav-item-icon">
      <slot name="icon" />
    </div>
    <div class="nav-item-text">
      <slot name="text" />
    </div>
  </b-nav-item>
</template>

<script>
export default {
  name: "UiNavButton",
}
</script>

<style scoped lang="scss">

.nav-item {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;

  .nav-link {
    display: flex;
    flex-direction: row;

    padding: 12px 22px;
  }
}

.nav-item-icon {
  display: flex;
  justify-content: center;

  width: 20px;
}

.nav-item-text {
  color: #333333;
  margin-left: 11px;
}
</style>