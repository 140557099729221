<template>
  <b-row>
    <b-col>
      <NDAppHeaderMobile/>
      <b-row class="sticky-top page-header nd-page-header">
        <b-col class="pl-0 pr-0 nd-background-body bg-sticky-padding-top bg-sticky-padding-bottom">
          <b-nav horizontal class="sticky-top justify-content-center main-menu nd-background">
            <b-nav-item to="/" exact class="text-uppercase page-chapter home">
              {{ $t("general.home") }}
            </b-nav-item>
            <b-nav-item to="/creators" class="text-uppercase page-chapter creators">
              {{ $t("general.creators") }}
            </b-nav-item>
          <!--  <b-nav-item to="/reels" class="text-uppercase page-chapter ">
              {{ $t("general.reels") }}
            </b-nav-item>-->
          </b-nav>
        </b-col>
      </b-row>
      <ui-posts v-model="posts" class="nd-posts" />
    </b-col>
  </b-row>
</template>
<script>
import Post from "../models/Post";
import UiPosts from "../ui/UiPosts.vue";
import NDAppHeaderMobile from "@/components/layout/NDAppHeaderMobile.vue";

export default {
  data: function () {
    return {
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadPosts();
    window.addEventListener("scroll", this.updateScroll);
    this.$verifyEmail()
  },
  components: {
    UiPosts, NDAppHeaderMobile
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadPosts() {
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.isLoading = true;
      this.$get(
        "/posts?page=" + this.page,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
  },
};
</script>

<style lang="scss">
  .main-menu {
    flex-wrap: nowrap;
    .page-chapter-home {
      width: 100%!important;
    }
  }

  .nd-footer-mobile {
    display: none;
  }

  .nd-page-header {
      /*margin-bottom: 20px;*/
    }

  @media (max-width: 1200px) {
    .nd-page-header {
      /*margin-bottom: 16px;*/
    }

    .nd-page-header, .nd-posts {
        padding-left: 16px;
        padding-right: 16px;
    }

    .nd-posts {
        margin-bottom: 120px;
    }

    .single-post, .single-post.has-trailer {
      height: 350px;
      .has-trailer, .single-post__item {
        height: 350px;
      }
    }

    .two-post/*, .three-post, .four-post*/ {
      height: 350px;
      .multigrid-item {
        height: inherit;
      }
    }

    .three-post {
        height: 350px;
    }

    .four-post {
        min-height: 350px;
    }

    .four-post .multigrid-item, .multigrid-item {
      min-height: 175px;
    }

    .nd-pay-to-unlock {
      font-size: 8px;
      line-height: 12px;
      width: 80px;
      height: 18px;

      img {
          width: 9px !important;
          height: 10px !important;
          margin-right: 4px;
      }
    }

    .post .nd-pay-button-block .nd-pay-button {
      height: 32px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }

    .post .nd-media {
      padding: 0 8px 8px 8px;
    }

    .post .nd-pay-button-block {
      padding: 0 8px;
    }

    .post .nd-avatar-name-post-block {
      padding: 16px 8px;
    }

    .post .nd-post-text {
      padding: 0 8px 16px 8px;
    }

    .post .nd-footer-btns {
      padding: 10px 8px 0 8px;
    }

    .post .nd-footer-likes-comments {
      padding: 12px 8px 16px 8px;
    }

    .nd-footer-mobile {
      display: flex;
      height: 83px;
      background: #FFFFFF;
      box-shadow: 0px -4px 30px rgba(164, 174, 189, 0.33), inset 0px 1px 2px rgba(149, 159, 175, 0.3);
      justify-content: space-around;

      .nav-item {
        width: 52px;
        display: flex;
        justify-content: center;
      }

      .nav-link {
        position: relative;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
        text-align: center;
        color: #333333;
        padding: 0;
        padding-top: 9px;
        padding-left: 3px;
        padding-right: 3px;
          &.router-link-active {
              color: #1E76FF !important;
              img {
                filter: invert(32%) sepia(91%) saturate(2106%) hue-rotate(207deg) brightness(100%) contrast(101%);
              }
          }
        }

        .nd-add-post-footer-btn {
          position: relative;
          top: -42px;
          width: 67px;
          height: 67px;
          background: linear-gradient(90.01deg, #AA0742 -19.28%, #9E005D 37.65%, #ED1C24 125.74%);
          border-radius: 100%; 
          display: flex;
          justify-content: center;
          align-items: center;
        }
    }
}
</style>
