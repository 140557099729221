<template>
  <b-row class="profile nd-profile" v-if="isLoggedIn && user != null" :class="{'nd-profile-scrolled-header': isScrolled}">
    <div class="nd-main-header-container sticky-top" v-if="isScrolled">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ user.name }}
        </b-link>
      </div>
    </div>

    <b-col>
      <b-row class="nd-profile-page-title" :class="'page-header' + (isScrolled ? ' scrolled' : '')">
        <div class="nd-profile-page-header nd-dropdown-block">
          <b-link @click="goBack">
            <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          </b-link>
          <div class="flex-grow-1 overflow-hidden">
            <ui-username :user="user" :asLink="false" class="nd-profile-page-header-name" />
          </div>

          <div class="buttons">
            <b-dropdown no-caret right variant="link">
              <template slot="button-content">
                <i class="bi-three-dots text-white"/>
              </template>
              <b-dropdown-item @click.prevent="copyLink">{{
                $t("general.copy-link-to-profile")
              }}</b-dropdown-item>
              <b-dropdown-item @click.prevent="addToList(user)">{{
                $t("general.add-to-list")
              }}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </b-row>
      <b-row class="nd-background nd-header-profile overflow-hidden">
        <b-col class="p-0">
          <div class="nd-background-profile">
            <b-img :src="user.cover ? storageUrl + user.cover : user.cover" v-if="user.cover != null" />
          </div>
          <div class="nd-header-profile-line1">
            <div class="nd-header-profile-avatar">
              <UiAvatar :user="user"/>
            </div>

            <div class="nd-header-profile-btns">
              <div class="nd-btns-position">
                <b-link :to="'/messages/' + user.id">
                  <img src="~@/assets/icons/new-design/nd-messages.svg">
                </b-link>
                <b-link @click.prevent="addToList(user)">
                  <img src="~@/assets/icons/new-design/nd-star.svg">
                </b-link>
                <b-link @click.prevent="copyLink">
                  <img src="~@/assets/icons/new-design/nd-copy.svg">
                </b-link>
                <b-link @click.prevent="tip" v-if="!isOwner && (user && user.role !== 0)">
                  <img src="~@/assets/icons/new-design/nd-tip.svg">
                </b-link>
              </div>

            </div>
          </div>

          <div class="nd-header-profile-line2">
              <ui-username class="nd-header-profile-name-line1" :user="user" :asLink="false" />
              <div class="nd-header-profile-name-line2">{{ "@" + user.username }}</div>
              <div class="nd-header-profile-counts" v-if="user.isCreator">
                <div>
                  {{user.counted_info.likes}}
                  <img src="~@/assets/icons/new-design/nd-count-heart.svg"/>
                </div>

                <div>
                  {{user.counted_info.subscribers}}
                  <img src="~@/assets/icons/new-design/nd-count-user-group.svg"/>
                </div>

                <div>
                  {{user.counted_info.photos}}
                  <img src="~@/assets/icons/new-design/nd-count-photo-camera.svg"/>
                </div>

                <div>
                  {{user.counted_info.videos}}
                  <img src="~@/assets/icons/new-design/nd-count-video.svg"/>
                </div>

              </div>
              <div class="nd-header-profile-name-line2" v-if="user.bio">{{ user.bio }}</div>
              <div v-if="user.location" class="nd-header-profile-name-line2">{{ user.location }}</div>
              <div v-if="user.website" class="nd-header-profile-name-line2">
                <b-link target="_blank" :href="user.website">{{ user.website}}</b-link>
              </div>
              <div>
                <b-link class="nd-edit-btn" href="/settings" v-if="isOwner">
                  <img src="~@/assets/icons/new-design/nd-edit.svg"/>
                  Profile
                </b-link>
              </div>

              <div class="nd-subscription-block" v-if="!user.isSubscribed && !isOwner">
                <div class="nd-subscription-hr"></div>
                <h5>{{ $t("general.subscription") }}</h5>
                <div class="nd-btn-row">
                  <div v-if="user.active_offer" class="nd-button blue-bordered big-btn w-100" @click.prevent="subscribe(user.active_offer, Payment.TYPE_SUBSCRIPTION_PROMOTION)">
                    {{  user.active_offer.message }}
                  </div>
                  <div v-else class="nd-button blue-bordered big-btn w-100" @click.prevent="subscribe">
                    {{ $t("general.subscribe-for-x", [ user.isFree ? $t("general.free") : user.priceFormatted, ]) }}
                  </div>
                </div>
              </div>

              <div class="nd-subscription-block"
                v-if="
                  !isOwner &&
                  !user.isSubscribed &&
                  !user.isFree &&
                  user.bundles.length > 0
                "
              >
                <div class="nd-subscription-hr"></div>
                <h5>{{ $t("general.subscription-bundles") }}</h5>
                <div v-for="(item, index) in user.bundles" :key="index" class="nd-btn-row">
                  <div class="nd-button blue-bordered big-btn w-100" @click.prevent="subscribe(item)" >
                    {{ $t("general.get-x-months-for-y-z-off", item.title(user)) }}
                  </div>
                </div>
              </div>
          </div>
        </b-col>
      </b-row>

      <b-row class="d-block" v-if="isOwner">
        <b-nav tabs justified class="nd-background nd-page-tabs">
          <b-nav-item class="nd-page-tab"
            :active="postsType == types.active"
            @click.prevent="updatePosts(types.active)"
            >{{ $t("general.posts") }}</b-nav-item
          >
          <b-nav-item class="nd-page-tab blbr"
            :active="postsType == types.scheduled"
            @click.prevent="updatePosts(types.scheduled)"
            >{{ $t("general.scheduled") }}</b-nav-item
          >
          <b-nav-item class="nd-page-tab"
            :active="postsType == types.expired"
            @click.prevent="updatePosts(types.expired)"
            >{{ $t("general.expired") }}</b-nav-item
          >
        </b-nav>
      </b-row>
      <ui-posts v-model="posts" />
    </b-col>
  </b-row>
  <PageUserPublic v-else-if="!isLoggedIn" />
</template>
<style lang="scss">

.nd-profile {
  margin-top: -10px;

  .nd-subscription-block {
    .nd-subscription-hr {
      height: 1px;
      background: #E9EBF0;
      margin: 20px -20px;
    }

    h5 {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      color: #333333;
      margin-bottom: 20px;
    }

    .nd-btn-row {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .nd-page-tabs {
    margin-bottom: 20px;
  }

  .nd-profile-page-header {
    position: relative;
    margin-top: -30px;
    top: 76px;
    padding-left: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    height: 30px;

    a {
      > img {
        margin-right: 8px;
      }
    }

  }

  .nd-profile-page-header-name {
    font-style: normal;
    font-weight: 500!important;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
  }

  .nd-profile-page-title {
    height: 30px;
      .buttons {
          position: absolute;
          right: 20px;
          button {
            padding: 0;
            width: 26px;
            height: 18px;
            background: rgba(51, 51, 51, 0.58);
            border-radius: 2px;
            text-decoration: none!important;

            .bi-three-dots::before, .show .bi-three-dots::before{
              border: 0;
              margin-top: 0px;
              position: relative;
              display: block;
              background: none;
            }
          }
      }

      .nd-dropdown-block {
        z-index: 5;
        .dropdown-menu {
          width: 153px;
          background: #FFFFFF;
          box-shadow: 0px 2px 8px rgba(164, 174, 189, 0.3);

          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          color: #333333;

          li {
            border-bottom: 1px solid #E9EBF0;
            &:last-of-type {
              border-bottom: 0;
            }
          }

          .dropdown-item {
            padding: 7px 20px;
          }
        }
      }
    }
    
  .nd-header-profile {
    margin-bottom: 20px;
    .nd-background-profile {
      position: relative;
      width: 100%;
      height: 156px;
        &:after {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          background: #959FAF;
          pointer-events: none;
          content: "";
          z-index: 3;
        }
        img {
          -o-object-fit: cover;
          object-fit: cover;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          z-index: 4;
        }
    }

    .nd-header-profile-line1 {
      position: relative;
      display: flex;
      margin-top: -47px;
      z-index: 5;
      justify-content:space-between;
      margin-left: 20px;
      margin-right: 20px;

      .nd-header-profile-avatar {
        border: 3px solid white;
        border-radius: 100%;
        width: 94px;
        height: 94px;
      
        .nd-avatar {
          width: 88px;
          height: 88px;

          a {
            width: 88px;
            height: 88px;
          }
        }

        .nd-is-creator {
          width: 26px;
          height: 36px;
        }

        .b-avatar-badge {
          margin-bottom: -1px;
          img {
            height: 36px;
          }
        }
      }

      .nd-header-profile-btns {
        .nd-btns-position {
          display: flex;
          position: absolute;
          bottom: 0;
          right: 0;
        }

        a {
          margin-right: 18px;
          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    }

    .nd-header-profile-line2 {
      padding: 16px 20px;

      .nd-header-profile-name-line1 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 20px;
        color: #333333;
      }

      .nd-header-profile-name-line2 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #959FAF;
        margin-top: 5px;
      }

      .nd-edit-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 135px;
        height: 24px;
        background: #1E76FF;
        border-radius: 30px;
        margin-top: 8px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        cursor: pointer;
        img {
          margin-right: 8px;
        }
      }

      .nd-header-profile-counts {
        display: flex;
        align-items: center;
        margin: 16px 0 24px 0;
        >div {
          padding: 3px 8px;
          margin-right: 8px;
          background: #D6F4FF;
          border-radius: 30px;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
          color: #333333;
          img {
            margin-left: 2px;
          }
        }
      }
    }
  }
}

.profile {
  .page-header {
    .btn,
    a:not(.dropdown-item) {
      padding: 0;
      color: white;
    }
    &.scrolled {
      top: 0;
      margin-bottom: 0;
      color: #333;

      .buttons {
        display: none;
      }

      .nd-profile-page-header-name {
        color: #333;
      }
      .nd-profile-page-header{
        top: 30px;

        a {
          > img {
            filter: contrast(800%) invert(68%)
          }
        }
      }
    }
  }

}

@media (max-width: 1200px) {
  .nd-profile {
    margin-left: 1px;
    margin-right: 1px;
    margin-top: -14px;
    margin-bottom: 120px;


    .nd-subscription-block {
      .nd-subscription-hr {
        margin: 16px -16px;
      }

      h5 {
        font-size: 16px;
        margin-bottom: 16px;
      }

      .nd-btn-row {
        margin-bottom: 16px;
        .nd-button {
          height: 32px;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }

    .post {
      margin-bottom: 14px!important;
    }

    .nd-page-tabs {
        margin-bottom: 16px;

        .nd-page-tab {
          height: 45px;
          a {
              font-size: 14px;
          }
        }

    }

    .nd-profile-page-header {
        margin-top: -30px;
        top: 74px;
    }
    

    .nd-header-profile {
      margin-bottom: 16px;
      .nd-header-profile-line1 .nd-header-profile-avatar {
          width: 72px;
          height: 72px;
          
          .nd-avatar {
            width: 72px;
            height: 72px;

            a {
              width: 66px;
              height: 66px;
            }
          }

          .nd-is-creator {
            width: 23px;
            height: 32px;
          }

          .b-avatar-badge {
            bottom: -5px!important;
            img {
              height: 32px;
            }
          }
      }

      .nd-header-profile-line1 {
          margin-top: -57px;
          margin-left: 16px;
          margin-right: 16px;
      }

      .nd-header-profile-line1 .nd-header-profile-btns .nd-btns-position {
          bottom: -21px;
      }

      .nd-header-profile-line2 {
        padding: 12px 8px;

        .nd-header-profile-name-line1 {
          font-size: 16px;
          line-height: 18px;
        }

        .nd-header-profile-counts {
          margin: 27px 0 24px 0;
        }
      }

    } 

  }
}
</style>

<script>
import Post from "../models/Post";
import User from "../models/User";
import UiPosts from "../ui/UiPosts.vue";
import Payment from "../models/Payment";
import UiUsername from "../ui/UiUsername.vue";
import UiAvatar from "../ui/UiAvatar";
import PageUserPublic from "../pages/PageUserPublic";

export default {
  components: {UiAvatar, UiPosts, UiUsername, PageUserPublic },
  
  data: function () {
    return {
      user: null,
      posts: [],
      scrollPosition: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      postsType: Post.TYPE_ACTIVE,
      storageUrl: process.env.VUE_APP_STORAGE_URL
    };
  },

  computed: {
    isScrolled: function () {
      return this.scrollPosition >= 85;
    },
    isOwner: function () {
      return this.user.id == this.$store.state.currentUser.id;
    },
    username: function () {
      return this.$route.params.username;
    },
    types: function () {
      return {
        active: Post.TYPE_ACTIVE,
        scheduled: Post.TYPE_SCHEDULED,
        expired: Post.TYPE_EXPIRED,
      };
    },
    
    isLoggedIn: function () {
      return this.$store.state.token != null;
    },
  },

  watch: {
    username: function (oldV, newV) {
      if (oldV && newV && oldV != newV) {
        this.reset();
        this.loadUser();
      }
    },
  },

  created() {
    if(!this.isLoggedIn) return
    this.Payment = Payment;
  },

  mounted() {
    if(!this.isLoggedIn) return

    this.loadUser();
    window.addEventListener("scroll", this.updateScroll);
    this.$verifyEmail()
  },

  methods: {
    goBack() {
      if(window.history.length === 1) this.$router.push('/')
      else this.$router.back()
    },
    updatePosts(type) {
      this.postsType = type;
      this.reset();
      this.loadPosts();
    },
    reset() {
      this.isLoading = false;
      this.hasMore = false;
      this.page = 1;
      this.posts = [];
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadUser() {
      this.$get(
        "/users/" + this.username,
        (data) => {
          this.user = new User(data);
          this.loadPosts();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadPosts() {
      this.isLoading = true;
      this.$get(
        "/posts/user/" +
          this.user.id +
          "?page=" +
          this.page +
          "&type=" +
          this.postsType,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
      this.$bvToast.toast(this.$t("general.link-copied"), {
        autoHideDelay: 2000,
        title: this.$t("general.link"),
        solid: true,
        toaster: "b-toaster-bottom-left",
      });
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
    subscribe(bundle, type = Payment.TYPE_SUBSCRIPTION_NEW) {
      if (type === Payment.TYPE_SUBSCRIPTION_PROMOTION) {
        return this.$buyItem({
          type: Payment.TYPE_SUBSCRIPTION_PROMOTION,
          user: this.user,
        });
      }

      if (this.user.isFree) {
        this.$post(
          "/subscribe/" + this.user.id,
          {},
          () => {
            this.reset();
            this.loadUser();
          },
          (errors) => {
            console.log(errors);
          }
        );
      } else {
        switch (type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$buyItem({
              type: Payment.TYPE_SUBSCRIPTION_NEW,
              user: this.user,
              bundle: bundle,
            });
        }
      }
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        user: this.user,
      });
    },
  },
};
</script>
