<template>
<div></div>
</template>
<script>

export default {
  created() {
      this.$post('/verify/email', { token: this.$route.query?.token },
        (data) => {
          this.$saveUser(data)
          this.$router.push('/')
        },
        (errors) => {
          let errorText = ''
          for (const item of errors._) {
            errorText += item + '. '
          }
          this.$showError(errorText)
          console.log('error', errors)
        }
      )
  }
};
</script>
