<template>
  <b-row class="sticky-top nd-app-suggestions">
    <b-col>
      <div class="d-flex align-items-center w-100 mb-20">
        <h5 class="nd-title-suggestions flex-grow-1">
          {{ $t("general.suggestions") }}
        </h5>
        <b-link @click.prevent="slideLeft" class="nd-next-btn">
          <i class="bi-chevron-left" />
        </b-link>
        <b-link @click.prevent="slideRight" class="nd-next-btn">
          <i class="bi-chevron-right" />
        </b-link>
      </div>
      <div ref="swiper" class="swiper w-100 overflow-hidden">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide d-block"
            v-for="(item, key) in chunkedArr"
            :key="key"
          >
            <ui-suggestion
              :user="user"
              v-for="(user, k) in item"
              :key="k"
              class="mb-2"
            />
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { Swiper } from "swiper";
import "swiper/swiper-bundle.css";
import User from "../models/User";
import UiSuggestion from "../ui/UiSuggestion.vue";
export default {
  components: { UiSuggestion },
  data: function () {
    return {
      users: [],
      swiper: null,
    };
  },
  mounted() {
    this.swiper = new Swiper(this.$refs.swiper, {
      // Optional parameters
      direction: "horizontal",
    });
    this.loadSuggestions();
  },
  computed: {
    chunkedArr() {
      const result = [];
      for (let i = 0; i < this.users.length; i += 3)
        result.push(this.users.slice(i, i + 3));
      return result;
    },
  },
  methods: {
    loadSuggestions() {
      this.$get(
        "/users",
        (data) => {
          let users = [];
          for (let obj of data.users) {
            users.push(new User(obj));
          }
          this.users = users;
          this.$nextTick(function () {
            this.swiper.update();
          });
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    slideLeft() {
      this.swiper.slidePrev();
    },
    slideRight() {
      this.swiper.slideNext();
    },
  },
};
</script>

<style lang="scss">
  .nd-app-suggestions {
    margin-top: 20px;

    .nd-title-suggestions {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      color: #333333;
      margin-bottom:0 ;
    }

    .nd-next-btn {
      width: 30px;
      height: 30px;
      background: #D6F4FF;
      border-radius: 100%;
      text-align: center;
      padding-top: 4px;
      margin-right: 5px;
      i {
        font-weight: 600;
        color:#1E76FF!important;
      }
    }
  }
</style>