<template>
  <b-card :header="$t('general.payout-method')" class="nd-background nd-payouts-method">
    <div v-if="!editing">
      <div v-if="method" class="d-flex align-items-center nd-payouts-selected-method">
        <img v-if="this.method.type === 1" src="~@/assets/icons/new-design/nd-bank.svg" />
        <img v-if="this.method.type === 2" src="~@/assets/icons/new-design/nd-cash-by-hand.svg" />
        {{ getInfo() }}
      </div>
      <div>
        <div class="nd-button inline nd-payouts-btn-add" @click.prevent="edit">{{ !editing ? $t("general.edit") : $t("general.add") }}</div>
      </div>
    </div>
    <div class="nd-form-uni" v-if="editing">
      <div class="nd-form-uni-body">
        <b-form>
          <div class="d-flex nd-radios">
            <b-form-radio v-model="type" value="1">
              <img src="~@/assets/icons/new-design/nd-bank.svg" />
              {{ $t("general.bank") }}
            </b-form-radio>
            <b-form-radio v-model="type" value="2">
              <img src="~@/assets/icons/new-design/nd-cash-by-hand.svg" />
              {{ $t('general.cash-by-hand') }}
            </b-form-radio>
            <b-form-radio v-model="type" value="3">
              {{ $t('general.paxum') }} 
            </b-form-radio>
          </div>
    
          <div v-if="type == 0">
            <ui-form-input
              type="text"
              name="paypal"
              v-model="paypal"
              :errors="errors"
              :label="$t('general.paypal-address')"
            />
          </div>
    
          <div v-if="type == 1">
            <ui-form-input
              type="text"
              name="first_name"
              v-model="verification.info.first_name"
              :errors="errors"
              disabled
              :label="$t('general.first-name')"
            />
            <ui-form-input
              type="text"
              name="last_name"
              v-model="verification.info.last_name"
              :errors="errors"
              disabled
              :label="$t('general.last-name')"
            />
    
            <b-form-group
              :label="$t('general.bank-country')"
              label-for="country"
              class="mb-3"
              label-class="small"
            >
              <b-form-select
                id="country"
                v-model="verification.country"
                required
                disabled
                :options="countries"
                :state="errors['country'] ? false : null"
                aria-describedby="country-errors"
              />
              <b-form-invalid-feedback id="country-errors">
                <div v-for="error in errors['country']" :key="error">
                  {{ error }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
    
            <ui-form-input
              type="text"
              name="name"
              v-model="name"
              :errors="errors"
              :label="$t('general.bank-name')"
            />
            <ui-form-input
              type="text"
              name="address"
              v-model="address"
              :errors="errors"
              :label="$t('general.bank-address')"
            />
            <ui-form-input
              type="text"
              name="swift"
              v-model="swift"
              :errors="errors"
              :label="$t('general.swift')"
            />
            <ui-form-input
              type="text"
              name="account"
              v-model="account"
              :errors="errors"
              :label="$t('general.account-number')"
            />
          </div>
    
          <div v-if="type == 2">
            <ui-form-input
              type="text"
              name="first_name"
              v-model="verification.info.first_name"
              :errors="errors"
              disabled
              :label="$t('general.first-name')"
            />
            <ui-form-input
              type="text"
              name="last_name"
              v-model="verification.info.last_name"
              :errors="errors"
              disabled
              :label="$t('general.last-name')"
            />
    
            <b-form-group
              :label="$t('general.bank-country')"
              label-for="country"
              class="mb-3"
              label-class="small"
            >
              <b-form-select
                id="country"
                v-model="verification.country"
                required
                disabled
                :options="countries"
                :state="errors['country'] ? false : null"
                aria-describedby="country-errors"
              />
              <b-form-invalid-feedback id="country-errors">
                <div v-for="error in errors['country']" :key="error">
                  {{ error }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
    
          <div v-if="type == 3">
            <ui-form-input
              type="text"
              name="email"
              v-model="email"
              :errors="errors"
              :label="$t('general.email')"
            />
          </div>
    
          <div class="nd-payouts-method-btns">
            <div class="nd-button inline gray nd-payouts-btn" @click.prevent="unedit">{{ $t("general.cancel") }}</div>
            <div class="nd-button inline nd-payouts-btn" @click.prevent="save">{{ $t("general.save") }}</div>
          </div>

        </b-form>
      </div>
    </div>
  </b-card>
</template>

<style lang="scss">
.nd-payouts-method {
  margin-top: 20px;

  .nd-payouts-selected-method {
    img {
      position: relative;
      margin-right: 6px;
      margin-top: -2px;
    }
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;

  }

  .nd-payouts-btn-add {
    width: 132px;
    height: 48px;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
  }

  .nd-radios {
    margin-bottom: 20px;

    .custom-radio {
      text-transform: uppercase;
      margin-right: 40px;
      padding-left: 20px;

      img {
        margin-right: 5px;
      }
  
      label {
        margin-bottom: 0;
        display: flex;
        align-items: center;
        &::before, &::after {
          top: 2px;
          left: -21px;
        }
      }
  
      input {
        height: 22px!important;
      }
    }
  }

  .nd-payouts-method-btns {
    .nd-payouts-btn {
      width: 132px;
      height: 48px;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      margin-right: 20px;
    }
  }

  .nd-form-uni {
    margin-left: 0;
    margin-right: 0;
  }

  .nd-form-uni-body {
    padding: 0;
  }

  .card-header {
    border-radius: 10px;
    background: white;
    border: 0;
    padding: 16px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: #333333;
  }

  .card-body {
    padding: 0px 20px 16px 20px;

  }
}

@media (max-width: 1200px) {
  .nd-payouts-method {
    .card-header {
      padding: 16px 8px;
    }

    .card-body {
        padding: 0px 8px 16px 8px;
    }

    .nd-payouts-btn-add {
        width: 100%;
        height: 32px;
        font-size: 14px;
        line-height: 18px;
      }

    .nd-form-uni {
      margin-bottom: 0;
    }
    
    .nd-form-uni-body {
      padding: 0;
    }

    .nd-radios {
      margin-bottom: 20px;

      .custom-radio {
        text-transform: uppercase;
        margin-right: 20px;
        padding-left: 20px;
        label {
          display: flex;
          align-items: center;
          &::before, &::after {
            top: 1px;
          }
        }
    
        input {
          height: 22px!important;
        }
      }
    }

    .nd-payouts-method {
        margin-top: 16px;
    }

    .nd-payouts-method-btns {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      .nd-payouts-btn {
        height: 32px;
        font-size: 14px;
        line-height: 18px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}
</style>

<script>
import PayoutMethod from "../models/PayoutMethod";
import UiFormInput from "./UiFormInput.vue";
import Countries from "../helpers/Countries";
export default {
  components: { UiFormInput },
  props: ["value", "verification"],
  data: function () {
    return {
      type: this.value ? this.value.type * 1 : PayoutMethod.TYPE_BANK,
      paypal: this.value ? this.value.info.paypal : "",
      name: this.value ? this.value.info.name : "",
      address: this.value ? this.value.info.address : "",
      swift: this.value ? this.value.info.swift : "",
      account: this.value ? this.value.info.account : "",
      errors: {},
      editing: false,
      first_name: '',
      last_name: '',
      country: '',
      email: this.value ? this.value.info.email : ""

    };
  },

  computed: {
    method: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    countries() {
      let countries = [];
      for (let k in Countries) {
        countries.push({
          value: k,
          text: Countries[k],
        });
      }
      return countries;
    },
  },
  methods: {
    getInfo() {
      switch (this.method.type) {
        case PayoutMethod.TYPE_PAYPAL:
          return this.method.info.paypal;
        case PayoutMethod.TYPE_BANK:
          return this.$t("general.bank-info", [
            this.method.info.name,
            this.method.info.account.slice(0, 2) +
              "..." +
              this.method.info.account.slice(-2),
          ]);
      }
    },
    edit() {
      this.editing = true;
    },
    unedit() {
      this.type = this.method ? this.method.type * 1 : PayoutMethod.TYPE_PAYPAL;
      this.editing = false;
    },
    save() {
      this.errors = {};
      let fields = { type: this.type };
      switch (this.type * 1) {
        case PayoutMethod.TYPE_PAYPAL:
          fields.paypal = this.paypal;
          break;
        case PayoutMethod.TYPE_BANK:
          fields.name = this.name;
          fields.address = this.address;
          fields.swift = this.swift;
          fields.account = this.account;
          break;
        case PayoutMethod.TYPE_CASH:
          break;
        case PayoutMethod.TYPE_PAXUM:
          fields.email = this.email
          break;
      }
      this.$post(
        "/payouts/method",
        fields,
        (data) => {
          this.method = new PayoutMethod(data);
          this.editing = false;
        },
        (errors) => {
          this.errors = errors;
        }
      );
    },
  },
};
</script>
