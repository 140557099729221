import User from "../components/models/User";
import axios from "axios";
import { decode } from "@/components/models/Media"

export function isPicture(type) {
  return type === 0
}

export function isVideo(type) {
  return type === 1
}

export default {

  install(Vue) {

    Vue.prototype.$saveToken = function (data) {
      if (data == null) {
        localStorage.removeItem('token');
        this.$store.state.token = null;
      } else {
        localStorage.token = data;
        this.$store.state.token = data;
      }
    }

    Vue.prototype.$saveUser = function (data) {
      if (data == null) {
        localStorage.removeItem('currentUser');
        this.$store.commit('setUser', null)
      } else {
        data.avatar = data.avatar ? data.avatar + '?rnd=' + Math.random() : null;
        localStorage.currentUser = JSON.stringify(data);
        this.$store.commit('setUser', new User(data))

        this.$language('get');
      }
    }

    Vue.prototype.$showSpinner = function () {
      this.$store.state.spinner = true;
    }

    Vue.prototype.$hideSpinner = function () {
      this.$store.state.spinner = false;
    }

    Vue.prototype.$showPhotoSwipe = function (media, id) {
      let items = [];
      let index = 0;

      for (let item of media) {
        let screenshot = decode(item.screenshot)
        let url = decode(item.url)
        if(typeof screenshot === 'string' && !screenshot.includes(process.env.VUE_APP_STORAGE_URL)) {
          screenshot = process.env.VUE_APP_STORAGE_URL + screenshot
        }

        if(typeof url === 'string' && !url.includes(process.env.VUE_APP_STORAGE_URL)) {
          url = process.env.VUE_APP_STORAGE_URL + url
        }

        if (item.type == 1) {		 
          items.push({
            html: `
              <div class="video">
                <video data-video-instance class="video-js vjs-big-play-centered" 
                  controls 
                  preload='metadata' 
                  playsinline 
                  controlsList='nodownload' 
                  poster="${screenshot}">
                    <source src="${url}" type="video/mp4" />
                  </video>
              </div>`,
          });
        } else {
          items.push({
            src: url,
            w: 0,
            h: 0,
          });
        }
        if (item.id === id) {
          this.$store.state.swiperOptions = {
            index: index,
          };
        }
        index++;
      }
      this.$store.state.swiperItems = items;
    }

    Vue.prototype.$buyItem = function (data) {
      if(!this.$store.state.currentUser.isVerify) {
        this.$verifyEmail()
        return
      }
      this.$store.state.buyItem = data;
    }

    Vue.prototype.$translate = async (text, language = 'en')=> {

      let url = "https://www.googleapis.com/language/translate/v2/?key=AIzaSyBwIM-JwQboovbHLk0GAHEvOHe_1DjEZX4&target="+language+"&q="+text;

      let translated = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        }
        }).then(response => response.json())
        .then(response => {


          if(response.data.translations  && response.data.translations[0]){
            return response.data.translations[0].translatedText;
          }else{
            return this.text;
          }

        });

        return translated

    }

    Vue.prototype.isVideo = type => isVideo(type)

    Vue.prototype.isPicture = type => isPicture(type)

    Vue.prototype.$language = async function (method='get') {

      const langs = ['ar','en','pt','ro'];

      let result;
      let user = localStorage.getItem('currentUser');

      user = JSON.parse(user);

      const username = user.username;
      const userLanguage = localStorage.getItem('language');

      let sendAs = 'GET';
      let url = "/lang/get/" + username;

      switch(method.toLowerCase()) {
        case "get":
          sendAs = 'GET';
          url = "/lang/get/" + username;
        break;
        case "post":
          sendAs = 'POST';
          url = "/lang/set/";
        break;
        default:
          sendAs = 'GET';
          url = "/lang/get/" + username;
      }

      if(sendAs=='GET'){

        result = this.$get(url, (userLanguage) => {
            if(langs.includes(userLanguage.language)){
              localStorage.language = userLanguage.language;
              this.$i18n.locale = userLanguage.language;
            }
            return;
        }, (error) => {
          console.error('Error:', error);
          return;
        })
      }

      if(sendAs=='POST'){

        const userData = {
          username: username,
          lang: userLanguage,
        }

        result = this.$post(url, userData, () => {
          return;
        }, (error) => {
          console.error('Error:', error);
          return;

        });
      }

      return result;

    }

    Vue.prototype.$formatAmount = function (amount) {
      return process.env.VUE_APP_CURRENCY_SIGN + parseFloat(amount / 100).toFixed(2);
    }

    Vue.prototype.$escape = function (s) {
      return s.replace(/[\u00A0-\u9999<>&]/g, function (i) {
        return '&#' + i.charCodeAt(0) + ';';
      });
    }

    Vue.prototype.$get = function (url, success, failure) {
      this.$api('get', process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + url, null, success, failure);
    }

    Vue.prototype.$post = function (url, data, success, failure) {
      this.$api('post', process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + url, data, success, failure);
    }

    Vue.prototype.$deleteRequest = function (url, data, success, failure) {
      this.$api('delete', process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + url, data, success, failure);
    }

    Vue.prototype.$aGet = function (url, success, failure) {
      this.$api('get', process.env.VUE_APP_API_URL + '/admin' + url, null, success, failure);
    }

    Vue.prototype.$aPost = function (url, data, success, failure) {
      this.$api('post', process.env.VUE_APP_API_URL + '/admin' + url, data, success, failure);
    }

    Vue.prototype.$api = function (method, url, data, success, failure) {
      let headers = {};
      if (this.$store.state.token) {
        headers["Authorization"] = "Bearer " + this.$store.state.token;
      }
      axios(
        {
          method: method,
          url: url,
          data: data ? data : {},
          headers: headers
        }
      )
        .then((response) => {
          if(response.data?.success === false) {
            // от 01.07.2022. В случае ошибки, сервер возвращает 200 и структуру данных
            // {"success":false,"message":"Validation errors","errors":{"email":["The email must be a valid email address."]}}
            let errs = {_: [], timer: null};
            if(response.data?.errors) {
              for (let field in response.data.errors) {
                if(field !== 'timer')
                  errs._.push(response.data.errors[field][0])
                else
                  errs.timer = response.data.errors[field][0]
              }

              failure(errs);
            }
            else {
              errs._.push('success === false')
              failure(errs);
            }
          }
          else {
              if (response.data.updates) {
                this.$store.state.updates = {
                  notifications: response.data.updates.notifications,
                  messages: response.data.updates.messages,
                };
              }
              this.$hideSpinner();
              success(response.data);
          }
        })
        .catch((error) => {
          this.$hideSpinner();
          if (error.response && error.response.status == 401) {
            this.$saveToken(null);
            this.$saveUser(null);
            location = process.env.VUE_APP_APP_URL;
          }

          var errs = {};
          errs[Symbol.for("response")] = error;

          if (error.response && error.response.data && error.response.data.errors) {
            for (let field in error.response.data.errors) {
              errs[field] = [];
              for (let e in error.response.data.errors[field]) {
                errs[field].push(error.response.data.errors[field][e]);
              }
            }
          }
          failure(errs);
        });
    }

    Vue.prototype.$verifyEmail = function () {
      if(this.$store.state.currentUser.isVerify) return
      this.$toasted.clear()
      this.$toasted.show(`
      <div class="new-alert-body">
        <div class="new-alert-icon">
          <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 13H15L7.5 0L0 13ZM8.18182 10.9474H6.81818V9.57895H8.18182V10.9474ZM8.18182 8.21053H6.81818V5.47368H8.18182V8.21053Z" fill="#FC766A"/>
          </svg>
        </div>
        <div class="new-alert-text">Please verify your email to access full features </div>
      </div>
      `, {
        position: 'bottom-left',
        className: 'new-alert',
        duration : 30000,
        action : [
          {
            class: 'new-alert-close',
            text : '',
            onClick : (e, toastObject) => {
                toastObject.goAway(0)
            }
          },
          {
            class: 'new-alert-verify',
            text : 'verify now!',
            onClick : (e, toastObject) => {
              this.$get('/verify/email/send',
                (data) => {
                  this.$showSuccess(data?.message)
                  toastObject.goAway(0)    
                },
                (errors) => {
                    let errorText = ''
                    for (const item of errors._) {
                      errorText += item + '. '
                    }
                    this.$showError(errorText)
                    console.error(errors);
                }
              );
            }
          }
        ]
      }); 
    }

    Vue.prototype.$showSuccess = function (text) {
      this.$toasted.show(`
      <div class="new-alert-body">
        <div class="new-alert-icon">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 10.8L3.225 7.275L4.275 6.225L6.75 8.7L13.05 2.4C11.625 0.975 9.675 0 7.5 0C3.375 0 0 3.375 0 7.5C0 11.625 3.375 15 7.5 15C11.625 15 15 11.625 15 7.5C15 6.075 14.625 4.8 13.95 3.675L6.75 10.8Z" fill="#69A36E"/>
          </svg>        
        </div>
        <div class="new-alert-text">${text}</div>
      </div>
      `, {
        position: 'bottom-left',
        className: 'new-alert',
        duration : 995000,
        action : [
          {
            class: 'new-alert-close',
            text : '',
            onClick : (e, toastObject) => {
                toastObject.goAway(0)
            }
          }
        ]
      }); 
    }

    Vue.prototype.$showError = function (text) {
      this.$toasted.show(`
      <div class="new-alert-body">
        <div class="new-alert-icon">
          <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 13H15L7.5 0L0 13ZM8.18182 10.9474H6.81818V9.57895H8.18182V10.9474ZM8.18182 8.21053H6.81818V5.47368H8.18182V8.21053Z" fill="#FC766A"/>
          </svg>
        </div>
        <div class="new-alert-text">${text}</div>
      </div>
      `, {
        position: 'bottom-left',
        className: 'new-alert',
        duration : 5000,
        action : [
          {
            class: 'new-alert-close',
            text : '',
            onClick : (e, toastObject) => {
                toastObject.goAway(0)
            }
          }
        ]
      }); 
    }

    // detect PWA
    Vue.prototype.$isStandalone = () =>
        (window.matchMedia('(display-mode: standalone)').matches) ||
        (window.navigator.standalone) ||
        document.referrer.includes('android-app://');

    Vue.prototype.$isStandaloneIOS = () =>
        window.navigator.standalone === true
  },
}
