<template>
  <div class="password-input-container">
  <ui-form-input
      :type="currentType"
      v-bind="$attrs"
      v-on="$listeners"
      class="w-100"
      @focus="handleFocus"
      @blur="handleBlur"
  >
      <template #append>
        <b-input-group-text class="input-group-text">
          <b-img @click="changeCurrentType" :src="eyeIcon" class="eyeIcon"/>
        </b-input-group-text>
      </template>
  </ui-form-input>
  </div>
</template>

<script>
import UiFormInput from "../ui/UiFormInput.vue";

export default {
  name: "UiPasswordInput",
  components: {UiFormInput},
  data() {
    return {
      currentType: "password", // can be "password" or "text"
      focused: false
    };
  },
  computed: {
    eyeIcon() {
      return this.currentType === "text" ? require("@/assets/icons/new-design/nd-eye-show.svg") : require("@/assets/icons/new-design/nd-eye-hide.svg");
    },
  },
  methods: {
    handleFocus() {
      this.focused = true;
    },
    handleBlur() {
      this.focused = false;
    },
    changeCurrentType() {
      if (this.currentType === "text") {
        this.currentType = "password";
      } else {
        this.currentType = "text";
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.eyeIcon {
  width: 24px;
  height: 24px;
}

.input-group-text {
  background-color: transparent;
  padding: 0px 4px;
}
</style>