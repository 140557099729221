<template>
  <div class="nd-form-uni email">
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ $t("general.email") }}
        </b-link>
      </div>
    </div>

    <div class="nd-background overflow-hidden">
      <div class="nd-form-uni-body">
        <div v-if="errors._ && errors._.length > 0">
          <div class="nd-error-message" v-for="error in errors._" :key="error">
            {{ error }}
          </div>
        </div>

        <b-form @submit="saveSettings">
          <ui-form-input
            type="email"
            name="email"
            v-model="email"
            :errors="errors"
            :label="$t('general.email')"
          />
          <ui-form-input
            type="password"
            name="password"
            v-model="password"
            :errors="errors"
            :label="$t('general.password')"
          />
        </b-form>

        <div class="verify-block">
          <div>
            <div class="verify-text" v-if="timerId">An email was sent to your new adress with a verifying link! you can only send a new email in {{timer}} sec.</div>
          </div>
          <div class="verify-buttons">
            <template v-if="emailOriginal === email">
              <div class="nd-button big-btn w-100" v-if="isVerify">
                <img src="~@/assets/icons/success.svg" class="mr-5"/>
                <span>Verified</span>
              </div>

              <div class="nd-button gray big-btn w-100" v-else>
                <span>NON VERIFY</span>
              </div>
            </template>

            <div class="nd-button big-btn w-100" v-else @click.prevent="saveSettings" :class="{'gray': timerId }">
                <span>Save and Verify</span>
            </div>
          </div>
        </div>        
      

      </div>


    </div>
  </div>
</template>

<style lang="scss">
.verify-block {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;

    .verify-text{
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      color: #333333;
      border: 1px solid #DFE2E6;
      padding: 9px;
      margin: 0 15px 0 0;
    }

    .verify-buttons {
        width: 300px;
    }
}

@media (max-width: 1200px) {
  .verify-block {
    display: block;
    width: 100%;

      .verify-text{
        width: 100%;
      }

      .verify-buttons {
        margin-top: 16px;
        width: 100%;
      }
  }

  .email {
    .nd-button {
      height: 32px!important;
      font-size: 18px!important;
      line-height: 23px!important;
    }
  }
}
</style>


<script>
import UiFormInput from "../ui/UiFormInput.vue";
export default {
  data: function () {
    return {
      emailOriginal: '',
      email: "",
      password: "",
      errors: {},
      timerId: null,
      timer: 59,
    };
  },
  computed: {
    isVerify(){
      return this.$store.state.currentUser.isVerify
    }
  },

  mounted() {
    this.loadUser()
  },

  beforeDestroy() {
    this.stopTimer()
  },

  components: {
    UiFormInput,
  },
  methods: {
    startTimer() {
      if(this.timerId) return

      this.timerId = setInterval(() => {
        this.timer--
        if(!this.timer) this.stopTimer()
      }, 1000)
    },

    stopTimer() {
        clearInterval(this.timerId)
        this.timerId = null
        this.timer = 59
    },

    loadUser() {
      this.$get(
        "/auth/me",
        (data) => {
          this.emailOriginal = this.email = data.email;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    saveSettings() {
      if(this.timerId) return

      this.errors = {};
      this.$post(
        "/profile/email",
        {
          email: this.email,
          password: this.password,
        },
        (response) => {
          this.$showSuccess(response.message)
          this.startTimer()
        },
        (errors) => {
          this.errors = errors
          if(errors.timer) {
            this.timer = errors.timer
            this.startTimer()
          }
        }
      );
    },
  },
};
</script>
