<template>
  <div class="wrapper wrapper--auth">
    <b-container class="container-xl">
      <b-row>
        <b-col xl="2" class="pl-0 d-none d-xl-block" style="padding-right: 20px"></b-col>
        
        <b-col :xl="8" cols="12">
          <router-view></router-view>
        </b-col>

        <b-col xl="2" class="pr-0 d-none d-xl-block bg-sticky-padding-top" style="padding-left: 20px"></b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  data() {
    return {}
  }
};
</script>


