<template>
  <div>
    <h2 style="margin-left:0px;">PRIVACY POLICY</h2>
    <p style="margin-left:0px;"><strong>Last Modified: December 2020</strong></p>
    <p style="margin-left:0px;"><br data-cke-filler="true"></p>
    <h2 style="margin-left:0px;">IMPORTANT POINTS.</h2>
    <p style="margin-left:0px;"><br data-cke-filler="true"></p>
    <p style="margin-left:0px;"><strong>1.</strong> <strong>OVERVIEW</strong></p>
    <p style="margin-left:0px;">Fanspicy and its subsidiaries (collectively,
      "<strong>Fanspicy</strong>," "<strong>we</strong>," "<strong>us</strong>," "<strong>our</strong>") respect
      your privacy and are committed to protecting the personal data we hold about you. If you have questions,
      comments, or concerns about this Privacy Notice or our processing of personal data, please see the bottom of
      this Privacy Notice for information about how to contact us. Fanspicy is the data controller of the personal
      data collected, and is responsible for the processing of your personal data.</p>
    <p style="margin-left:0px;">Fanspicy is a social network which enables users to share their content; other users
      to enjoy content and for some to do both. We refer to those users who share content as
      "<strong>Creators</strong>" and those users who pay to view Creators' content as "<strong>Fans</strong>".
      This Privacy Notice explains our practices with respect to personal data we collect and process about you.
      This includes information we collect through, or in association with, our website located at <a
          href="https://Fanspicy.com/"> Fanspicy.com</a>, our services that we may offer from time to time
      via
      our website, our related social media sites (Twitter and Instagram), or otherwise through your interactions
      with us (the website, our social media pages, and services, collectively, the "<strong>Services</strong>").
    </p>
    <p style="margin-left:0px;"><i>Please review the following to understand how we process and safeguard personal
      data about you. By using any of our Services, whether by visiting our website or otherwise, and/or by
      voluntarily providing personal data to us, you acknowledge that you have read and understand the
      practices contained in this Privacy Notice. This Privacy Notice may be revised from time to time, so
      please ensure that you check this Privacy Notice periodically to remain fully informed.</i></p>
    <p style="margin-left:0px;">PERSONAL DATA WE COLLECT</p>
    <ul>
      <li>Categories of Personal Data We Collect</li>
      <li>How We Use Your Personal Data</li>
      <li>How We Obtain Your Personal Data</li>
      <li>Legal Bases for Processing</li>
      <li>Who We Share Your Personal Data With</li>
      <li>Personal Data We Share</li>
    </ul>
    <p style="margin-left:0px;">YOUR RIGHTS REGARDING PERSONAL DATA</p>
    <ul>
      <li>Accessing, Modifying, Rectifying, and Correcting Collected Personal Data</li>
      <li>Your California Privacy Rights</li>
      <li>Your Nevada Rights</li>
      <li>Your European Union and UK Privacy Rights</li>
    </ul>
    <p style="margin-left:0px;">YOUR CHOICES</p>
    <ul>
      <li>Communications Opt-Out</li>
      <li>Location Information</li>
      <li>Cookies and Web Tracking</li>
    </ul>
    <p style="margin-left:0px;">PROTECTING PERSONAL DATA<br>RETENTION OF PERSONAL DATA<br>OTHER IMPORTANT
      INFORMATION ABOUT PERSONAL DATA AND THE SERVICES</p>
    <ul>
      <li>Identity Verification</li>
      <li>Payment Information</li>
      <li>Collection of Personal Data from Children</li>
      <li>Third-Party Websites and Services</li>
      <li>Business Transfer</li>
      <li>Do Not Track</li>
      <li>International Use</li>
    </ul>
    <p style="margin-left:0px;">MODIFICATIONS AND UPDATES TO THIS PRIVACY NOTICE<br>APPLICABILITY OF THIS PRIVACY
      NOTICE<br>ADDITIONAL INFORMATION AND ASSISTANCE</p>
    <p style="margin-left:0px;"><strong>2.</strong> <strong>PERSONAL DATA WE COLLECT</strong></p>
    <p style="margin-left:0px;">We collect information that identifies, relates to, describes, is reasonably capable
      of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer
      or household ("<strong>personal data</strong>"). In addition, we may collect data that is not identifiable
      to you or otherwise associated with you, such as aggregated data, and is not personal data. To the extent
      this data is stored or associated with personal data, it will be treated as personal data; otherwise, the
      data is not subject to this Privacy Notice.</p>
    <p style="margin-left:0px;"><strong>a. Categories of Personal Data We Collect</strong></p>
    <p style="margin-left:0px;">The types of personal data we collect about you depends on your interactions with us
      and your use of the Services. In the past twelve (12) months, we collected the below categories of personal
      data from our users:</p>
    <ol>
      <li>Identifiers such as a real name, alias, postal address, internet protocol address, email address,
        account name, social security number, driver's license number, passport number, or other similar
        identifiers.</li>
      <li>Personal information categories listed in the California Customer Records statute (Cal. Civ. Code
        1798.80(e)).</li>
      <li>Characteristics of protected classifications under California or federal law.</li>
      <li>Commercial information, including records of personal property, products or services purchased,
        obtained, or considered, or other purchasing or consuming histories or tendencies.</li>
      <li>Biometric information. Note: While we do not collect biometric information, if you choose to
        authenticate yourself through certain service providers we use, they may collect biometric information
        subject to their privacy policies, but we are never provided with access to that information.</li>
      <li>Internet or other electronic network activity information, including, but not limited to, browsing
        history, search history, and information regarding a consumer's interaction with an internet website,
        application, or advertisement.</li>
      <li>Audio, electronic, visual, thermal, olfactory, or similar information.</li>
      <li>Professional or employment-related information.</li>
    </ol>
    <p style="margin-left:0px;">We will not collect additional categories of personal data other than those
      categories listed above. If we intend to collect additional categories of personal data, we will provide you
      with a new notice at or before the time of collection.</p>
    <p style="margin-left:0px;"><strong>b. How We Use Your Personal Data</strong></p>
    <p style="margin-left:0px;">We strive to ensure that the content can be enjoyed by everyone, and to keep the
      content appropriate, tasteful and lawful. To do that, we collect and process your personal data for the
      following business and commercial purposes:</p>
    <ol>
      <li>Developing, improving, operating, providing, predicting, or performing, including maintaining or
        servicing accounts, enhancing the Services and your experience with them, providing customer service,
        processing or fulfilling transactions, verifying your identity, and processing payments.</li>
      <li>Communicating with you by email and text about the Services, verifying your identity, responding to
        support inquiries or, sharing information about the Services.</li>
      <li>Auditing related to a current interaction with the user and concurrent transactions.</li>
      <li>Detecting security incidents, protecting against malicious, deceptive, fraudulent, or illegal activity,
        and prosecuting those responsible for that activity.</li>
      <li>Debugging to identify and repair errors that impair existing intended functionality.</li>
      <li>Undertaking internal research for technological development and demonstration.</li>
      <li>Undertaking activities to verify or maintain the quality or safety of the Services owned, manufactured,
        manufactured for, or controlled by us, and to improve, upgrade, or enhance the Services owned,
        manufactured, manufactured for, or controlled by us.</li>
      <li>Complying with applicable laws, regulations, rules and requests of relevant law enforcement and/or other
        governmental agencies, or for other purposes, as permitted or required by law.</li>
      <li>Enforcing our <a href="https://www.Fanspicy.com/terms.html">Terms of Service</a> and other usage
        policies.
      </li>
      <li>As necessary or appropriate to protect the rights, property, and safety of our users, us, and other
        third parties.</li>
    </ol>
    <p style="margin-left:0px;">We will not use the personal data we collected for materially different, unrelated,
      or incompatible purposes without providing you with notice and obtaining your consent.</p>
    <p style="margin-left:0px;"><strong>c. How We Obtain Your Personal Data</strong></p>
    <p style="margin-left:0px;">We collect your personal data from the following categories of sources:</p>
    <ul>
      <li><strong>Directly from you</strong>. When you provide it to us directly to open an account and use the
        Services, or when you update the information in your account (see the "<a
            href="https://www.Fanspicy.com/privacy.html#collected-personal-data"><i><strong>Accessing, Modifying,
          Rectifying, and Correcting Collected Personal Data</strong></i></a>" section below for more
        information).</li>
      <li><strong>Automatically or indirectly from you</strong>. For example, through and as a result of your use
        of and access to the Services. We also collect IP addresses and browser types from the devices you use.
      </li>
      <li><strong>From our service providers</strong>.</li>
    </ul>
    <p style="margin-left:0px;"><strong>d. Legal Bases for Processing</strong></p>
    <p style="margin-left:0px;">We process personal data for, or based on, one or more of the following legal bases:
    </p>
    <ul>
      <li><strong>Performance of a Contract</strong>. By using the Services, you have contracted with us through
        the <a href="https://www.Fanspicy.com/terms.html">Terms of Service</a>, and we will process certain
        personal
        data
        to perform under that contract.</li>
      <li><strong>Legitimate Interests</strong>. We may process personal data for our legitimate interests,
        including complying with any applicable law, rule or regulation, investigation or remedy; enforcing our
        <a href="https://www.Fanspicy.com/terms.html">Terms of Service</a>; protecting our, our users' or others'
        rights,
        property and safety; and detecting and resolving any fraud or security concerns.
      </li>
      <li><strong>Compliance with Legal Obligations and Protection of Individuals</strong>. We may process
        personal data to comply with our legal obligations, including as required by valid legal process,
        governmental request, and to protect those individuals who use our Services and others.</li>
    </ul>
    <p style="margin-left:0px;"><strong>e. Who We Share Your Personal Data With</strong></p>
    <p style="margin-left:0px;">We share personal data with the following categories of third parties:</p>
    <ul>
      <li>Our service providers.</li>
      <li>Our affiliated entities.</li>
      <li>Government agencies or regulators when permitted or required to do so by law; in response to a request
        from a law enforcement agency or authority or any regulatory authority; and/or to protect the integrity
        of the Services or our interests, rights, property, or safety, and/or that of our users and others.</li>
    </ul>
    <p style="margin-left:0px;"><strong>f. Personal Data We Share</strong></p>
    <p style="margin-left:0px;">In the past twelve (12) months, we shared with the following categories of third
      parties the following categories of personal data for a business purpose:</p>
    <ul>
      <li><strong>Identifiers (e.g. name, address, email address, government ID, IP address):</strong> Service
        providers.</li>
      <li><strong>Identifiers (e.g. account name):</strong> Other Creators and Fans.</li>
      <li><strong>Personal information categories listed in the California Customer Records statute (e.g.
        government ID, bank account numbers):</strong> Service providers.</li>
      <li><strong>Audio, electronic, visual, thermal, olfactory, or similar information (e.g. content you
        create):</strong> Service providers.</li>
    </ul>
    <p style="margin-left:0px;"><strong>3.</strong> <strong>YOUR RIGHTS REGARDING PERSONAL DATA</strong></p>
    <p style="margin-left:0px;">You have certain rights regarding the collection and processing of personal data.
      You may exercise these rights, to the extent they apply to you, by contacting us at the information provided
      at the end of this Privacy Notice, or by following instructions provided in this Privacy Notice or in
      communications sent to you.</p>
    <p style="margin-left:0px;">Your rights vary depending on the laws that apply to you, but may include:</p>
    <ul>
      <li>The right to know whether, and for what purposes, we process your personal data;</li>
      <li>The right to be informed about the personal data we collect and/or process about you;</li>
      <li>The right to learn the source of personal data about you we process;</li>
      <li>The right to access, modify, and correct personal data about you (see the "<a
          href="https://www.Fanspicy.com/privacy.html#collected-personal-data"><i><strong>Accessing, Modifying,
        Rectifying, and Correcting Collected Personal Data</strong></i></a>" section below for more
        information);</li>
      <li>The right to know with whom we have shared your personal data with, for what purposes, and what personal
        data has been shared (including whether personal data was disclosed to third parties for their own
        direct marketing purposes);</li>
      <li>The right to withdraw your consent, where processing of personal data is based on your consent; and</li>
      <li>The right to lodge a complaint with a supervisory authority located in the jurisdiction of your habitual
        residence, place of work, or where an alleged violation of law occurred.</li>
    </ul>
    <p style="margin-left:0px;">See "<a
        href="https://www.Fanspicy.com/privacy.html#your-california-privacy-rights"><i><strong>Your California
      Privacy
      Rights</strong></i></a>", "<a
        href="https://www.Fanspicy.com/privacy.html#your-nevada-privacy-rights"><i><strong>Your Nevada Privacy
      Rights</strong></i></a>", and "<a
        href="https://www.Fanspicy.com/privacy.html#your-european-union-and-uk-privacy-rights"><i><strong>Your
      European
      Union and UK Privacy Rights</strong></i></a>", for more information about certain legal rights.
    </p>
    <p style="margin-left:0px;"><strong>a. Accessing, Modifying, Rectifying, and Correcting Collected Personal
      Data</strong></p>
    <p style="margin-left:0px;">We strive to maintain the accuracy of any personal data collected from you, and will
      try to respond promptly to update our records when you tell us the information in our records is not
      correct. However, we must rely upon you to ensure that the information you provide to us is complete,
      accurate, and up-to-date, and to inform us of any changes. Please review all of your information carefully
      before submitting it to us. Any updates or corrections to your information may be made through your account
      <a href="https://www.Fanspicy.com/my/settings/account">settings</a>.
    </p>
    <p style="margin-left:0px;">Depending on the laws that apply to you, you may obtain from us certain personal
      data in our records. If you wish to access, review, or make any changes to personal data you have provided
      to us through the Services, please contact us at the information provided at the end of this Privacy Notice.
      We reserve the right to deny access as permitted or required by applicable law.</p>
    <p style="margin-left:0px;"><strong>b. Your California Privacy Rights</strong></p>
    <p style="margin-left:0px;">California's "Shine the Light" law, permits our users who are California residents
      to request and obtain from us a list of what personal data (if any) we disclosed to third parties for their
      own direct marketing purposes in the previous calendar year and the names and addresses of those third
      parties. Requests may be made only once per year per person, must be sent to the email address below, and
      are free of charge. However, we do not disclose personal data protected under the "Shine the Light" law to
      third parties for their own direct marketing purposes.</p>
    <p style="margin-left:0px;">The California Consumer Privacy Act ("<strong>CCPA</strong>") provides our users who
      are California residents the following additional rights:</p>
    <p style="margin-left:0px;"><strong>Right to Know:</strong> You have the right to request that we disclose
      certain information to you about the personal data we collected, used, disclosed, and sold about you in the
      past 12 months. This includes a request to know any or all of the following:</p>
    <ul>
      <li>The categories of personal data collected about you;</li>
      <li>The categories of sources from which we collected your personal data;</li>
      <li>The categories of personal data that we have sold or disclosed about you for a business purpose;</li>
      <li>The categories of third parties to whom your personal data was sold or disclosed for a business purpose;
      </li>
      <li>Our business or commercial purpose for collecting or selling your personal data; and</li>
      <li>The specific pieces of personal data we have collected about you.</li>
    </ul>
    <p style="margin-left:0px;"><strong>Data Portability:</strong> You have the right to request a copy of personal
      data we have collected and maintained about you in the past 12 months.</p>
    <p style="margin-left:0px;"><strong>Right to Deletion:</strong> You have the right to request that we delete the
      personal data we collected from you and maintained, subject to certain exceptions. Please note that if you
      request deletion of your personal data, we may deny your request or may retain certain elements of your
      personal data if it is necessary for us or our service providers to:</p>
    <ul>
      <li>Complete the transaction for which the personal data was collected, provide a good or service requested
        by you, or reasonably anticipated within the context of our ongoing business relationship with you, or
        otherwise perform a contract between our business and you.</li>
      <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or
        prosecute those responsible for that activity.</li>
      <li>Debug to identify and repair errors that impair existing intended functionality.</li>
      <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech,
        or exercise another right provided for by law.</li>
      <li>Comply with the California Electronic Communications Privacy Act pursuant to Chapter 3.6 (commencing
        with Section 1546) of Title 12 of Part 2 of the Penal Code.</li>
      <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
        that adheres to all other applicable ethics and privacy laws, when the deletion of the information is
        likely to render impossible or seriously impair the achievement of such research, if you have provided
        informed consent.</li>
      <li>To enable solely internal uses that are reasonably aligned with your expectations based on your
        relationship with us.</li>
      <li>Comply with a legal obligation.</li>
      <li>Otherwise use the personal data, internally, in a lawful manner that is compatible with the context in
        which you provided the information.</li>
    </ul>
    <p style="margin-left:0px;"><strong>Right to Opt-Out/In:</strong> You have the right to opt-out of the sale of
      your personal data. You also have the right to opt-in to the sale of personal data. However, we do not sell
      your personal data.</p>
    <p style="margin-left:0px;"><strong>Right to Non-Discrimination:</strong> You have the right not to receive
      discriminatory treatment by us for the exercise of your CCPA privacy rights. Unless permitted by the CCPA,
      we will not:</p>
    <ul>
      <li>Deny you goods or services.</li>
      <li>Charge you different prices or rates for goods or services, including through granting discounts or
        other benefits, or imposing penalties.</li>
      <li>Provide you a different level or quality of goods or services.</li>
      <li>Suggest that you may receive a different price or rate for goods or services or a different level or
        quality of goods or services.</li>
    </ul>
    <p style="margin-left:0px;">To exercise your California privacy rights described above, please submit a
      verifiable request to us by visiting <a href="https://help.Fanspicy.com/ "> help.Fanspicy.com</a> or
      emailing us at <a href="mailto:privacy@Fanspicy.com">privacy@Fanspicy.com</a>.</p>
    <p style="margin-left:0px;">If you have an account with us, you can exercise any of the above rights from your
      <a href="https://Fanspicy.com/my/profile">profile</a>. If you don't have a profile or if you are unable
      to
      access, control, or delete your information from within your profile, you can contact us through any of the
      above methods.
    </p>
    <p style="margin-left:0px;">Only you, or a person authorized by you to act on your behalf, may make a verifiable
      consumer request related to your personal data.</p>
    <p style="margin-left:0px;">You may only make a verifiable consumer request for Right to Know or Data
      Portability twice within a 12-month period. The verifiable consumer request must:</p>
    <ul>
      <li>Provide sufficient information that allows us to reasonably verify you are the person about whom we
        collected personal data or an authorized representative. Depending on the nature of the request, we may
        need to verify your identity through an additional Ondato verification cycle, which requires a
        government issued ID and a photo requiring your presence through the a (see <a
            href="https://www.Fanspicy.com/privacy.html#identity-verification">Identity Verification</a> section
        below
        for more information).</li>
      <li>Describe your request with sufficient detail that allows us to properly understand, evaluate, and
        respond to it.</li>
    </ul>
    <p style="margin-left:0px;">We may deny your request if we are unable to verify your identity or have reason to
      believe that the request is fraudulent.</p>
    <p style="margin-left:0px;"><strong>Consumer Request by an Authorized Agent</strong></p>
    <p style="margin-left:0px;">If any authorized agent submits a consumer request on your behalf, in order to
      confirm that person or entity's authority to act on your behalf and verify the authorized agent's identity,
      we require an email be sent to <a href="mailto:privacy@ Fanspicy.com">privacy@ Fanspicy.com</a>, along
      with
      all of the below items:</p>
    <ul>
      <li>Proof that you gave the authorized agent signed permission to submit the request.</li>
      <li>Sufficient information to verify the authorized agent's identity, depending on the nature of the
        request.</li>
      <li>To verify your identity, depending on the nature of the request, we may also require a valid Government
        Issued ID (not expired), email address, and the last 4 digits of the social security number.</li>
    </ul>
    <p style="margin-left:0px;">We cannot respond to your request or provide you with personal data if we cannot
      verify your identity or authority to make the request and confirm the personal data relates to you. Making a
      verifiable consumer request does not require to create an account with us. However, if you do have an
      existing login, we will require you to log in to submit a request. We will only use personal data provided
      in a verifiable consumer request to verify the request's identity or authority to make the request.</p>
    <p style="margin-left:0px;">We will acknowledge receipt of the request within ten (10) business days of its
      receipt. We will respond to a verifiable consumer request within forty-five (45) days of its receipt. If we
      require more time (up to 90 days), we will inform you of the reason and extension period in writing. If you
      have an account with us, we will deliver our written response to that account. If you do not have an account
      with us, we will deliver our written response electronically. Any disclosures we provide will only cover the
      12-month period preceding the receipt of the verifiable consumer request. The response we provide will also
      explain the reasons we cannot comply with a request, if applicable. For Data Portability requests, we will
      provide the responsive information in a portable and, to the extent technically feasible, in a readily
      useable format that allows you to transmit the information to another entity without hindrance.</p>
    <p style="margin-left:0px;">We do not charge a fee to process or respond to your verifiable consumer request
      unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a
      fee, we will tell you why we made that decision and provide you with a cost estimate before completing your
      request.</p>
    <p style="margin-left:0px;"><strong>c. Your Nevada Privacy Rights</strong></p>
    <p style="margin-left:0px;">Nevada law permits our users who are Nevada consumers to request that their personal
      data not be sold (as defined under applicable Nevada law), even if their personal data is not currently
      being sold. Requests may be sent to privacy@Fanspicy.com, and are free of charge.</p>
    <p style="margin-left:0px;"><strong>d. Your European Union and UK Privacy Rights</strong></p>
    <p style="margin-left:0px;">In addition to the above-listed rights, European Union and UK privacy law provides
      individuals with enhanced rights in respect of their personal data. These rights may include, depending on
      the circumstances surrounding the processing of personal data:</p>
    <ul>
      <li>The right to object to decisions based on profiling or automated decision-making that produce legal or
        similarly significant effects on you;</li>
      <li>The right to request restriction of processing of personal data or object to processing of personal data
        carried out pursuant to (i) a legitimate interest (including, but not limited to, processing for direct
        marketing purposes) or (ii) performance of a task in the public interest;</li>
      <li>In certain circumstances, the right to data portability, which means that you can request that we
        provide certain personal data we hold about you in a machine-readable format; and</li>
      <li>In certain circumstances, the right to erasure and/or the right to be forgotten, which means that you
        can request deletion or removal of certain personal data we process about you.</li>
    </ul>
    <p style="margin-left:0px;">Note that we may need to request additional information from you to validate your
      request. To exercise any of the rights above, please visit <a
          href="https://www.Fanspicy.com/help">www.Fanspicy.com/help</a> or email us at <a
          href="mailto:privacy@Fanspicy.com">privacy@Fanspicy.com</a>.</p>
    <p style="margin-left:0px;"><strong>4.</strong> <strong>YOUR CHOICES</strong></p>
    <p style="margin-left:0px;">You have choices about certain information we collect about you, how we communicate
      with you, and how we process certain personal data. When you are asked to provide information, you may
      decline to do so; but if you choose not to provide information that is necessary to provide some of our
      Services, you may not be able to use those Services. In addition, it is possible to change your browser
      settings to block the automatic collection of certain information.</p>
    <p style="margin-left:0px;"><i><strong>a. Communications Opt-Out</strong></i>. You may opt out of receiving
      email communications from us at any time by following the opt-out link or other unsubscribe instructions
      provided in any email message received, by contacting us as provided at the end of this Privacy Notice, or
      by changing your notification preferences in account <a
          href="https://www.Fanspicy.com/my/settings/account">setting</a>. you wish to opt out by sending us an
      email
      to the address provided below, please include "Opt-Out" in the email's subject line and include your name
      and the email address you used to sign up for communications in the body of the email. Note, that if you do
      business with us in the future, you may not, subject to applicable law, opt out of certain automated
      notifications, such as order or subscription confirmations, based on business transactions (e.g.,
      e-commerce).</p>
    <p style="margin-left:0px;"><i><strong>b. Location Information</strong></i>. If you want to limit or prevent our
      ability to receive location information from you, you can deny or remove the permission for certain Services
      to access location information or deactivate location services on your device. Please refer to your device
      manufacturer or operating system instructions for instructions on how to do this.</p>
    <p style="margin-left:0px;"><i><strong>c. Cookies and Web Tracking</strong></i>. Consult our <a
        href="https://www.Fanspicy.com/cookies"><strong>Cookie Notice</strong></a> for more information about how
      to
      control and/or opt out of certain web tracking technologies.</p>
    <p style="margin-left:0px;"><strong>5.</strong> <strong>PROTECTING PERSONAL DATA</strong></p>
    <p style="margin-left:0px;">We use reasonable and appropriate physical, technical, and organizational safeguards
      designed to promote the security of our systems and protect the confidentiality, integrity, availability,
      and resilience of personal data. Those safeguards include: (i) the encryption of personal data where we deem
      appropriate; (ii) taking steps to ensure personal data is backed up and remains available in the event of a
      security incident; and (iii) periodic testing, assessment, and evaluation of the effectiveness of our
      safeguards.</p>
    <p style="margin-left:0px;">However, no method of safeguarding information is completely secure. While we use
      measures designed to protect personal data, we cannot guarantee that our safeguards will be effective or
      sufficient. In addition, you should be aware that Internet data transmission is not always secure, and we
      cannot warrant that information you transmit utilizing the Services is or will be secure.</p>
    <p style="margin-left:0px;"><strong>6.</strong> <strong>RETENTION OF PERSONAL DATA.</strong></p>
    <p style="margin-left:0px;">We retain personal data for a period of six (6) months after a user closes their
      account, and certain personal data for longer periods to the extent we deem necessary to carry out the
      processing activities described above, including but not limited to compliance with applicable laws,
      regulations, rules and requests of relevant law enforcement and/or other governmental agencies, and to the
      extent we reasonably deem necessary to protect our and our partners' rights, property, or safety, and the
      rights, property, and safety of our users and other third parties. Under applicable law, we are required to
      retain certain financial information for seven (7) years.</p>
    <p style="margin-left:0px;"><strong>7.</strong> <strong>OTHER IMPORTANT INFORMATION ABOUT PERSONAL DATA AND THE
      SERVICES.</strong></p>
    <p style="margin-left:0px;"><i><strong>a. Identity Verification</strong></i>. We require checks for Creators to
      ensure that we do not knowingly offer our Services to or collect personal data from anyone under 18 or
      anyone using a false identity, and offer checks as an option for Fans. These checks involve providing
      certain information to one or more of our service providers, currently Ondato, Aristotle, and Jumio which
      verify your identity. If you commence the registration process, you do so with the understanding that your
      personal data will be transferred to Ondato and processed subject to their <a
          href="https://ondato.com/privacy.html-policy/">privacy policy</a> and <a
          href="https://ondato.com/privacy.html-policy/">terms and conditions</a>, Aristotle and processed subject
      to
      their <a href="https://integrity.aristotle.com/privacy.html-policy/">privacy policy</a> and <a
          href="https://integrity.aristotle.com/privacy.html-policy/">terms and conditions</a>, or Jumio and
      processed
      subject to their <a href="https://www.jumio.com/legal-information/privacy.html-policy/">privacy policy</a>
      and <a href="https://www.jumio.com/legal-information/terms.html-and-conditions/">terms and conditions</a>.
      Ondato,
      Aristotle, and Jumio all require that you submit your passport or other government identification document,
      and use facial analysis software to verify your identity. Ondato also requires that you provide a photo of
      yourself holding your government identification as part of the verification process.</p>
    <p style="margin-left:0px;"><i><strong>b. Payment Information</strong></i>. Payments made by Fans to access
      content are processed by our third party payment providers. For example, when you make a payment that is
      processed by a payment provider, you will provide that third party with your credit card number, credit card
      expiration date, and security code, which they process and store subject to their privacy policy and terms
      of service. We do not receive your full credit card number, credit card expiration date, or the security
      code. Instead, the payment provider provides us with a "token" that represents your account, your card's
      expiration date, card type and the first two and last four digits of your card number. If you are required
      to provide your name and email address to the payment provider, then they also provide us with that
      information. Payments issued to Creators for their content are made by Fanspicy using the bank account
      information that we have collected and stored.</p>
    <p style="margin-left:0px;"><i><strong>c. Collection of Personal Data from Children</strong></i>. Our Services
      are not intended for anyone under 18. Anyone under 18 years of age is not permitted to use the Services, and
      we do not knowingly collect information from children under the age of 18. By using the Services, you
      represent that you are 18 years of age or older.</p>
    <p style="margin-left:0px;"><i><strong>d. Third-Party Websites and Services</strong></i>. As a convenience, we
      may reference or provide links to third- party websites and services, including those of unaffiliated third
      parties, our affiliates, service providers, and third parties with which we do business (including, but not
      limited to, our service providers). When you access these third-party services, you leave our Services, and
      we are not responsible for, and do not control, the content, security, or privacy practices employed by any
      third-party websites and services. You access these third-party services at your own risk. This Privacy
      Notice does not apply to any third-party services; please refer to the Privacy Notices or policies for such
      third-party services for information about how they collect, use, and process personal data.</p>
    <p style="margin-left:0px;"><i><strong>e. Business Transfer</strong></i>. We may, in the future, sell or
      otherwise transfer some or all of our business, operations or assets to a third party, whether by merger,
      acquisition or otherwise. Personal data we obtain from or about you via the Services may be disclosed to any
      potential or actual third- party acquirers and may be among those assets transferred.</p>
    <p style="margin-left:0px;"><i><strong>f. Do Not Track</strong></i>. We currently do not use any cross-site
      tracking technologies and do not currently process or comply with any web browser's "do not track" signal or
      similar mechanisms. Note, however, that you may find information about how to block or reject certain
      tracking technologies in our <a href="https://www.Fanspicy.com/cookies"><strong>Cookie Notice</strong></a>.
    </p>
    <p style="margin-left:0px;"><i><strong>g. International Use</strong></i>. Your personal data will be stored
      and/or processed in the United States, as well as in the European Union, Canada, Hong Kong, Russia,
      Singapore, Switzerland, Thailand, Ukraine and the United Kingdom. By your use of the Services, you
      acknowledge that we will transfer your data to, and store your personal data in, the above countries, which
      may have different data protection rules than in your country, and personal data may become accessible as
      permitted by law in the above countries, including to law enforcement and/or national security authorities
      in the those countries. For transfers of data into and out of the European Economic Area, pursuant to
      Article 46 of the General Data Protection Regulation, we use data transfer agreements subject to EU-approved
      standard contractual clauses.</p>
    <p style="margin-left:0px;"><strong>8.</strong> <strong>MODIFICATIONS AND UPDATES TO THIS PRIVACY
      NOTICE</strong></p>
    <p style="margin-left:0px;">This Privacy Notice replaces all previous disclosures we may have provided to you
      about our information practices with respect to the Services. We reserve the right, at any time, to modify,
      alter, and/or update this Privacy Notice, and any such modifications, alterations, or updates will be
      effective upon our posting of the revised Privacy Notice. We will use reasonable efforts to notify you in
      the event material changes are made to our processing activities and/or this Privacy Notice, such as by
      posting a notice on the Services or sending you an email. Your continued use of the Services following our
      posting of any revised Privacy Notice will constitute your acknowledgement of the amended Privacy Notice.
    </p>
    <p style="margin-left:0px;"><strong>9.</strong> <strong>APPLICABILITY OF THIS PRIVACY NOTICE</strong></p>
    <p style="margin-left:0px;">This Privacy Notice is subject to the <a
        href="https://www.Fanspicy.com/terms.html">Terms
      of
      Service</a> and Acceptable <a href="https://www.Fanspicy.com/privacy.html">Use Policy</a> that govern
      your use
      of
      the Services. This Privacy Notice applies regardless of the means used to access or provide information
      through the Services.</p>
    <p style="margin-left:0px;">This Privacy Notice does not apply to information from or about you collected by any
      third-party services, applications, or advertisements associated with, or websites linked from, the
      Services. The collection or receipt of your information by such third parties is subject to their own
      privacy policies, statements, and practices, and under no circumstances are we responsible or liable for any
      third party's compliance therewith.</p>
    <p style="margin-left:0px;"><strong>10.</strong> <strong>ADDITIONAL INFORMATION AND ASSISTANCE</strong></p>
    <p style="margin-left:0px;">If you have any questions or concerns about this Privacy Notice and/or how we
      process personal data, please contact us at <a
          href="mailto:privacy@Fanspicy.com">privacy@Fanspicy.com</a>.
    </p>
    <p style="margin-left:0px;">In addition, we have appointed a data protection officer ("DPO") who is responsible
      for, among other things, responding to questions, requests, and concerns in relation to this Privacy Notice
      and our use of personal data. You may contact the DPO as follows:</p>
    <p style="margin-left:0px;">Data Protection Officer<br><a
        href="mailto:DPO@Fanspicy.com">DPO@Fanspicy.com</a>
    </p>
  </div>
</template>

<script>
export default {
  name: "PagePrivacy"
}
</script>

<style scoped>

</style>