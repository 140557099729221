<template>
  <div class="nd-new-post">
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ postId ? $t("general.edit-post") : $t("general.new-post") }}
        </b-link>
        <div class="nd-button" @click.prevent="submitPost">{{$t("general.post")}}</div>
      </div>
    </div>

    <div class="nd-form-uni" v-if="poll.length > 0">
      <div class="nd-background">
        <div class="nd-form-uni-body">
          <b-form>
            <div class="d-flex align-items-center w-100 nd-option-title">
              <i class="bi-bar-chart" />
              <div class="flex-grow-1 mx-2">{{ $t("general.poll") }}</div>
              <div class="text-end">
                <b-link @click="pollRemove">
                  <i class="bi-x-circle-fill" />
                </b-link>
              </div>
            </div>
            <div class="nd-option-hr hr-margin-bottom"></div>
            
            <div
              v-for="(option, index) in poll"
              :key="index"
              class="d-flex align-items-center form-margin-bottom"
            >
              <b-form-input
                name="options"
                :placeholder="$t('general.enter-option')"
                v-model="poll[index]"
              />
              <b-link class="nd-option-link" @click="pollRemoveOption(index)">
                <i class="bi-x" />
              </b-link>
            </div>
            <div class="nd-option-hr hr-margin-top hr-margin-bottom"></div>
            <b-link class="small nd-link-add-option" @click="pollAddOption">{{ $t("general.add-option") }}</b-link>
          </b-form>
        </div>
      </div>

    </div>

    <div class="nd-background nd-new-post-container">
      <ui-media-uploader class="nd-new-post-dropzone" v-model="media" ref="uploader" />
      <div>
        <div class="nd-new-post-options"
          v-if="expire > 0 || (scheduleDate != null && scheduleTime != null) || price > 0">
          <ui-post-option-info class="nd-new-post-option"
            v-if="expire > 0"
            :info="$tc('general.x-days', expire)"
            @close="expireRemove()"
          />
          <ui-post-option-info class="nd-new-post-option"
            v-if="scheduleDate != null && scheduleTime != null"
            :info="scheduleFormat()"
            @close="scheduleRemove()"
          />
          <ui-post-option-info class="nd-new-post-option"
            v-if="price > 0"
            :info="priceFormat()"
            @close="priceRemove()"
          />
        </div>

        <b-form-textarea
          max-rows="8"
          class="px-3 py-0 mb-4"
          v-model="message"
          aria-describedby="errors"
          :placeholder="$t('general.type-something')"
          :state="Object.keys(errors).length > 0 ? false : null"
        />
        
        <div class="invalid-feedback px-3 py-2" id="errors">
          <div v-for="(value, key) in errors._" :key="key">
            <div v-for="(error, k) in value" :key="k">{{ error[0] }}</div>
          </div>
        </div>

        <b-modal id="modalExpire" ref="modalExpire" dialog-class="nd-main-modal nd-new-post-modal" centered hide-footer :title="$t('general.expiration-period')">
          <b-form-group class="text-center nd-new-post-modal-radios">
            <b-form-radio-group v-model="expire" :options="expireOptions" buttons />
          </b-form-group>
          <div class="nd-footer-btns">
            <div @click="$refs.modalExpire.hide()" class="nd-button big-btn">
                {{ $t('general.ok') }}
            </div>
          </div>
        </b-modal>




        <b-modal id="modalSchedule" ref="modalSchedule" dialog-class="nd-main-modal nd-new-post-modal" centered hide-footer :title="$t('general.schedule')">
          <b-tabs style="margin-bottom: 1rem;">
            <b-tab :title="scheduleFormatDate()" active>
              <b-calendar hide-header block class="mt-3" :min="new Date()" v-model="scheduleDate" />
            </b-tab>
            <b-tab :title="scheduleFormatTime()">
              <div class="text-center">
                <b-time hide-header block class="mt-3" v-model="scheduleTime" />
              </div>
            </b-tab>
          </b-tabs>
          <div class="nd-footer-btns">
            <div @click="$refs.modalSchedule.hide()" class="nd-button big-btn">
                {{ $t('general.ok') }}
            </div>
          </div>
        </b-modal>

        <b-modal id="modalPrice" ref="modalPrice" dialog-class="nd-main-modal nd-new-post-modal" centered hide-footer :title="$t('general.post-price')">
          <div class="nd-new-post-row-modal nd-has-refix">
            <b-input-group :prepend="currencySign">
              <b-form-input
                id="price"
                name="price"
                :placeholder="$t('general.free')"
                v-model="madalPriceAmount"
              />
            </b-input-group>
            <div style="font-size: 80%; color: #dc3545;margin-top: 2px;margin-bottom: 1rem;">{{errorText}}</div>
          </div>
          <div class="nd-footer-btns">
            <div @click="setPrice" class="nd-button big-btn">
                {{ $t('general.ok') }}
            </div>
          </div>
        </b-modal>
      </div>

     
      <div class="d-flex align-items-center nd-post-btns">
        <b-link @click="mediaDropzoneClick" class="nd-post-btn">
          <img src="@/assets/icons/image.svg"/>
        </b-link>
        <!--<b-link @click="mediaDropzoneClick" class="mx-2">
          <img src="@/assets/icons/video.svg" alt />
        </b-link>-->
        <b-link @click="pollAdd" class="nd-post-btn">
          <img src="@/assets/icons/poll.svg" />
        </b-link>
        <b-link v-b-modal.modalExpire class="nd-post-btn">
          <img src="@/assets/icons/expire-period.svg" />
        </b-link>
        <b-link v-b-modal.modalSchedule class="nd-post-btn">
          <img src="@/assets/icons/schedule.svg" />
        </b-link>
        <b-link v-b-modal.modalPrice class="nd-post-btn">
          <img src="@/assets/icons/post-price.svg" />
        </b-link>
      </div>
  
      <div>
        <div v-if="hasVideo" class="control-postcreate-block">
          <div class="custom-switcher-control-text">{{ $t("general.trailer") }}</div>
          <div>
            <img v-if="hasTrailer" @click="hasTrailer = false" src="~@/assets/icons/new-design/nd-switch-on.svg" />
            <img v-else @click="hasTrailer = true" src="~@/assets/icons/new-design/nd-switch-off.svg" />
          </div>
        </div>

        <div class="control-postcreate-block column margin-top" v-show="hasTrailer">
          <div class="custom-switcher-control-text gray">{{ $t("general.trailer-timer") }}</div>
          <div class="custom-radio-block">
            <b-form-group v-slot="{ ariaDescribedby }">
              <b-form-radio-group
                class="custom-radio-block"
                id="btn-radios-2"
                v-model="trailer_duration"
                :options="trailer_duration_options"
                :aria-describedby="ariaDescribedby"
                name="radio-btn-outline"
              ></b-form-radio-group>
            </b-form-group>
          </div>
        </div>

        <div class="control-postcreate-block" v-if="hasTrailer === 'delete this text'">
          <div class="custom-switcher-control-text">{{ $t("general.add-trailer-to-free-zone") }}</div>
          <b-form-checkbox
            class="test-custom-switch"
            v-model="trailer_free_zone"
            name="check_trailer_free_zone"
            switch
            disabled
          ></b-form-checkbox>
        </div>

        <div class="control-postcreate-block" v-if="hasTrailer === 'delete this text'">
          <div class="custom-switcher-control-text">{{ $t("general.add-post-to-free-zone") }}</div>
          <b-form-checkbox disabled
                           v-model="post_free_zone"
                           name="check_post_free_zone"
                           switch
          ></b-form-checkbox>
        </div>

        <div class="d-flex mt-1" v-if="hasTrailer === 'delete this text'">
          <img src="@/assets/icons/warning-blue.svg" alt="Warning" />
          <div
            class="warning-text ml-1"
          >{{ $t("general.trailer-freezone-hint") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.nd-new-post {
  margin-left: -15px;
  margin-right: -15px;

  .nd-option-link {
    color: #1E76FF;
    font-size: 34px;
    margin-right: -9px;
  }

  .nd-link-add-option {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #1E76FF;
  }

  .nd-option-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #333333;
    padding-bottom: 20px;

    a {
      color: #1E76FF;
    }
  }

  .nd-new-post-dropzone {
    margin-bottom: 16px;
  }

  .nd-option-hr {
    position: relative;
    margin: 0 -20px;
    height: 1px;
    background: #E9EBF0;

    &.hr-margin-bottom {
      margin-bottom: 16px;
    }
    
    &.hr-margin-top {
      margin-top: 16px;
    }
  }

  .form-margin-bottom {
    margin-block: 16px;
  }

  .nd-new-post-options {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 16px;

    .nd-new-post-option {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3px 30px 3px 17px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #333333;
      margin-right: 20px;
      background: #E9EBF0;
      border-radius: 15px;
      text-align: center;

      i {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 24px;
        color: #1E76FF;
      }
    }
  }

  .nd-new-post-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;

    .nd-post-btns {
      margin-bottom: 30px;
      .nd-post-btn {
          padding: 0 12px;
      }
    }

    textarea {
      border: none;
      scroll-behavior: smooth;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      text-align: center;

      &::placeholder {
        color: #959FAF;
      }
    }
    textarea:focus {
      border: none;
      box-shadow: none;
    }
  }

  .nd-form-uni {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 20px;
  }
}

.nd-new-post-row-modal {
      .form-group {
        display: block;
      }

      .input-group {
          .input-group-append {
            position: absolute;
            right: 10px;
            background: transparent;
            height: 100%;
            > div {
              border: 0;
            }
          }
        }

        label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          padding-bottom: 6px;
          margin-bottom: 0;
        }

        input:not([type='file']) {
          border: 1px solid #CCD2E3;
          border-radius: 30px!important;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #333333;
          padding: 15px 19px;
          height: 48px;
        }

      &.nd-has-refix {
        .input-group {
          .input-group-prepend {
            position: absolute;
            z-index: 4;
            height: 100%;
            left: 6px;

            .input-group-text {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: #333333;
              border: 0;
              background: none;
            }
          }

          .form-control {
            padding-left: 38px;
          }
        }
      }
    }

@media (max-width: 1200px) {
  .nd-new-post-row-modal {
    &.nd-has-refix {
      .input-group {
        .input-group-prepend {
          left: 17px;
        }
      }
    }
  }
}

.nd-new-post-modal {
  .nd-new-post-modal-radios {
      .btn-group-toggle {
        width: 100%;
        border-radius: 30px;
        overflow: hidden;
        border-radius: 30px;
        overflow: hidden;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #FFFFFF;
        background: #959FAF;

        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 48px;
          border: 0;
          background: #959FAF;
          &.active {
            background: #333333;
            border-radius: 30px;
          }
        }

      }
    }

    .nd-footer-btns {
      display: flex;
      justify-content: right;

      .nd-button {
        
      }
    }
}

@media (max-width: 1200px) {
  .nd-footer-btns {
      .nd-button {
        height: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
      }
    }

  .nd-new-post {
    margin-bottom: 150px;
    margin-left: 1px;
    margin-right: 1px;

    .nd-link-add-option {
      margin-top: 11px;
      display: block;
    }

    .nd-form-uni {
        margin-bottom: 16px;
        margin-left: 1px;
        margin-right: 1px;
    }

    .nd-option-link {
      font-size: 30px;
    }

    .nd-option-hr {
      margin-left: -8px;
      margin-right: -8px;
      &.hr-margin-bottom {
        margin-bottom: 7px;
      }

      &.hr-margin-top {
        margin-top: 7px;
      }
    }

    .form-margin-bottom {
      margin-block: 0px;
    }
  }
}
</style>

<script>
import some from 'lodash/some';
import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

import UiPostOptionInfo from "../ui/UiPostOptionInfo";
import UiMediaUploader from "../ui/UiMediaUploader.vue";
import Media from "../models/Media";

export default {
  components: {
    UiPostOptionInfo,
    UiMediaUploader,
  },
  data: function () {
    return {
      errorText: '',
      errors: {},
      message: "",
      currencySign: process.env.VUE_APP_CURRENCY_SIGN,
      media: [],
      poll: [],
      expire: 0,
      expireOptions: {
        0: this.$tc("general.x-days", 0),
        3: this.$tc("general.x-days", 3),
        7: this.$tc("general.x-days", 7),
        30: this.$tc("general.x-days", 30)
      },
      scheduleDate: null,
      scheduleTime: null,
      price: null,
      madalPriceAmount: null,
      has_trailer: false,
      trailer_duration: "3",
      trailer_duration_options: [
        { text: "3 sec", value: "3" },
        { text: "5 sec", value: "5" },
        { text: "9 sec", value: "9" }
      ],
      trailer_free_zone: false,
      post_free_zone: false
    };
  },
  computed: {
    postId() {
      return this.$route.params.id;
    },
    hasTrailer:{
      get(){
        return Boolean(this.has_trailer)
      },
      set(value){
        this.has_trailer = value
      }
    },
    hasVideo() {
      return some(this.media, mediaObj => this.isVideo(mediaObj.type))
    },
  },
  mounted() {
    if (this.postId) {
      this.loadPost();
    }
  },
  watch: {
    postId: function(newV, oldV) {
      if (oldV != newV) {
        this.reset();
        if (newV) {
          this.loadPost();
        }
      }
    },

    media: function () {
      this.hasTrailer = this.hasVideo
    },
    
    madalPriceAmount() {
      if(Number(this.madalPriceAmount) < 3 || Number(this.madalPriceAmount) > 100)
        this.errorText = `The value must be between ${3} and ${100}`
      else 
        this.errorText = ''
    }
  },
  methods: {
    setPrice() {   
      if(this.errorText) return
  
      this.$refs.modalPrice.hide()
      this.price = this.madalPriceAmount
      this.madalPriceAmount = null
    },

    reset() {
      this.errors = {};
      this.message = "";
      this.media = [];
      this.poll = [];
      this.expire = 0;
      this.scheduleDate = null;
      this.scheduleTime = null;
      this.price = null;
      this.$refs.uploader.clean();
      this.hasTrailer = true;
      this.trailer_duration = "3";
      this.trailer_free_zone = false;
      this.post_free_zone = false;
    },
    loadPost() {
      this.$showSpinner();
      this.$get(
        "/posts/" + this.postId,
        data => {
          this.message = data.message;
          this.hasTrailer = data.has_trailer;
          this.trailer_free_zone = data.trailer_free_zone;
          this.post_free_zone = data.post_free_zone;
          this.trailer_duration = data.trailer_duration;
          for (let m of data.media) {
            if (m.type == Media.TYPE_VIDEO) {
              for (let t of m.thumbs) {
                if (t.url == m.screenshot) {
                  m.scr = t;
                  break;
                }
              }
            }
          }
          this.media = data.media;
          this.$nextTick(function() {
            this.$refs.uploader.manual();
          });

          for (let p of data.poll) {
            this.poll.push(p.option);
          }
          this.expire = data.expires;
          if (data.schedule) {
            this.scheduleDate = dayjs(data.schedule).format("YYYY-MM-DD");
            this.scheduleTime = dayjs(data.schedule).format("HH:mm:ss");
          }
          this.price = data.price / 100;
        },
        errors => {
          console.log(errors);
        }
      );
    },
    mediaDropzoneClick() {
      this.$refs.uploader.click();
    },
    pollAdd() {
      if (this.poll.length == 0) {
        this.poll = ["", ""];
      }
    },
    pollRemove() {
      this.poll = [];
    },
    pollAddOption() {
      this.poll.push("");
    },
    pollRemoveOption(index) {
      this.poll.splice(index, 1);
    },
    scheduleFormatDate() {
      return this.scheduleDate !== null
        ? dayjs(this.scheduleDate).format("LL")
        : this.$t("general.date");
    },
    scheduleFormatTime() {
      return this.scheduleTime !== null && this.scheduleDate !== null
        ? dayjs(this.scheduleDate + " " + this.scheduleTime).format("LT")
        : this.$t("general.time");
    },
    scheduleFormat() {
      return this.scheduleTime !== null && this.scheduleDate !== null
        ? dayjs(this.scheduleDate + " " + this.scheduleTime).format("L LT")
        : "";
    },
    scheduleFormatIso() {
      return this.scheduleTime !== null && this.scheduleDate !== null
        ? dayjs(this.scheduleDate + " " + this.scheduleTime).toISOString()
        : null;
    },
    scheduleRemove() {
      this.scheduleDate = null;
      this.scheduleTime = null;
    },
    expireRemove() {
      this.expire = 0;
    },
    priceFormat() {
      return this.currencySign + this.price;
    },
    priceRemove() {
      this.price = null;
    },
    submitPost() {
      this.errors = {};

      let media = [];
      for (let m of this.media) {
        media.push({
          id: m.id,
          screenshot: m.scr ? parseInt(m.scr.id) + 1: null
        });
      }
      let fields = {
        message: this.message,
        has_trailer: this.hasTrailer,
        trailer_duration: this.trailer_duration,
        trailer_free_zone: this.trailer_free_zone,
        post_free_zone: this.post_free_zone
      };
      if (media.length > 0) {
        fields.media = media;
      }
      if (this.poll && this.poll.length > 0) {
        fields.poll = this.poll;
      }
      if (this.expire) {
        fields.expires = this.expire;
      }
      if (this.scheduleFormatIso() != null) {
        fields.schedule = this.scheduleFormatIso();
      }
      if (this.price) {
        fields.price = this.price;
      }
      if (this.postId) {
        fields._method = "PUT";
      }

      this.$post(
        "/posts" + (this.postId ? "/" + this.postId : ""),
        fields,
        (response) => {
          if('errors' in response) {
            this.errors = response.errors
            return
          }

          if (this.postId) {
            this.$bvToast.toast(this.$t("general.post-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left"
            });
          } else {
            this.$router.replace("/");
          }
        },
        errors => {
          this.errors = errors;
        }
      );
    }
  }
};
</script>
