<template>
    <div class="nd-header-mobile">
      <div><UiAvatar content-class="nd-avatar-header-mobile"/></div>
      <div><img src="~@/assets/logo-fanspicy.svg" class="nd-mobile-logo"/></div>
      <div><div v-b-toggle.sidebar class="nd-header-mobile-btn"><img src="~@/assets/icons/new-design/nd-mobile-menu.svg"/></div></div>
    </div>
</template>

<script>
import UiAvatar from "../ui/UiAvatar";
  export default {
    components: { UiAvatar }
  }
</script>

<style lang="scss">
.nd-header-mobile {
    display: flex;
    padding: 15px 0;
    align-items: center;
    justify-content: space-between;

  .nd-is-creator{
    left: 25px;
    top: 23px;
    width: 11px;
    height: 15px;
  }

  .b-avatar-badge {
    margin-bottom: -3px;
    img {
      height: 15px;
    }
  }

  .nd-header-mobile-btn {
    cursor: pointer;
  }

  .nd-mobile-logo{
    width: 79px;
    height: 32px;
  }

  .nd-avatar-header-mobile {
    width: 36px;
    height: 36px;
  }
 
}

@media (min-width: 1200px) {
  .nd-header-mobile {
      display: none;
  }
}
</style>