<template>
  <div class="nd-lists">
    <b-row class="sticky-top">
        <div class="nd-main-header-container">
          <div class="nd-background nd-main-header">
            <b-link @click="$router.go(-1)">
              <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
              {{ $t("general.lists") }}
            </b-link>
          </div>
        </div>
    </b-row>


      <b-row class="nd-list-items nd-background">
        <b-col class="nd-list-col" xl="4" cols="6" v-for="(item, index) in lists" :key="index">
          <div class="nd-list-item">
            <div class="nd-img-block"> 
              <img src="~@/assets/icons/new-design/nd-folder.svg" />
            </div>
            <div class="nd-name">{{ item.title }}</div>
            <div class="nd-count">{{ $tc("general.x-people", [item.listeesCount]) }}</div>
            <b-link :to="'/lists/' + item.id" >
              Open
            </b-link>
          </div>

        </b-col>
      </b-row>

   
  </div>
</template>
<style lang="scss">
.nd-lists {


  .nd-list-items {
    padding: 23px 5px;

    display: flex;
    flex-wrap: wrap;

    .nd-list-col {

    }

    .nd-list-item {
      height: 216px;
      background: #F5F5FB;
      border-radius: 10px;
      margin-bottom: 30px;
      padding: 30px 8px 21px 8px;
      
      display: flex;
      flex-direction: column;
      align-items: center;

      .nd-img-block {
        min-width: 79px;
        min-height: 79px;
        border-radius: 100%;
        background: white;
        display: inline-flex;
        justify-content: center;
        align-items: center;


        img {
          width: 58px;
          height: 58px;
        }
      }

      .nd-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        text-align: center;
        margin-top: 15px;
      }

      .nd-count {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #959FAF;
        text-align: center;
        margin-bottom: 8px;
      }

      a {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 32px;
        width: 100%;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        text-transform: uppercase;
        color: #FFFFFF;
        background: #1E76FF;
        border-radius: 30px;

        &:hover {
          background: #02B5F5;
        }
      }
    } 
  }
}

@media (max-width: 1200px) { 
  .nd-lists {
    padding: 0 16px 120px 16px;

    .nd-list-items {
      padding: 16px 4px; 

      .nd-list-col {
        padding-right: 8px;
        padding-left: 8px;

        .nd-list-item {
          margin-bottom: 16px;
        }
      }
    }
  }
}
</style>

<script>
import List from "../models/List";
export default {
  data: function () {
    return {
      lists: [],
    };
  },
  mounted() {
    this.loadLists();
  },
  methods: {
    loadLists() {
      let lists = [];
      this.$get(
        "/lists",
        (data) => {
          for (let l of data.lists) {
            lists.push(new List(l, this));
          }
          this.lists = lists;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>
