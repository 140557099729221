<template>
  <b-row>
    <b-col>
      <b-row class="border-bottom sticky-top page-header bg-white">
        <div class="d-flex align-items-center w-100 mx-3">
          <h5 class="text-uppercase p-0 my-3 flex-grow-1">
            {{ $t("admin.payouts") }}
          </h5>
          <b-nav pills>
            <b-nav-form class="mr-2">
              <b-form-input
                  :placeholder="$t('admin.search')"
                  v-model="q"
                  class="mr-1"
              />
            </b-nav-form>
            <b-nav-item
                to="/admin/payouts/pending"
                exact
                :active="$route.path.includes('/pending')"
                @click="q=''"
            >
              {{ $t("admin.pending") }}
            </b-nav-item>
            <b-nav-item
                to="/admin/payouts/completed"
                exact
                :active="$route.path.includes('/completed')"
                @click="q=''"
            >
              {{ $t("admin.completed") }}
            </b-nav-item>
<!--            <b-nav-item-->
<!--                to="/admin/payouts/batches"-->
<!--                exact-->
<!--                exact-active-class="active"-->
<!--                @click="q=''"-->
<!--            >-->
<!--              {{ $t("admin.batches") }}-->
<!--            </b-nav-item>-->
          </b-nav>
        </div>
      </b-row>
      <b-row>
        <b-table
            id="table"
            hover
            responsive
            ref="table"
            :items="loadPayouts"
            :fields="fields"
            per-page="0"
            class="small nowrap"
            :current-page="page"
        >
          <template #cell(user)="data">
            <b-link v-if="data.item.user" :to="'/'+data.item.user.username">{{
                data.item.user.username
              }}
            </b-link>
          </template>

          <template #cell(gateway)="data">
            {{ $t('admin.'+data.item.payout_name) }}
          </template>

          <template #cell(actions)="data">
            <b-button
                size="sm"
                class="mr-1"
                variant="secondary"
                @click="data.toggleDetails"
            >
              {{ $t("admin.details") }}
            </b-button>
            <b-button
                @click.prevent="status(data.item, getStatus('complete'))"
                variant="primary"
                size="sm"
                class="mr-1"
                v-show="!$route.path.includes('/completed')"
            >{{ $t("admin.complete") }}
            </b-button>
            <b-button
                @click.prevent="del(data.item)"
                variant="danger"
                size="sm"
                class="mr-1"
            >{{ $t("general.delete") }}
            </b-button>
          </template>

          <template #row-details="data">
            <UiAdminPayoutDetail :data="data.item"/>
          </template>

        </b-table>
      </b-row>
      <b-row class="text-center m-3 d-block" v-if="total > 0">
        <b-pagination
            align="center"
            v-model="page"
            :total-rows="total"
            :per-page="perPage"
            aria-controls="table"
        ></b-pagination>
      </b-row>
    </b-col>
  </b-row>
</template>
<style scoped>
</style>
<script>
import Payout from "../../models/Payout";
import UiAdminPayoutDetail from "../../ui/UiAdminPayoutDetail";

export default {
  components: {UiAdminPayoutDetail},
  data: function () {
    return {
      q: "",
      page: 1,
      total: 0,
      perPage: 1,
      fields: [
        {
          key: "id",
          label: this.$t("admin.invoice-id"),
          tdClass: "full",
        },
        {
          key: "user",
          label: this.$t("admin.user"),
          tdClass: "full",
        },
        {
          key: "amount",
          tdClass: "full",
          label: this.$t("general.amount"),
          formatter: (v) => {
            return this.$formatAmount(v);
          },
        },
        {
          key: "gateway",
          label: this.$t("admin.gateway"),
          tdClass: "full",
        },
        {
          key: "updated_at",
          label: this.$t("admin.processed"),
          formatter: (v) => {
            return v.format("lll");
          },
          tdClass: "full",
        },
        {
          key: "created_at",
          label: this.$t("admin.date"),
          formatter: (v) => {
            return v.format("lll");
          },
          tdClass: "full",
        },
        {
          key: "actions",
          label: this.$t("admin.actions"),
          tdClass: "full",
          stickyColumn: true
        },
      ],
    };
  },
  watch: {
    q() {
      this.reset();
    },
    type() {
      this.reset();
    },
  },
  created() {
    const url = new URLSearchParams(location.search)
    if(url.has('id')) {
      this.q = url.get('id')
    }
  },
  computed: {
    type() {
      return this.$route.params.type;
    },
  },
  methods: {
    getStatus(type) {
      switch (type) {
        case "complete":
          return Payout.STATUS_COMPLETE;
        case "batches":
          return Payout.STATUS_BATCHES;
        case "pending":
          return Payout.STATUS_PENDING;
      }
    },
    reset() {
      this.page = 1;
      this.$refs.table.refresh();
    },
    loadPayouts(ctx, callback) {
      let url = "/payouts";
      if (this.type) {
        url += "/" + this.type;
      }
      url += "?page=" + ctx.currentPage;
      if(this.q) {
        url += "&id=" + this.q;
      }
      this.$aGet(
          url,
          (data) => {
            let payouts = [];
            for (let obj of data.data) {
              payouts.push(new Payout(obj));
            }
            this.total = data.total;
            this.perPage = data.per_page;
            callback(payouts);
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    del(payout) {
      this.$aPost(
          "/payouts/" + payout.id,
          {
            _method: "DELETE",
          },
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
    status(payout, status) {
      this.$aPost(
          "/payouts/" + payout.id,
          {
            status: status,
          },
          () => {
            this.$refs.table.refresh();
          },
          (errors) => {
            console.log(errors);
          }
      );
    },
  },
};
</script>
