<template>
  <b-row class="nd-bookmarks">
    <b-col>
      <div class="nd-main-header-container sticky-top">
        <div class="nd-background nd-main-header">
          <b-link @click="$router.go(-1)">
            <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
            {{ $t("general.bookmarks") }}
          </b-link>
        </div>
      </div>


      <ui-posts v-model="posts" />
    </b-col>
  </b-row>
</template>

<style lang="scss">
.nd-bookmarks {
  margin-right: -30px;
  margin-left: -30px;

  .posts {
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (max-width: 1200px) {
  .nd-bookmarks  {
    margin: 0 -16px 120px -16px;

    .posts {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}
</style>

<script>
import Post from "../models/Post";
import UiPosts from "../ui/UiPosts.vue";
export default {
  data: function () {
    return {
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadPosts();
    window.addEventListener("scroll", this.updateScroll);
  },
  components: {
    UiPosts,
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadPosts() {
      this.isLoading = true;
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.$get(
        "/bookmarks?page=" + this.page,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.isLoading = false;
          this.hasMore = data.next_page_url != null;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
  },
};
</script>
