<template>
  <div class="nd-settings" v-if="user != null">
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ $t("general.settings") }}
        </b-link>
        <div class="nd-button" @click.prevent="saveSettings">{{$t("general.save")}}</div>
      </div>
    </div>

    <div>
      <div class="nd-background overflow-hidden">
        <div class="nd-settings-bg cover w-100 position-relative">
          <b-img :src="storageUrl + user.cover + '?rnd=' + rnd" v-if="user.cover != null" />
          <div class="buttons">
            <b-button @click.prevent="clck('cover')">
              <img src="~@/assets/icons/new-design/nd-camera2.svg" />
            </b-button>
          </div>

          <b-button variant="icon" class="nd-settings-del bg-position">
              <i class="bi-x text-white" @click.prevent="del('cover')" />
          </b-button>
        </div>

        <div class="avatar position-relative">
          <b-avatar :src="storageUrl + user.avatar + '?rnd=' + rnd" text=" "/>
          <div class="buttons">
            <b-button @click.prevent="clck('avatar')">
              <img src="~@/assets/icons/new-design/nd-camera2.svg" />
            </b-button>
          </div>
          <b-button variant="icon" class="nd-settings-del avatar-position">
              <i class="bi-x text-white"  @click.prevent="del('avatar')" />
          </b-button>
        </div>

        <div class="nd-form-uni-body">
          <div v-if="errors._ && errors._.length" class="w-100 mt-3">
            <div class="nd-error-message" v-for="error in errors._" :key="error">
              {{ error }}
            </div>
          </div>
          <b-form @submit="saveSettings">
            <ui-form-input
              type="text"
              name="username"
              v-model="user.username"
              :errors="errors"
              :label="$t('general.username')"
            />
            <ui-form-input
              type="text"
              name="name"
              v-model="user.name"
              :errors="errors"
              :label="$t('general.full-name')"
            />
            <ui-form-textarea class="bio-textarea"
              name="bio"
              v-model="user.bio"
              :errors="errors"
              :label="$t('general.bio')"
            />
            <ui-form-input
              type="text"
              name="location"
              v-model="user.location"
              :errors="errors"
              :label="$t('general.location')"
            />
            <ui-form-input
              type="url"
              name="website"
              v-model="user.website"
              :errors="errors"
              :label="$t('general.website')"
            />
          </b-form>
        </div>
      </div>

      
        <b-card no-body :header="$t('general.security')" class="nd-background nd-settings-card overflow-hidden">
          <b-list-group flush>
            <b-list-group-item to="/settings/password">
              <img src="~@/assets/icons/new-design/nd-key.svg" />
              {{$t("general.password")}}
              <img class="arr-alt" src="~@/assets/icons/new-design/nd-arrow-right-alt.svg" />
            </b-list-group-item>
            <b-list-group-item to="/settings/email">
              <img src="~@/assets/icons/new-design/nd-email.svg" />
              {{$t("general.email")}}
              <img class="arr-alt" src="~@/assets/icons/new-design/nd-arrow-right-alt.svg" />
            </b-list-group-item>
          </b-list-group>
        </b-card>
     

      <div v-if="user.role !== 0" class="nd-background nd-settings-card overflow-hidden">
        <b-card no-body :header="$t('general.subscription')">
          <b-list-group flush>
            <b-list-group-item to="/settings/price">
              <img src="~@/assets/icons/new-design/nd-price-tag.svg" />
              {{$t("general.price-bundles")}}
              <img class="arr-alt" src="~@/assets/icons/new-design/nd-arrow-right-alt.svg" />
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </div>

      <div class="nd-background nd-settings-card overflow-hidden">
        <div class="divider" />
        <div class="settings-topic w-100" v-if="user.role !== 0">
          <div class="w-100 topic-header">
              {{ $t("general.messages") }}
          </div>
          <div class="switch-input">
            <div class="switch-input-text">
              {{ $t("general.accept-messages") }}
            </div>
            <div @click="paidMessagesAccessChange">
              <img v-if="paidMessagesAccess" src="~@/assets/icons/new-design/nd-switch-on.svg" />
              <img v-else src="~@/assets/icons/new-design/nd-switch-off.svg" />
            </div>
          </div>
          <div class="settings-info">
              {{ $t("general.accept-messages-option-info") }}
          </div>
        </div>
      </div>
    </div>
    <input type="file" ref="avatar" hidden @change="upload('avatar')" />
    <input type="file" ref="cover" hidden @change="upload('cover')" />
  </div>
</template>
<style lang="scss">
.nd-settings {
  margin-left: -15px;
  margin-right: -15px;

  .cover {
    z-index: 2;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #000;
      opacity: 0.2;
      pointer-events: none;
      content: "";
      z-index: 3;
    }
  }

  .cover {
    height: 155px;
    img {
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .avatar {
    margin-top: -50px;
    z-index: 3;
    width: 94px;
    height: 94px;
    margin-left: 20px;
    position: relative;

    .b-avatar {
      width: 94px;
      height: 94px;
    }

    .badge-secondary {
      background: #E3E3EF;
    }
  }

  .nd-settings-bg {
    background: #959FAF;
  }

  .nd-settings-del {
    width: 32px;
    height: 32px;
    background: rgba(51, 51, 51, 0.48);
    border-radius: 2px;
    padding: 0;
    font-size: 32px;
    line-height: 32px;

    &.bg-position {
      position: absolute;
      top: 11px;
      right: 13px;
    }

    &.avatar-position {
      position: absolute;
      top: 2px;
      right: -4px;
    }

    i::before {
      margin-left: -1px;
      margin-top: -1px;
    }
  }

  .nd-form-uni-body {
    padding: 20px;

    .form-group {
      margin-bottom: 20px!important;
    }

    label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      margin-bottom: 7px;
    }

    input {
      border: 1px solid #CCD2E3;
      border-radius: 30px!important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      padding: 15px 19px;
    }

    .bio-textarea {
        textarea {
          border: 1px solid #CCD2E3!important;
          border-radius: 10px;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          color: #333333;
            &:focus {
              color: #495057;
              background-color: #fff;
              border-color: #a1d6f4!important;
              outline: 0;
              box-shadow: 0 0 0 0.2rem rgba(47,165,231, 0.25)!important;
        }
      }
    }
  }

  .nd-settings-card {
    margin-top: 20px;

    .card-header {
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      color: #1E76FF;
      height: 54px;
      background: white;
    }

    .list-group {
      background: white;
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      .list-group-item {
        display: flex;
        align-items: center;
        height: 54px;

        img {
          margin-right: 12px;
        }

        .arr-alt {
          margin-left: 12px;
        }
      }
    }
  }

  .buttons {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;

    button {
      height: 32px;
      width: 32px;
      background: none;
      border: 0;
      padding: 0;
    }
  }

  .settings-topic {
    padding: 20px;
  }

  .divider {
    height: 1px;
    width: 100%;
    background-color: #DDDDDD;
  }

  .topic-header {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    color: #1E76FF;
    margin-bottom: 16px;
  }

  .switch-input {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .control-postcreate-block {
      img {
        cursor: pointer;
      }
    }
  }

  .switch-input-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
    margin-right: 12px;
  }


  .settings-info {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    padding: 10px 24px;
    background: #D6F4FF;
    border-radius: 10px;
  }

}

@media (max-width: 1200px) {
    .nd-settings {
      margin-bottom: 150px;
      margin-left: 1px;
      margin-right: 1px;

      .settings-topic {
        padding: 20px 8px;
      }

      .topic-header {
        font-size: 18px;
        line-height: 18px;
      }

      .switch-input-text {
        font-size: 14px;
      }

      .settings-info {
        padding: 10px 12px;
        font-weight: 400;
        font-size: 10px;
        line-height: 13px;
      }


      .avatar {
        width: 72px;
        height: 72px;
        .b-avatar {
          width: 72px;
          height: 72px;
        }
      }

      .nd-settings-del.avatar-position {
        top: -5px;
        right: -12px;
      }


      .nd-form-uni-body {
        padding: 32px 8px 9px 8px;

        .form-group {
          margin-bottom: 16px!important;
        }

        label {
          font-size: 12px;
          line-height: 16px;
        }

        input {
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          padding: 7px 19px;
        }

        .bio-textarea {
            textarea {
              font-size: 12px;
              line-height: 16px;
              color: #333333;
          }
        }
      }

      .nd-settings-card {
        margin-top: 16px;

    .card-header {
      font-size: 16px;
      line-height: 18px;
      height: 48px;
      padding-left: 8px;

    }

    .list-group {
      font-size: 18px;
      line-height: 18px;
      color: #333333;
      .list-group-item {
        padding-left: 8px;
        height: 48px;

        img {
          margin-right: 10px;
        }

        .arr-alt {
          margin-left: 10px;
        }
      }
    }
  }

    }
  }
</style>
<script>
import User from "../models/User";
import UiFormInput from "../ui/UiFormInput.vue";
import UiFormTextarea from "../ui/UiFormTextarea.vue";
export default {
  data: function () {
    return {
      user: null,
      rnd: Math.random(),
      errors: {},
      paidMessagesAccess: false,
      storageUrl: process.env.VUE_APP_STORAGE_URL
    };
  },
  components: {
    UiFormInput,
    UiFormTextarea,
  },
  mounted() {
    this.loadUser();
  },
  methods: {
    loadUser() {
      this.$get(
        "/auth/me",
        (data) => {
          this.paidMessagesAccess = Boolean(data.paid_message);
          this.user = new User(data);
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    saveSettings() {
      this.errors = {};
      this.$post(
        "/profile",
        {
          username: this.user.username,
          name: this.user.name,
          bio: this.user.bio,
          location: this.user.location,
          website: this.user.website,
        },

        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.$bvToast.toast(this.$t("general.settings-saved"), {
            autoHideDelay: 2000,
            title: this.$t("general.success"),
            solid: true,
            toaster: "b-toaster-bottom-left",
          });
        },
        (errors) => {
          this.errors = errors;
        }
      );
    },
    clck(type) {
      this.$refs[type].click();
    },
    upload(type) {
      let formData = new FormData();
      formData.append("image", this.$refs[type].files[0]);
      this.$post(
        "/profile/image/" + type,
        formData,
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.rnd = Math.random();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    del(type) {
      this.$post(
        "/profile/image/" + type,
        {},
        (data) => {
          this.user = new User(data);
          this.$saveUser(data);
          this.rnd = Math.random();
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    paidMessagesAccessChange() {
      this.paidMessagesAccess = !this.paidMessagesAccess
      this.$get(
          "/profile/paid-message/toggle",
          () => {},
          (errors) => {
            console.log(errors);
          }
      );
    }
  },
};
</script>
