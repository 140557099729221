<template>
<div class="analytics-tab">
    <div v-if="isDataLoaded">
       <!--<button @click="showPopup">Show Popup</button>--> 
        <div class="title">{{ $t('general.earnings-today') }}:</div>
       <b-container>
           <b-row>
               <b-col cols="6" class="pad10" v-for="item in earningsToday" :key="item.key">
                <div class="category-item" @click="selectEarnings(item)">
                    <div class="item-title">{{item.name}}</div>
                    <div class="item-amount">{{formatNumber(item.amount)}}{{currency}}</div>
                    <img :src="item.icon"/>
                </div>
               </b-col>
           </b-row>
       </b-container>

        <br>
        <br>

        <UiEarningsDates :from="null" :to="null" />

        <portal to="window-portal">
            <div class="popup-background" v-if="isShowPopup">
                <div class="popup-block">
                    <div class="popup-title">
                        <div class="popup-close" @click="isShowPopup = false">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.893226 0L0 0.893226L4.60677 5.5L0 10.1068L0.893226 11L5.5 6.39323L10.1068 11L11 10.1068L6.39323 5.5L11 0.893226L10.1068 0L5.5 4.60677L0.893226 0Z" fill="#D9D9D9"/>
                            </svg>
                        </div>
                    </div>
                    <div class="popup-body" v-if="isPopupDataLoaded">
                        <template v-if="selectedEarnings">
                        <!--v-if="selectedEarnings.id === 20"-->
                                <template >
                                    <div class="table-line" v-for="(item, idx) in selectedEarnings.dataList" :key="idx">
                                        <div class="time">{{item.time}}</div>
                                        <div class="name">
                                            <router-link target="_blank" :to="`/${item.username}`">{{item.username}}</router-link>
                                        </div>
                                        <div class="type">{{getEarningsTypeStr(item.type)}}</div>
                                        <div class="amount">{{formatNumber(item.amount)}}{{currency}}</div>
                                    </div>
                                </template>
                        </template>
                    </div>
                    <div class="popup-body" v-else>
                        <div class="loader">
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        </portal>
    </div> 
  <div v-else>Loading...</div>
</div>
 
</template>
<script>
import subscribtions from '@/assets/icons/new-design/nd-subscribtions.svg'
import posts from '@/assets/icons/new-design/nd-posts.svg'
import messages from '@/assets/icons/new-design/nd-messages-a.svg'
import referrals from '@/assets/icons/new-design/nd-referrals.svg'
import tips from '@/assets/icons/new-design/nd-tips.svg'
import totaltoday from '@/assets/icons/new-design/nd-totaltoday.svg'
import calendar from '@/assets/icons/analytics/calendar.svg'
import UiEarningsDates from './UiEarningsDates'

export default {
    components: {
        UiEarningsDates
    },
    data: function () {
        return {
            currency: process.env.VUE_APP_CURRENCY_SIGN.replace('\\', ''),
            calendar,
            isShowPopup: false,
            isDataLoaded: true,
            isPopupDataLoaded: true,
            selectedEarnings: null,

            earningsToday: [
                {
                    id: 0,
                    key: 'subscribtions',
                    name: this.$t("general.ansubscribtions"),
                    icon: subscribtions,
                    amount: 0,
                    dataList: []
                },

                {
                    id: 10,
                    key: 'posts',
                    name: this.$t("general.anposts"),
                    icon: posts,
                    amount: 0,
                    dataList: []
                },

                {
                    id: 11,
                    key: 'messages',
                    name: this.$t("general.anmessages"),
                    icon: messages,
                    amount: 0,
                    dataList: []
                },

                {
                    id: -1,
                    key: 'referrals',
                    name: this.$t("general.anreferrals"),
                    icon: referrals,
                    amount: 0,
                    dataList: []
                },

                {
                    id: 20,
                    key: 'tips',
                    name: this.$t("general.antips"),
                    icon: tips,
                    amount: 0,
                    dataList: []
                },

                {
                    id: -2,
                    key: 'total today',
                    name: this.$t("general.antotaltoday"),
                    icon: totaltoday,
                    amount: 0,
                    dataList: []
                },
            ],
        };
    },
    mounted() {
        this.loadData()
        //window.addEventListener("scroll", this.updateScroll);
    },
    methods: {
        getEarningsTypeStr(typeId) {
            /*
            TYPE_SUBSCRIPTION_NEW = 0;
            TYPE_POST = 10;
            TYPE_MESSAGE = 11;
            TYPE_TIP = 20;
            */
            switch(typeId) {
                case 0:
                    return 'subscribed with Amount'
                case 10:
                    return 'paid post with Amount'
                case 11:
                    return 'paid Messages with Amount'
                case 20:
                    return 'paid Tips with Amount'
                default:
                    return typeId
            }
        },

        selectEarnings(selectedEarnings) {
            this.selectedEarnings = selectedEarnings
            this.isShowPopup = true
            this.loadDataDetail()
        },

        showPopup() {
            this.isShowPopup = !this.isShowPopup
        },
    /* updateScroll() {
        const scrollPosition = window.innerHeight + window.scrollY;
        if (
            document.body.offsetHeight &&
            scrollPosition &&
            document.body.offsetHeight - scrollPosition <= 1000 &&
            !this.isLoading &&
            this.hasMore
        ) {
            this.loadMore();
        }
        },*/
    /* loadMore() {
        if (this.hasMore) {
            this.page = this.page + 1;
            this.loadData();
        }
        },*/
    formatNumber(num) {
        return Number(Number(num).toFixed(2))
    },

    loadData() {
        this.isDataLoaded = false;
        this.$get(
            "/analytics/today",
            (data) => {
                if(!data?.data) this.earningsToday.forEach(item => item.amount = 0)
                else {
                    this.earningsToday.forEach(item => {
                        let dataValue = data.data.find(itemValue => itemValue.type === item.id)
                        if(dataValue) {
                          item.amount = dataValue.amount
                        }

                        if(item.id === -2) {
                            for(let itemData of data.data) {
                                if(Object.keys(itemData).includes('total')) {
                                    item.amount = itemData.total || 0
                                }    
                            } 
                        }
                    })
                }
                this.isDataLoaded = true;
            },
            (errors) => {
                this.isDataLoaded = true;
            console.log(errors)
            }
        );
        },
    loadDataDetail() {
        this.isPopupDataLoaded = false;
        this.$get(
            "/analytics/today/type/" + this.selectedEarnings.id,
            (data) => {
                if(!data?.data){
                    this.selectedEarnings.dataList = []
                } 
                else{
                    this.selectedEarnings.dataList = data?.data
                }

                console.log(data)
                this.isPopupDataLoaded = true;
            },
            (errors) => {
                this.isPopupDataLoaded = true;
            console.log(errors)
            }
        );
        },
    },
};
</script>

<style lang="scss">

.analytics-tab {
    padding-left: 32px;
    padding-right: 32px;

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: #333333;
        margin-left: 40px;
        margin-top: 27px;
        margin-bottom: 37px;
    }

    .category-item {
        background: #FFFFFF;
        border: 1px solid #E9EBF0;
        border-radius: 10px;
        position: relative;
        height: 162px;

        padding: 20px 14px;

        margin-left: 32px;
        margin-right: 32px;
        margin-bottom: 20px;
        text-align: center;
        font-style: normal;
        font-weight: 700;
        text-transform: uppercase;
        cursor: pointer;

        .item-title {
            padding-top: 13px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 21px;
            text-transform: uppercase;
            color: #333333;
        }

        .item-amount {
            padding-top: 13px;
            font-weight: 500;
            font-size: 20px;
            line-height: 26px;
        }

        img{
            width: calc(100% - 28px);
            position: absolute;
            bottom: 14px;
            left: 14px;
        }
    }
}

.popup-background {
        z-index: 5000;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom:0;
        background: rgba(0, 0, 0, 0.3);
        overflow: hidden;

        .popup-block {
            z-index: 5001;
            background: #FFFFFF;
            border: 1px solid #DDDDDD;
            border-radius: 4px;
            font-size: 12px;

            width: 550px;
            height: 275px;

            position: absolute;
            left: 50%;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);

            .popup-title {
                position: relative;
                height: 30px;
                max-height: 30px;
                overflow: hidden;
                white-space: nowrap;

                .popup-close {
                    position: absolute;
                    right: 0;
                    top: 5px;
                    padding: 5px 16px 5px 5px;
                    cursor: pointer;
                    svg {
                        width: 11px;
                        height: 11px;
                    }
                }
            }
            .popup-body {
                position: relative;
                height: calc(100% - 30px);
                padding: 0 25px 35px 25px;
                overflow-x: hidden;
                overflow-y: auto;
                margin-right: 3px;

                &::-webkit-scrollbar-thumb {
                    border-radius: 20px;
                    background: rgba(217, 217, 217, 0.5);
                }

                .loader {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    -webkit-transform: translate(-50%, -50%);
                    transform: translate(-50%, -50%);
                }
            }
        }
    }

    .nd-from-to {
        margin-left: 34px;
        margin-right: 34px;
    }

    
   .from-to {
        display: flex;
        height: 48px;
        background: #D6F4FF;
        border-radius: 50px;
        align-items: center;
        justify-content: center;

        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        color: #333333;

        .blue {
            color: #1E76FF;
        }

        >div{
            padding: 0 2px;
        }

        input {
            border: none;
            background: #F9F9F9;
            width: 138px;
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 23px;
            background: transparent;
        }

        img {
            position: relative;
            top: -2px;
            margin-right: 10px;
            width: 20px;
            height: 20px;
        }
   }

   
   .category-item-inline {
        display: flex;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 21px;
        text-transform: uppercase;
        color: #333333;
        border-bottom: 1px solid #E9EBF0;
        cursor: pointer;
        white-space: nowrap;
        padding: 16px;

        &.bold {
            font-weight: 500;
        }
        > div {
            width: 100%;
            &:last-of-type {
                text-align: right;
                width: 70%;
            }
        }

        .item-amount {
            font-weight: 500;
        }

        .image {
            width: 164px;
            img {
                width: inherit;
            }
        }
        
   }

@media (max-width: 1200px) {
    .analytics-tab {
        padding-left: 9px;
        padding-right: 9px;
        margin-bottom: 120px;

        .title {
            font-size: 16px;
            line-height: 21px;
            margin-left: 20px;
            margin-top: 16px;
            margin-bottom: 16px;
        }

        .category-item {
            margin-left: 0;
            margin-right: 0;
            margin-bottom: 16px;

            .item-title {
                font-size: 14px;
                line-height: 18px;
            }

            .item-amount {
                font-size: 20px;
                line-height: 26px;
            }
        }

    }

    .nd-from-to {
        margin-left: 0px;
        margin-right: 0px;
    }

    .from-to {
        font-size: 16px;
        line-height: 21px;
        input {
            width: 115px;
        }
    }

    .category-item-inline {
        padding: 16px 14px;
        margin-left: -24px;
        margin-right: -24px;
        > div {
            min-width: 104px;
        }

        .item-title, .item-amount {
            font-size: 14px;
        }

        .image {
            display: inline-flex;
            justify-content: left;
            align-items: center;
        }

        img {
            height: 8px;
        }
    }

    .popup-background {
        .popup-block {
            width: 96%;
            height: 275px;
            .popup-title {
                .popup-close {

                }
            }
            .popup-body {
   
            }
        }
    }


}



    .table-line {
        display: flex;
        padding: 6px 15px;
       /* margin: 0 15px;*/
        border-bottom: 1px solid #D9D9D9;
        div{
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: #000000;
            overflow: hidden;
            white-space: nowrap;
            padding:0 5px;
            text-align: left;
            &.time {
                width: 50px;
            }

            &.name {
                width: 180px;
            }

            &.type {
                width: 150px;
            }

            &.amount {
                width: 100px;
            }

            a {
                color: #02B5F5;
            }
        }
    }



    .pad10 {
        padding: 0 10px!important
    }


</style>
