<template>
  <div class="nd-footer">
    <div class="nd-footer-copy">&copy;{{ getFullYear }} Fanspicy</div>

    <ul class="nd-footer-navlinks">
      <li class="footer-navlinks__li"><a class="black" href="https://help.fanspicy.com" target="_blank">{{ $t("general.support") }}</a></li>
      <li class="footer-navlinks__li"><a class="black" href="https://help.fanspicy.com" target="_blank">{{ $t("general.how-it-works") }}</a></li>
      <li class="footer-navlinks__li"><router-link class="black" to="/terms" target="_blank">{{ $t("general.terms") }}</router-link></li>
      <li class="footer-navlinks__li"><router-link class="black" to="/cookie" target="_blank">{{ $t("general.cookie-notice") }}</router-link></li>
      <li class="footer-navlinks__li"><router-link class="black" to="/privacy" target="_blank">{{ $t("general.privacy") }}</router-link></li>
      <li class="footer-navlinks__li"><router-link class="black" to="/contract" target="_blank">{{ $t("general.contract-fan-creator") }}</router-link></li>
      <!--<li class="footer-navlinks__li"><router-link class="black" to="/dmca" target="_blank">{{ $t("general.dmca") }}</router-link></li>
      <li class="footer-navlinks__li"><router-link class="black" to="/usc" target="_blank">{{ $t("general.usc-2257") }}</router-link></li>-->
    </ul>

    <div class="nd-footer-social">
      <a><img src="@/assets/icons/new-design/nd-twitter.svg" alt="Twitter"></a>
      <a><img src="@/assets/icons/new-design/nd-instagram.svg" alt="Instagram"></a>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.nd-footer {
  background: #E3E3EF;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 74px;

  .nd-footer-copy {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #959FAF;
  }

  .nd-footer-navlinks {
    list-style: none;
    margin: 0;
    padding: 0px 160px;

    li {
      display: inline-block;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      padding: 0 10px;
    }
  }

  .nd-footer-social {
    a:first-of-type {
      margin-right: 20px;
    }
  }  
}

@media (max-width: 1200px) {
  .nd-footer {
    justify-content: left;
    height: 174px;
    padding: 16px;
    position: relative;


  .nd-footer-copy {
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
  }

  .nd-footer-navlinks {
    max-width: 188px;
    padding: 0;
    li {
      display: block;
      margin-bottom: 6px;
    }
  }

  .nd-footer-social {
    position: absolute;
    right: 51px;
    top: 38px;
    a:first-of-type {
      margin-right: 20px;
    }
  }  
}

}
</style>

<script>
export default {
  computed: {
    getFullYear() {
      return new Date().getFullYear()
    }
  }
}
</script>