<template>
  <div class="nd-payouts-main" v-if="settings != null">
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ $t("general.payouts") }}
        </b-link>
      </div>
    </div>

    <div class="nd-payouts-main-container">
      <ui-payouts-stats v-model="stats" :settings="settings" @updatePyouts="updatePyouts" />

      <ui-payouts-method v-model="method" :verification="verification" />

      <div no-body class="nd-payouts-main-tabs nd-background">
          <div class="nd-payouts-main-tabs-header">
            <div class="nd-payouts-main-tab" :class="{active: activeTab === 'earnings'}" @click="activeTab = 'earnings'">{{$t('general.earnings')}}</div>
            <div class="nd-payouts-main-tab" :class="{active: activeTab === 'payouts'}" @click="activeTab = 'payouts'">{{$t('general.payouts')}}</div>
            <div class="nd-payouts-main-tab" :class="{active: activeTab === 'analytics'}" @click="activeTab = 'analytics'">{{$t('general.analytics')}}</div>
            <div class="nd-payouts-main-tab" :class="{active: activeTab === 'echarge-back'}" @click="activeTab = 'echarge-back'">{{$t('general.charge-back')}}</div>
          </div>
          <div class="nd-payouts-main-tabs-body">
            <ui-earnings v-if="activeTab === 'earnings'" />
            <ui-payouts v-if="activeTab === 'payouts' && isUpdatedPayouts" />
            <ui-analytics v-if="activeTab === 'analytics'" />
            <ui-charge-back v-if="activeTab ==='echarge-back'" />
          </div>
      </div>
    </div>
   
      
  </div>
</template>

<style lang="scss">
.nd-payouts-main-tabs {
  margin-top: 20px;
  margin-bottom: 20px;

  .nd-payouts-main-tabs-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #E9EBF0;

    .nd-payouts-main-tab {
      border-bottom: 2px solid transparent;
      margin-bottom: -1px;
      padding: 16px 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      text-transform: uppercase;
      color: #959FAF;
      cursor: pointer;
      &.active {
        color: #1E76FF;
        border-color: #1E76FF;
      }
    }
  }

  .nd-payouts-main-tabs-body {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (max-width: 1200px) {
  .nd-payouts-main-tabs {
    margin-top: 16px;

    .nd-payouts-main-tabs-header {
      justify-content: space-between;

    .nd-payouts-main-tab {
      font-size: 12px;
      line-height: 18px;
      padding: 10px 8px;
    }
  }

  .nd-payouts-main-tabs-body {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  }
}
</style>

<script>
import PayoutMethod from "../models/PayoutMethod";
import Payout from "../models/Payout";
import UiPayoutsStats from "../ui/UiPayoutsStats.vue";
import UiPayoutsMethod from "../ui/UiPayoutsMethod.vue";
import Verification from "../models/Verification";
import UiPayouts from "../ui/UiPayouts.vue";
import UiChargeBack from "../ui/UiChargeBack.vue";
import UiEarnings from "../ui/UiEarnings.vue";
import UiAnalytics from "../ui/UiAnalytics.vue";
export default {
  props: {
    verification: Verification,
  },
  components: {
    UiPayoutsStats,
    UiPayoutsMethod,
    UiPayouts,
    UiChargeBack,
    UiEarnings,
    UiAnalytics,
  },
  data: function () {
    return {
      activeTab: 'earnings',
      amount: '',
      method: null,
      stats: {},
      settings: null,
      errors: {},
      isUpdatedPayouts: true
    };
  },
  computed: {
    canWithdraw() {
      return this.stats.currentBalance >= this.settings.payout;
    },
  },
  mounted() {
    this.loadInfo();
  },
  methods: {
    updatePyouts() {
      this.isUpdatedPayouts = false 
      console.log('isUpdatedPayouts', this.isUpdatedPayouts)
      this.$nextTick(()=> {
        
        this.isUpdatedPayouts = true
        console.log('isUpdatedPayouts', this.isUpdatedPayouts)
      })
    },

    loadInfo() {
      this.$get(
        "/payouts/info",
        (data) => {
          this.method = data.method ? new PayoutMethod(data.method) : null;
          data.stats.withdraw = data.stats.withdraw
            ? new Payout(data.stats.withdraw)
            : null;
          this.stats = data.stats;
          this.settings = data.settings;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.nd-payouts-main {
  margin-left: -15px;
  margin-right: -15px;

  .nd-payouts-main-container {

  }
}

@media (max-width: 1200px) {
  .nd-payouts-main {
      margin-left: 1px;
      margin-right: 1px;
      margin-bottom: 120px;

      .nd-payouts-main-container {

      }
    }
}
</style>
