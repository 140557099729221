<template>
  <div class="nd-search" >
    <b-row class="sticky-top">
          <div class="nd-main-header-container">
            <div class="nd-background nd-main-header">
              <b-link @click="$router.go(-1)">
                <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
              </b-link>

              <div class="nd-main-header-controls">
                <app-search class="nd-app-search-page-result" v-model="q" @search="reset"/>
              </div>
            </div>
          </div>
      </b-row>
 
      <ui-posts v-model="posts" />
  </div>
</template>

<style lang="scss">
.nd-search {
  .nd-main-header-controls, .nd-app-search-page-result {
    width: 100%;
  }
  .nd-app-search-page-result {
    margin-bottom: 0;

    .form-control, .btn {
      border: 0;
      padding-left: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
    }
  }
}

@media (max-width: 1200px) { 
  .nd-search {
    padding: 0 16px 120px 16px;
  }
}
</style>

<script>
import AppSearch from "../layout/AppSearch.vue";
import Post from "../models/Post";
import UiPosts from "../ui/UiPosts.vue";
export default {
  data: function () {
    return {
      posts: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      q: this.$route.query?.q || '',
    };
  },
  mounted() {
    this.loadPosts();
    window.addEventListener("scroll", this.updateScroll);
  },
  components: {
    UiPosts,
    AppSearch,
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    reset() {
      this.isLoading = false;
      this.posts = [];
      this.hasMore = false;
      this.page = 1;
      this.loadPosts();
    },
    loadPosts() {
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.isLoading = true;
      this.$get(
        "/posts?q=" + encodeURIComponent(this.q) + "&page=" + this.page,
        (data) => {
          let posts = [...this.posts];
          for (let obj of data.data) {
            posts.push(new Post(obj));
          }
          this.posts = posts;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPosts();
      }
    },
  },
};
</script>
