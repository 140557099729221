<template>
  <div class="nd-message" :class="isAuthor ? 'out' : 'in'">
    <div class="d-flex align-items-end">
      <UiAvatar :user="message.user" />
      <div class="d-flex flex-column w-100 nd-message-container">
        <div v-if="media.length > 0 && message.hasAccess"
          class="media preview-size"
          :class="message.media.length == 1 ? 'single' : 'multiple'">
          <b-link  v-for="(item, index) in media" :key="index" class="item"
          @click="$showPhotoSwipe(message.media, 0)">
            <div class="position-relative">
              <b-img
                :src="item.type == 0 ? getUrl(item.url)  : getUrl(item.screenshot)"
                v-if="item.type >= 0"
              />
              <div v-else class="count">
                {{ "+" + (message.media.length - media.length + 1) }}
              </div>
              <i
                class="bi-play-circle-fill play text-white"
                v-if="item.type == 1"
                style="font-size: 300%; line-height: 1"
              />
            </div>
          </b-link>
        </div>

        <div v-else-if="!message.hasAccess && message.media.length > 0" class="body w-100 rounded">
          <b-aspect aspect="4:3">
            <div class="w-100 h-100 position-relative">
              <div class="d-flex align-items-center h-100">
                <div class="mx-auto">
                  <i
                    class="bi-lock-fill align-self-center text-muted"
                    style="font-size: 300%; line-height: 1"
                  />
                  <div
                    class="d-flex text-muted small justify-content-center mt-2"
                  >
                    <div class="mr-1" v-if="message.images > 0">
                      <i class="bi-image" /> {{ message.images }}
                    </div>
                    <div class="ml-1" v-if="message.videos > 0">
                      <i class="bi-camera-video" /> {{ message.videos }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="position-absolute p-3"
                style="bottom: 0; left: 0; right: 0"
              >
                <b-button
                  variant="primary"
                  size="sm"
                  block
                  @click.prevent="unlock"
                  >{{
                    $t("general.unlock-message-for-x", [message.priceFormatted])
                  }}</b-button
                >
              </div>
            </div>
          </b-aspect>
        </div>

        <div v-if="message.items.payment" class="body rounded wrap">
          <i18n
            :path="
              'general.' +
              (message.items.payment.items.post
                ? isAuthor
                  ? 'tip-message-post-out'
                  : 'tip-message-post-in'
                : isAuthor
                ? 'tip-message-out'
                : 'tip-message-out')
            "
            tag="div"
          >
            <template v-slot:amount>
              {{ $formatAmount(message.items.payment.amount) }}
            </template>
            <template v-slot:post v-if="message.items.payment.items.post">
              <b-link :to="message.items.payment.items.post.url">{{
                $t("general.post")
              }}</b-link>
            </template>
          </i18n>
        </div>

        <div v-if="messageText" class="nd-message-text">
           <div>{{ messageText }}</div>
           <div class="nd-time">
              <i class="bi-check2" v-if="isAuthor && !message.isRead" />
              <i class="bi-check2-all text-primary" v-if="isAuthor && message.isRead" />
              <div>{{ message.time }}</div>
           </div>
           <div class="nd-translate-msg-btn" v-if="!isAuthor && !isLanguageTranslateTurnOn" @click="handleTranslateClick">
            <img src="@/assets/icons/google-translate.svg" />
           </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.preview-size {
  min-width: 220px;
  max-width: 100%;
}

.nd-message {
  margin: 20px 0;
  &.in {
    .wrapper {
      align-items: flex-start !important;
    }

    .nd-message-container {
      margin-right: 40px;
    }

    .nd-avatar {
      margin-right: 20px;
    }

    .nd-message-text {
      color: #FFFFFF;
      background: #1E76FF;
    }

    .nd-time {
      opacity: 0.7;
      font-weight: 500;
    }
  }

  &.out {
    & > div {
      flex-direction: row-reverse;
    }

    .wrapper {
      align-items: flex-end !important;
    }

    .nd-message-container {
      margin-left: 40px;
    }

    .nd-avatar {
      margin-left: 20px;
    }

    .nd-message-text {
      color: #333333;
      background: #F5F5FB;
    }

    .nd-time {
      font-weight: 500;
      color: #333333;
      opacity: 0.7;
    }
  }

  .nd-avatar {
    width: 48px;
    height: 48px;
    .b-avatar {
      width: inherit;
      height: inherit;
    }

    .nd-is-creator {
      width: 13px;
      height: 18px;
    }

    .b-avatar-badge {
      img {
        height: 18px;
      }
    }
  }

  .nd-message-text {
    position: relative;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    padding: 10px;
    border-radius: 10px;
    min-height: 50px;
  }

  .nd-time {
    display: flex;
    align-items: center;
    justify-content: right;
    font-style: normal;
    font-size: 10px;
    line-height: 13px;
    font-weight: 500;
  }

  .nd-translate-msg-btn {
    position: absolute;
    right: -38px;
    top: 0;
    bottom: 0;
    width: 16px;

    img {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
      height: 16px;
      cursor: pointer;
      filter: invert(65%) sepia(18%) saturate(233%) hue-rotate(178deg) brightness(92%) contrast(96%);
    }
  }

  .media {
    background: #E3E3EF;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.multiple {
      flex: 1;
      display: grid;
      grid-gap: 2px;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: min-content;
      .item {
        > div {
          height: 0;
          padding-bottom: 100%;
          position: relative;
          img {
            overflow: hidden;
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
          }
          .count {
            color: $black;
            font-size: 250%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }
    }
    &.single {
      padding: 0 !important;
      .item {
        max-width: 100%;
        > div {
          max-width: 100%;
          img {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
.nd-message {
  margin: 16px 0;

  &.in {
    .wrapper {
      align-items: flex-start !important;
    }

    .nd-message-container {
      margin-right: 30px;
    }

    .nd-avatar {
      margin-right: 8px;
    }
  }

  &.out {
    & > div {
      flex-direction: row-reverse;
    }

    .wrapper {
      align-items: flex-end !important;
    }

    .nd-message-container {
      margin-left: 30px;
    }

    .nd-avatar {
      margin-left: 8px;
    }
  }

  .nd-avatar {
    width: 40px;
    height: 40px;
    .b-avatar {
      width: inherit;
      height: inherit;
    }

    .nd-is-creator {
      width: 13px;
      height: 18px;
    }

    .b-avatar-badge {
      img {
        height: 18px;
      }
    }
  }

  .nd-translate-msg-btn {
    right: -30px;
  }

  .media {
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

}
</style>


<script>
import Message from "../models/Message";
import Payment from "../models/Payment";
import UiAvatar from "./UiAvatar";

export default {
  components: {UiAvatar},
  props: ["value"],
  data(){
    return {
      decodific: true,
      translatedMessage: "",
      currentMessageLanguage: "",
      isLanguageTranslateManual: false,
      storageUrl: process.env.VUE_APP_STORAGE_URL,
    }
  },

  async created (){
    this.isLanguageTranslateManual = this.message.isLanguageTranslateManual
    if (!this.isAuthor) {
      this.$root.$on('languageSwitched', async() => {
         this.$emit('setTranslateMessage', {id: this.message.id, translatedMessage: ''})
         this.translatedMessage = ''

        await this.handleTranslateEvent();
      });

      await this.handleTranslateEvent();
    }
  },
  watch: {
    isLanguageTranslateTurnOn(newValue) {
      if (!newValue && !this.isAuthor) {
        this.translatedMessage = "";
        this.currentMessageLanguage = "";
      }
    }
  },
  computed: {
    message: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    messageText() {
      if(this.isLanguageTranslateTurnOn || this.isLanguageTranslateManual)
        return this.translatedMessage || this.message.translatedMessage || this.message.message;

      return this.message.message
    },
    isLanguageTranslateTurnOn() {
      return this.$store.state.isLanguageTranslateTurnOn;
    },
    isAuthor() {
      return this.message.user.id == this.$store.state.currentUser.id;
    },
    media() {
      let m = [];
      const l = this.message.media.length;
      if (l > 4) {
        m = this.message.media.slice(0, 3);
        m.push({
          type: -1,
        });
      } else if (l == 3) {
        m = this.message.media.slice(0, 1);
        m.push({
          type: -1,
        });
      } else {
        m = this.message.media;
      }
      return m;
    },
  },
  methods: {
    getUrl(url) {
        if(typeof url === 'string' && !url.includes(this.storageUrl)) {
          return this.storageUrl + url
        }
        else return url
    },

    unlock() {
      this.$buyItem({
        type: Payment.TYPE_MESSAGE,
        message: this.message,
        success: (data) => {
          this.message = new Message(data);
        },
      });
    },

    handleTranslateClick() {
      this.isLanguageTranslateManual = !this.isLanguageTranslateManual
      this.$emit('setTranslateManual', {id: this.message.id, isLanguageTranslateManual: this.isLanguageTranslateManual})

      const languageToTranslate = this.$store.state.languageToTranslate || this.$i18n.locale;
      if (this.currentMessageLanguage === languageToTranslate) {
        this.translatedMessage = this.message.translatedMessage;
        this.currentMessageLanguage = "";
        return;
      }

      this.translateMessage();
    },

    async handleTranslateEvent() {
      if (!this.isLanguageTranslateTurnOn) return;

      await this.translateMessage();
    },

    async translateMessage() {
      if (this.isAuthor || this.message.translatedMessage) return;

      const languageToTranslate = this.$store.state.languageToTranslate || this.$i18n.locale;
      let translated = await this.$translate(this.message.message, languageToTranslate);
      this.translatedMessage = translated;
      this.$emit('setTranslateMessage', {id: this.message.id, translatedMessage: translated})
      this.currentMessageLanguage = languageToTranslate;
    }
  },
};
</script>