<template>
  <div class="nd-comments w-100">
    <div class="list" v-if="comments.length">
      <ui-comment
        v-for="(item, index) in comments"
        :key="index"
        v-model="comments[index]"
        @reply="reply"
      />
    </div>
    
    <div class="nd-post-bottom-panel sticky-bottom" ref="commentForm">
      <b-form>
        <UiAvatar/>

        <div class="nd-post-input">
          <b-form-input
            v-model="message"
            type="text"
            :placeholder="$t('general.add-a-comment')"
          />
        </div>

        <div class="nd-post-button">  
          <b-button @click.prevent="submitComment" :disabled="message.length == 0">
            <img src="~@/assets/icons/new-design/nd-send.svg"/>
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.nd-comments {
  padding-bottom: 10px;
  margin-bottom: 58px;

  .nd-post-bottom-panel{
    padding: 20px 16px 25px 16px;
    background: white;
    form {
      display: flex;
      align-items: center;

      .nd-post-input {
        width: 100%;
        margin-left: 8px;
        input {
          height: 32px;
          width: 100%;
          border: 0;
          background: #E9EBF0;
          border-radius: 30px;
          font-style: normal;
          font-weight: 400;
          font-size: 10px;
          line-height: 13px;
          color: #333333;
        }
      }
    
      .nd-post-button {
        button {
          background: none;
          border: 0;
          width: 42px;
          margin-right: -12px;
          height: 32px;
          padding: 0 0 0 0px;
    
          &:focus, .btn-secondary.focus {
              color: #fff;
              background-color: transparent;
              border-color:  transparent;
              box-shadow: none;
          }

          &.disabled {
              opacity: 1;
              img {
                filter: invert(67%) sepia(26%) saturate(171%) hue-rotate(178deg) brightness(89%) contrast(50%);
              }
          }
        }
      }
    }

    .nd-avatar {
      width: 36px;
      height: 36px;
      .b-avatar {
        width: inherit;
        height: inherit;
      }

      .nd-is-creator {
          width: 13px;
          height: 18px;
      }

      .b-avatar-badge {
        img {
          height: 18px;
        }
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .nd-comments {
    padding-bottom: 0;
    .sticky-bottom {
      box-shadow: 0px -4px 30px rgba(164, 174, 189, 0.33), inset 0px 1px 2px rgba(149, 159, 175, 0.3);
      bottom: 5rem;
      padding-top: 8px;
      padding-bottom: 40px;
      margin-left: -16px;
      margin-right: -16px;
    }
  }
}
</style>

<script>
import UiComment from "./UiComment.vue";
import Comment from "../models/Comment";
import UiAvatar from "./UiAvatar";
export default {
  components: {UiAvatar, UiComment },
  data: function () {
    return {
      comments: [],
      message: "",
      replyTo: null,
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  props: {
    postId: Number,
  },
  mounted() {
    this.loadComments();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadComments() {
      this.isLoading = true;
      this.$get(
        "/comments/" + this.postId + "?page=" + this.page,
        (data) => {
          const comments = [...this.comments];
          for (let d of data.data) {
            comments.push(new Comment(d));
          }
          this.comments = comments;
          this.isLoading = false;
          this.hasMore = data.next_page_url != null;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadComments();
      }
    },
    submitComment() {
      this.$post(
        "/comments/" + this.postId,
        {
          message: this.message,
        },
        (data) => {
          const comments = [...this.comments];
          comments.push(new Comment(data));
          this.comments = comments;
          this.message = "";
          this.replyTo = null;
          this.$nextTick(function () {
            scrollTo({
              top: document.body.scrollHeight,
              behavior: "smooth",
            });
          });
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    reply(comment) {
      this.replyTo = comment;
      this.message = "@" + comment.user.username + ", " + this.message;
    },
    unreply() {
      this.replyTo = null;
    },
  },
};
</script>
