import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(localizedFormat);

export default class Payout {

    static STATUS_PENDING = 0;
    static STATUS_COMPLETE = 1;
    static STATUS_BATCHES = 2;

    static TYPE_BANK = 1;
    static TYPE_CASH = 2;
    static TYPE_PAXUM = 3;

    id = 0;
    amount = 0;
    status = Payout.STATUS_PENDING;
    info = {};
    created_at = dayjs();
    updated_at = dayjs();
    status_name = ''
    payout_name = ''
    user = {}

    constructor(data) {
        this.id = data.id;
        this.status = data.status;
        this.created_at = dayjs(data.created_at);
        this.updated_at = dayjs(data.updated_at);
        this.info = data.info;
        this.amount = data.amount;
        this.status_name = data.status_name;
        this.payout_name = this.statusName;
        this.user = data.user;
    }

    get statusName() {
        let nameCode = ''
        if(this.info?.type) {
            switch (this.info.type) {
                case Payout.TYPE_BANK:
                    nameCode = 'type_bank'
                    break;
                case Payout.TYPE_CASH:
                    nameCode = 'type_cash'
                    break;
                case Payout.TYPE_PAXUM:
                    nameCode = 'type_paxum'
                    break;
                default:
                    nameCode = ''
            }
        }

        return nameCode
    }

}

export function mapTypes() {
    return {
        [Payout.TYPE_BANK]: 'bank',
        [Payout.TYPE_CASH]: 'cash',
        [Payout.TYPE_PAXUM]: 'paxum',
    }
}