<template>
  <div class="post w-100 border-bottom mb-2 mb-md-3 nd-background" v-if="showPost">
    <div class="d-flex nd-avatar-name-post-block w-100 align-items-start nowrap">

      <!--      avatar-->
      <UiAvatar :user="post.user"
                v-if="!someThumbnailIsLoading"
                content-class="bg-secondary text-white nd-avatar-in-post"
      />

      <b-skeleton v-else
                  type="avatar"
                  size="48px"
                  style="margin-bottom: 30px"
      />
      <!--      avatar end-->

      <!--      user name and profile name-->
      <div class="flex-grow-1 overflow-hidden d-block align-items-center nd-username-block">
        <ui-username v-if="!someThumbnailIsLoading"
                     :user="post.user"
        />
        <b-skeleton v-else
                    width="150px"
        />

        <b-link
            v-if="!someThumbnailIsLoading"
            :to="'/' + post.user.username"
            class="fz-12 black nd-username-in-post d-block"
        >@{{ post.user.username }}
        </b-link>
        <b-skeleton v-else
                    width="100px"
        />
      </div>
      <!--      user name and profile name end-->

      <!--      date and menu-->
      <div v-if="!someThumbnailIsLoading" class="d-flex pt-1 align-items-center nd-dropdown-block">
        <div class="fz-12 mr-2 nd-date-ago">{{ post.ago }}</div>

        <b-dropdown class="ui-dropdown-post" no-caret right variant="link">
          <template slot="button-content"><i class="bi-three-dots"/></template>
          <b-dropdown-item @click.prevent="copyLink">{{
              $t("general.copy-link-to-post")
            }}
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="addToList(post.user)">{{
              $t("general.add-to-list")
            }}
          </b-dropdown-item>
 
          <b-dropdown-item :to="'/posts/edit/' + post.id" v-if="isOwner">{{
              $t("general.edit")
            }}
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="del" v-if="isOwner">{{
              $t("general.delete")
            }}
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-skeleton v-else width="50px"/>
    </div>
    <!--      date and menu end-->

    <!--    poll-->
    <b-card no-body no-footer class="poll m-3" v-if="post.poll.length > 0">
      <template v-if="!someThumbnailIsLoading">
        <b-list-group flush>
          <b-list-group-item
              v-for="(poll, index) in post.poll"
              :key="index"
              href="#"
              @click.prevent="vote(poll.id)"
              class="item"
          >
            <div class="title d-flex align-items-center">
              <div class="flex-grow-1">{{ poll.option }}</div>
              <div class="votes text-muted small" v-if="post.hasVoted">
                {{ poll.votes_count }}
              </div>
            </div>
            <div class="percent p-1">
              <div
                  :style="'width: ' + calcPercent(poll.id) + '%'"
                  class="rounded"
              />
            </div>
          </b-list-group-item>
        </b-list-group>
        <b-card-footer>{{ $tc("general.x-votes", [totalVotes]) }}</b-card-footer>
      </template>
      <template v-else>
        <b-skeleton type="input"/>
        <b-skeleton type="input"
                    v-for="(poll, index) in post.poll"
                    :key="index"
        />
      </template>
    </b-card>
    <!--    poll end-->

    <!--    post text -->
    <div class="w-100 overflow-hidden nl2br wrap nd-post-text" v-if="!someThumbnailIsLoading" v-html="post.messageParsed"></div>
    <div v-else class="px-3">
      <b-skeleton  width="100px"/>
    </div>
    <!--    post text end -->

    <!--
      :class="{'max-height-100': hasAccess, 'pb-4': !hasAccess }"
    -->
    <div class="media nd-media" v-if="post.media.length">
      <!--      MULTIPLE MEDIA      -->
      <b-container v-if="post.media.length > 1" :class="{processing: someFileIsEncoding}">

        <UiProcessing v-if="someFileIsEncoding || someThumbnailIsLoading"
                      :type="someThumbnailIsLoading ? 'loading' : 'processing'"
                      :classes="{...calcMediaItems, ...{'has-trailer': hasTrailer}}"
                      :items="postItemsLength"
        />

        <b-row v-if="!someFileIsEncoding"
               v-show="!someThumbnailIsLoading"
               :class="calcMediaItems">

          <!--    trailer goes separately and available for everyone    -->
          <div class="multigrid-item nd-media-item" v-if="hasTrailer">
            <UiPlayer :media="post.media[0]"
                      :is-trailer="true"
                      class="video w-100 video-wrapper-multiple"
            />
          </div>

          <div v-for="(item, key) in post.media"
            :key="key"
            :class="{'has-trailer': key === 0 && !!isObject(item.trailers)}"
            class="multigrid-item nd-media-item" 
          >

            <!--    PICTURE     -->
            <img
              v-if="isPicture(item.type) && hasAccess"
              :src="item.url ? storageUrl + item.url : item.url"
              class="multiple multigrid-item__inner"
              @click="openGallery(key, item.type)"
            />

            <!--    VIDEO     -->
            <UiPlayer v-else-if="isVideo(item.type) && hasAccess"
                      :media="item"
                      :key="key"
                      :encoding="someFileIsEncoding"
                      class="video w-100 video-wrapper-multiple multigrid-item__inner"
            />

            <!--    LOCKED     -->
            <UiLocked v-else-if="isLocked(item, key)"
                      :media="post.media"
                      :type="item.type"
                      class="video w-100 video-wrapper-multiple multigrid-item__inner"
            />
          </div>
        </b-row>

        <!--        popup to see the images       -->
        <LightBox
          v-if="post.media.length && hasAccess"
          ref="lightbox"
          :media="lightboxMedia"
          :show-caption="true"
          :show-light-box="false"
        />

      </b-container>

      <!--      SINGLE MEDIA      -->
      <b-container :class="{processing: someFileIsEncoding}" v-else>

        <ui-processing v-if="someFileIsEncoding || someThumbnailIsLoading"
                      :type="someThumbnailIsLoading ? 'loading' : 'processing'"
                      :classes="[{...calcMediaItems, ...{'has-trailer': hasTrailer}}, 'single-post']"
                      :items="postItemsLength"
        />

        <b-row
            v-if="!someFileIsEncoding"
            v-show="!someThumbnailIsLoading" class="single-post justify-content-center"
            :class="{'has-trailer': hasTrailer}"
        >

          <!-- If has trailer -->
          <!-- trailer goes separately and available for everyone -->
          <b-col v-if="hasTrailer"  class="p-0 nd-media-item" :class="{'has-trailer': hasTrailer}">
            <UiPlayer :media="post.media[0]" :is-trailer="true" class="video w-100 video-wrapper-multiple"/>
          </b-col>

          <!-- Singlepost media itself -->
          <b-col v-for="(item, key) in post.media"
                 :key="key"
                 :class="{'has-trailer': key === 0 && !!isObject(item.trailers)}"
                 class="p-0 single-post__item nd-media-item"
          >
            <!--    PICTURE     -->
            <img v-if="isPicture(item.type) && hasAccess"
                :src="item.url ? storageUrl + item.url : item.url"
                @click="openGallery(key, item.type)"
                class="multiple"
            />

            <!--    VIDEO     -->
            <UiPlayer v-else-if="isVideo(item.type) && hasAccess"
                      :media="item"
                      :encoding="someFileIsEncoding"
                      :key="key"
                      class="video w-100 video-wrapper-multiple"
            />

            <UiLocked v-else-if="isLocked(item, key)"
                      :media="post.media"
                      :type="item.type"
            />

          </b-col>

        </b-row>

        <!--        popup to see the images       -->
        <LightBox
            v-if="post.media.length"
            ref="lightbox"
            :media="lightboxMedia"
            :show-caption="true"
            :show-light-box="false"
        />
      </b-container>

    </div>

    
      <!--      unlock (pay) / subscribe button     -->
      <div v-if="!hasAccess" class="nd-pay-button-block">
        <template v-if="!someThumbnailIsLoading">
          <div  v-if="post.user.active_offer" class="nd-pay-button" @click.prevent="unlock">
            {{ post.user.active_offer.message }}
          </div>
          <div v-else @click.prevent="unlock" class="nd-pay-button" >
            {{
              post.isFree
                ? $t("general.subscribe-to-see-for-x", [post.user.priceFormatted || ''])
                : $t("general.unlock-post-for-x", [post.priceFormatted])
            }}</div>
        </template>
        <template v-else>
          <div class="bg-white">
            <b-skeleton type="input"/>
          </div>
        </template>
      </div>

    <!--    post footer   -->
    <div class="d-flex w-100 nd-footer-btns">
      <!--      like-->
      <b-link class="nd-footer-btn"
        href="#"
        @click.prevent="like"
        :disabled="!hasAccess"
        v-if="!someThumbnailIsLoading"
      >
        <img src="~@/assets/icons/new-design/nd-heart.svg" v-if="post.isLiked === false" >
        <img src="~@/assets/icons/new-design/nd-heart-filled.svg" v-else >
      </b-link>
      <b-skeleton v-else width="20px" class="ml-3 mr-2"/>
      <!--      like end -->

      <!--      comment-->
      <b-link class="nd-footer-btn"
              :to="post.url"
              :disabled="!hasAccess"
              v-if="!someThumbnailIsLoading"
      >
        <img src="~@/assets/icons/new-design/nd-comments.svg" >
      </b-link>
      <b-skeleton v-else width="20px" class="mx-2"/>
      <!--      comment end-->

      <!--      tip  -->
      <b-link class="nd-footer-btn"
        :disabled="!hasAccess"
        @click.prevent="tip"
        v-if="!isOwner"
      >
        <template v-if="!someThumbnailIsLoading">
          <img src="~@/assets/icons/new-design/nd-tip.svg">
          <span class="ml-1 fz-12 font-weight-bold">{{ $t("general.send-tip") }}</span>
        </template>
        <template v-else>
          <b-skeleton width="20px"/>
        </template>
      </b-link>
      <!--      tip end  -->

      <!--      bookmark-->
      <b-link href="#"
              class="nd-footer-btn ml-auto mr-0"
              @click.prevent="bookmark"
              v-if="!someThumbnailIsLoading"
      >
        <img src="~@/assets/icons/new-design/nd-bookmark.svg" v-if="post.isBookmarked === false">
        <img src="~@/assets/icons/new-design/nd-bookmark-filled.svg" v-else>
      </b-link>
      <b-skeleton v-else width="20px" class="ml-2 mr-3 ml-auto"/>
      <!--      bookmark end-->
    </div>

    <!--likes/comments-->
    <div class="d-flex nd-footer-likes-comments"
         v-if="!someThumbnailIsLoading">
      <div class="label">
        {{ $t("general.x-likes", [post.likesCount]) }}
      </div>
      <span class="pl-10 pr-10">|</span>
      <b-link :to="post.url" :disabled="!hasAccess" class="link">
        {{ $t("general.x-comments", [post.commentsCount]) }}
      </b-link>
    </div>
    <div v-else class="d-flex align-items-center pb-2 px-3">
      <b-skeleton  width="50px"  />
    </div>

    <!--likes/comments end-->

    <!--    post footer end     -->
  </div>
</template>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";

$gap: 8px; //gap between elements in post grid

.fullscreen-open {
  overflow: hidden !important;

  body{
    overflow: hidden !important;
  }

  .video{
    z-index: unset !important;
  }
}
.post {
  .nd-media-item{
    border-radius: 10px!important;
    overflow: hidden;

  }
  .nd-footer-likes-comments {
    padding: 14px 20px 20px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;

    .link {
      color: #1E76FF;
    }

    .label {
      color: #333333;
    }
  }

  .nd-footer-btns {
    padding: 20px 20px 0 20px;
    .nd-footer-btn {
      margin-right: 18px;
      color: #959FAF!important;
    }
  }

  .nd-pay-button-block {
    padding: 0 20px ;

    .nd-pay-button {
      height: 48px;
      border: 1px solid #1E76FF;
      border-radius: 30px;
      width:100%;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      text-transform: uppercase;
      color: #1E76FF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
    }
  }

  .nd-media {
    padding: 0 20px 20px 20px;
  }

  .nd-post-text {
    padding: 0 20px 20px 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
  }
  .nd-avatar-name-post-block {
    padding: 20px;
  }

  .nd-dropdown-block {
    .dropdown-menu {
      width: 153px;
      background: #FFFFFF;
      box-shadow: 0px 2px 8px rgba(164, 174, 189, 0.3);

      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
      text-transform: uppercase;
      color: #333333;

      li {
        border-bottom: 1px solid #E9EBF0;
        &:last-of-type {
          border-bottom: 0;
        }
      }

      .dropdown-item {
        padding: 7px 20px;
      }
    }
  }

  .nd-username-block {
    padding: 5px 10px;
  }

  .ui-dropdown-post {
    button {
      border: 0;
      border-radius: 0;
      i {
        font-size: 20px!important;
      }

      i:before {
        border: 0!important;
        border-radius: 0!important;
        color: #1E76FF!important;
        border: 0!important;
        border-radius: 0!important;
        background: none!important;
      }
    }
  }

  .nd-username-in-post{
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #959FAF;
  }

  .nd-date-ago {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #959FAF;
  }


  .nd-avatar-in-post {
    width: 48px;
    height: 48px;
  }

  .nd-is-creator {
    width: 13px;
    height: 18px;
  }

  .b-avatar-badge {
    img {
      height: 18px;
    }
  }

  .switcher {
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .poll {
    .item {
      position: relative;
      .percent {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        & > div {
          background-color: lighten($primary, 40%);
          height: 100%;
          transition: width 0.5s ease-in-out;
        }
      }
      .title {
        position: relative;
        z-index: 1;
      }
    }
  }
  .media {
    max-height: calc(100vh);
    text-align: center;
    overflow: hidden;
    position: relative;
    min-height: 40px;
    &.max-height-100 {
      max-height: 100%;
    }
    .swiper-slide {
      height: unset;
    }
    img {
      cursor: pointer;
      width: 100%;
      display: inline-block;
      max-height: calc(100%);
      -o-object-fit: cover;
      object-fit: cover;
      position: relative;
      z-index: 1;

      &.vib-image {
        max-height: 100vh;
        z-index: 0;
      }

      &.multiple {
        height: 100%;
      }
    }
    .video-wrapper-multiple {
      overflow: hidden;
      .plyr__video-wrapper {
        //height: 290px;
        min-height: 250px;
      }
      .plyr__poster {
        background-size: cover;
      }
    }
    .plyr:-webkit-full-screen .plyr__video-wrapper {
      height: 100% !important;
    }
    .video {
      transform: unset;
      top: 0;
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      justify-content: center;
      //max-height: calc(100vh);
      position: relative;
      /*background: #000;*/
      overflow: hidden;
      z-index: 5;

      &.encoding{
        background: #ddd;
      }

      .video-js {
        height: 100% !important;
          &.video-multiple {
            border-radius: 10px;
            overflow: hidden;
          }
        .vjs-tech {
          height: 100% !important;
        }
      }
    }
    .plyr {
      width: 100% !important;
      height: 100% !important;
    }
  }

  .test-icons {
    position: absolute !important;
    z-index: 15 !important;
    .icon {
      width: 30%;
      height: auto;
      object-fit: contain;
    }
  }
}
.vib-content video{
  object-fit: contain;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -100;
  background-repeat: no-repeat;
  background-size: cover;
}

.vib-footer {
  bottom: inherit;
  width: auto;
  top: 0;
  left: 0;
  height: auto;
  padding: 15px 20px 15px 12px;
  border-bottom-right-radius: 50%;

  .vib-footer-count {
    padding-left: 0;
  }
}

.unlock-button {
    border-radius: 0;
}
//hide lightbox controls
.vib-thumbnail-wrapper, .vib-footer, .vib-arrow {
  display: none;
}

//disable swipe for this iteration
.vib-container {
  touch-action: unset !important;
}

// .has-trailer.multigrid-item {
//   width: 100%;
// }
.multigrid-with-access {
  &.three-post {
    .multigrid-item:nth-child(1),
    .multigrid-item:nth-child(2) {
      .plyr__video-wrapper {
        max-height: 250px;
      }
    }
    .multigrid-item:nth-child(3) {
      .plyr__video-wrapper {
        min-height: 100%;
      }
    }

    .multigrid-item:nth-child(1),
    .multigrid-item:nth-child(2) {
      img {
        min-height: 290px !important;
      }
    }

  }
  &.two-post,
  &.four-post,
  &.above-four-post {
    .multigrid-item {
      img {
        //height: 512px !important;
      }
    }
  }
  &.above-four-post {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
      .multigrid-item {
      width: 50%;
      grid-area: unset;
    }
  }

}
.multigrid-item{
  min-height: 250px;
}
.multigrid-item:nth-child(1) {
  grid-area: A
}
.multigrid-item:nth-child(2) {
  grid-area: B;
}
.multigrid-item:nth-child(3) {
  grid-area: C;
}
.multigrid-item:nth-child(4) {
  grid-area: D;
}
.multigrid-item img {
  border-radius: 5px;
}
.two-post {
  gap: $gap;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "A B";

  .plyr__video-wrapper{
    //height: 512px !important;
  }
}

.three-post {
  display: grid;
  gap: $gap;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "A C"
    "B C";
}
.four-post {
  gap: $gap;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "A C"
    "B D";

  .multigrid-item{
    height: 250px!important;
  }
}

//Высоту поста с 2 и более медиа внутри мы фиксируем 508px.
.single-post.has-trailer {
  height: 508px;
}

.two-post,
.three-post,
.four-post {
  height: 508px;
}

//Высоту поста с 1 медиа внутри мы фиксируем 508px.
.single-post__item {
  height: 508px;

  .has-trailer & {
    height: 508px;
  }
}

.multigrid-no-access {
  &.three-post {
    //grid-template-columns: auto;
    &.with-trailers {
      //grid-template-columns: fit-content(50%);
    }
  }
  &.above-four-post {
    gap: $gap;
    grid-auto-flow: row;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: fit-content(50%);
    grid-template-areas:
      "A C"
      "B D";
    &.with-trailers {
      .multigrid-item:nth-child(n+5) {
        display: none;
      }
    }
    .multigrid-item:nth-child(4) .locked-image{
      filter: brightness(40%);
    }
    .multigrid-item:nth-child(4) .locked-available {
      position: absolute;
      z-index: 25;
      font-size: 32px;
      color: $white;
      margin-bottom: 120px;
    }
  }
}

.single-post {
  &.has-trailer{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: $gap;
  }

  &__item {
    .video-wrapper-multiple {
      .plyr__video-wrapper {
        //height: 512px !important;
      }
    }
    .process-animation {
      height: 512px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #ddd;
    }
  }
}

.locked-block {
  width: 100%;
  height: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  background: #E3E3EF!important;
  border-radius: 10px!important;  

  .locked-image {
    height: 100%;
    object-fit: cover;
  }
}

.video {
  &:after, &:before {
    //content: "";
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:after {
    width: 0;
    height: 0;
    border: 13px solid transparent;
    border-left: 13px solid #fff;
    margin-top: -17px;
    margin-left: -9px;
    -webkit-filter: drop-shadow(0px 0px 2px rgba(0,0,0,0.25));
    z-index: 3;
  }
  &:before {
    z-index: 2;
    margin-top: -34px;
    margin-left: -34px;
    width: 60px;
    height: 60px;
    border: 3px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(black, 0.18);
    background: rgba(black, 0.42);
  }
  &:hover {
    &:before {
      background: rgba(black, 0.7);
    }
  }
}

.processing{
  min-height: 350px;
}

</style>

<script>
import some from 'lodash/some';
import each from 'lodash/each';
import every from 'lodash/every';
import { Swiper } from "swiper";
import LightBox from 'vue-it-bigger'
import 'vue-it-bigger/dist/vue-it-bigger.min.css'
import "swiper/swiper-bundle.css";
import Post from "../models/Post";
import Payment from "../models/Payment";
import UiUsername from "./UiUsername.vue";
import UiPlayer from "./UiPlayer";
import UiLocked from "./UiLocked";
import UiProcessing from "./UiProcessing";
import UiAvatar from "./UiAvatar";


export default {
  components: {UiAvatar, UiProcessing, UiLocked, UiPlayer, UiUsername, LightBox },
  data: function () {
    return {
      slide: 1,
      items: [],
      players: [],
      loaded: 0,
      forceShowLoading: true, // request to show loader by default some time, for test
      storageUrl: process.env.VUE_APP_STORAGE_URL
    };
  },
  props: {
    value: Post,
  },
  computed: {
    post: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isOwner: function () {
      return (
          this.$store.state.currentUser.isAdmin ||
          (this.value && this.value.user.id == this.$store.state.currentUser.id)
      );
    },
    hasAccess: function () {
      return this.$store.state.currentUser.isAdmin || this.post.hasAccess;
    },
    totalVotes() {
      let total = 0;
      for (let p of this.value.poll) {
        total += p.votes_count;
      }
      return total;
    },

    // when at least one file is encoding, don`t show file to public
    showPost: function () {
      return this.post != null && (this.someFileIsEncoding === false || this.isOwner)
    },

    // when at least one file is encoding or some Thumbnail Is Loaded
    someFileIsEncoding: function () {
      //check if its video (type === 1) and not encoded
      return !!this.post && some(this.post.media, media => {
          return media.type === 1 && media.encode === 0
      })
    },

    someThumbnailIsLoading(){
      // return true
      return this.lightboxMedia.length !==0 && (this.lightboxMedia.length > this.loaded || this.forceShowLoading === true)
    },

    //if post has trailer
    hasTrailer(){
      return this.post.media.length > 0 && this.isObject(this.post.media[0].trailers) && this.post.hasTrailer
    },

    postItemsLength(){
      let count = 0;
      if(!Array.isArray(this.post.media) || !this.post.media.length)
        return count;

      if(this.isObject(this.post.media[0].trailers)){
        count = count + 1;
      }

      return count + this.post.media.length;

    },

    calcMediaItems() {
      const mediaItems = this.post.media.length;
      const isHasAccess = this.hasAccess;

      let mediaItemsClasses = {}
      // if has trailers
      if (this.hasTrailer) {
        mediaItemsClasses = {
          'three-post': mediaItems === 2,
          'four-post': mediaItems === 3,
          'above-four-post': mediaItems >= 4,
          'with-trailers': true
        }
      } else {
        mediaItemsClasses = {
          'two-post': mediaItems === 2,
          'three-post': mediaItems === 3,
          'four-post': mediaItems === 4,
          'above-four-post': mediaItems > 4,
        }
      }
      if (isHasAccess) {
        mediaItemsClasses['multigrid-with-access'] = isHasAccess;
      } else {
        mediaItemsClasses['multigrid-no-access'] = !isHasAccess;
      }
      return mediaItemsClasses;
    },

    lightboxMedia() {
      const mediaPrepared = [];

      this.post.media.forEach(item => {                   
        if (this.isPicture(item.type)) {
          const obj = {
            thumb: item.url ? this.storageUrl + item.url : item.url,
            src: item.url ? this.storageUrl + item.url : item.url
          }
          mediaPrepared.push(obj);
        } else {
          const obj = {
            thumb: item.screenshot,
            sources: [
              {
                src: item.url ? this.storageUrl + item.url : item.url,
                type: 'video/mp4',
              },
            ],
            type: 'video',
          }
          mediaPrepared.push(obj);
        }
      });

      return mediaPrepared;
    },

  },
  created(){

  },
  mounted() {
    this.init();
    this.onLoadMedia();
  },
  watch: {
    //wait for all thumbnails in video to load + 1sec to ensure the browser rendering is complete
    loaded(val) {
      if(val >= this.lightboxMedia.length) {
        setTimeout(() => {
          this.forceShowLoading = false; // request to show loader by default!
        }, 1000)
      }
    }
  },
  methods: {
    init() {
      var that = this;
      if (that.$refs.swiper) {
        let s = new Swiper(that.$refs.swiper, {
          // Optional parameters
          direction: "horizontal",
          noSwipingClass: "vjs-control",
        });
        s.on("activeIndexChange", function () {
          that.slide = this.activeIndex + 1;
        });
        s.on("slideChange", function () {
          for (let p of that.players) {
            p.pause();
          }
        });
      }
      if (that.$refs.video) {
        if (!Array.isArray(that.$refs.video)) {
            that.$refs.video = [ that.$refs.video ];
        }
      }
    },
    /*
      check if some thumbnail is loading. reset in 5 sec (fallback)
     */
    onLoadMedia(){
      if(!Array.isArray(this.lightboxMedia) || !this.lightboxMedia.length || every(this.lightboxMedia, item => !item.thumb))
        return this.loaded = this.lightboxMedia.length

      setTimeout(() => {
        this.loaded = this.lightboxMedia.length
      }, 5000)


      each(this.lightboxMedia, item => {
        let img = new Image();
        img.onload = () => {
          //console.log(item.thumb + ' loaded (post' + this.post.id + ')')
          this.loaded ++
        }

        img.src = item.thumb;
        if (img.complete)
          img.onload();
      })
    },
    isObject(obj) {
      return typeof obj === 'object' && obj !== null && Object.keys(obj).length;
    },
    isLocked(item, key) {
      return  (this.isVideo(item.type) && key < 4 && !this.hasAccess) ||
              (this.isPicture(item.type) && key < 4 && !this.hasAccess)
    },
    openTrailer() {
      this.$refs.trailer.showImage(0)
    },
    prepareTrailerMedia(screenshot, media) {
      const mediaPrepared = [];
      let sources = []

      for (const [key, value] of Object.entries(media)) {
        sources.push({
          key: key,
          src: value ? this.storageUrl + value : value,
          type: 'video/mp4',
        });
      }

      const obj = {
        thumb: screenshot ? this.storageUrl + screenshot : screenshot,
        'sources': sources,
        type: 'video',
      }

      mediaPrepared.push(obj);

      return mediaPrepared;
    },
    openGallery(index, type) {
      if (this.isVideo(type)) {
        return
      }
      this.$refs.lightbox.showImage(index)
    },

    calcPercent(id) {
      if (!this.post.hasVoted) {
        return 0;
      }

      for (let p of this.post.poll) {
        if (p.id == id) {
          return Math.round((p.votes_count / this.totalVotes) * 100);
        }
      }
      return 0;
    },
    like() {
      this.$post(
        "/posts/" + this.post.id + "/like",
        {},
        (data) => {
          this.post.isLiked = data.is_liked;
          this.post.likesCount = data.likes_count;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    bookmark() {
      this.$post(
        "/bookmarks/" + this.post.id,
        {},
        (data) => {
          this.post.isBookmarked = data.is_bookmarked;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    del() {
      this.$post(
        "/posts/" + this.post.id,
        {
          _method: "DELETE",
        },
        () => {
          this.$emit("del", this.post);
          this.post = null;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.post.url}`;
      this.$copyText(link);
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
    vote(id) {
      if (this.post.hasVoted) {
        return;
      }
      this.$post(
        "/posts/" + this.post.id + "/" + id + "/vote",
        {},
        (data) => {
          this.post = new Post(data);
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    unlock() {
      if (this.post.isFree && this.post.user?.active_offer !== undefined) {
        this.$buyItem({
          type: Payment.TYPE_SUBSCRIPTION_PROMOTION,
          user: this.post.user,
        });
        return;
      }

      if (this.post.isFree) {
        this.$buyItem({
          type: Payment.TYPE_SUBSCRIPTION_NEW,
          user: this.post.user,
        });
      } else {
        this.$buyItem({
          type: Payment.TYPE_POST,
          post: this.post,
          success: (data) => {
            this.post = new Post(data);
            this.$nextTick(() => {
              this.init();
            });
          },
        });
      }
    },
    tip() {
      this.$buyItem({
        type: Payment.TYPE_TIP,
        post: this.post,
        user: this.post.user,
      });
    },
    showHidden(item) {
      this.$set(item, 'visible', true)
    },
    
  },
};
</script>