<template>
  <b-sidebar class="nd-left-sidebar" id="sidebar" no-header backdrop>
    <div class="nd-left-sidebar-header">
      <div class="nd-cell-avatar">
        <UiAvatar content-class="nd-avatar-main-menu"/>
      </div>
      <div class="nd-cell-name">
        <div>
          <ui-username :user="currentUser" />
          <div class="nd-username-line2">@{{ currentUser.username }}</div>
        </div>
        <b-button-close class="btn" v-b-toggle.sidebar><i class="bi-x" /></b-button-close>
      </div>
    </div>
    <div class="nd-left-sidebar-counters border-bottom">
      <div>
          <div class="nd-count">{{currentUser.counted_info.likes || 0}}</div>
          <div class="nd-text">Fans</div>
      </div>
      <div>
        <div class="nd-count">{{currentUser.counted_info.subscribers || 0}}</div>
          <div class="nd-text">Subscriptions</div>
      </div>
    </div>

    <b-nav vertical class="border-bottom nd-left-sidebar-menu">
      <ui-nav-button :to="currentUser.url" exact>
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-myprofile.svg">
        </template>
        <template #text>
          {{ $t("general.my-profile") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/bookmarks">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-bookmark.svg">
        </template>
        <template #text>
          {{ $t("general.bookmarks") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/lists">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-list.svg">
        </template>
        <template #text>
          {{ $t("general.lists") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/subscriptions">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-subscriptions.svg">
        </template>
        <template #text>
          {{ $t("general.subscriptions") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/settings">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-gear.svg"/>
        </template>
        <template #text>
          {{ $t("general.settings") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/payments">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-tip.svg"/>
        </template>
        <template #text>
          {{ $t("general.payments") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/payouts" v-if="!isFan">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-bank-card.svg"/>
        </template>
        <template #text>
          {{ $t("general.payouts") }}
        </template>
      </ui-nav-button>

      <ui-nav-button to="/become-a-model">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-become-a-model.svg"/>
        </template>
        <template #text>
          {{ 'Become a model' }}
        </template>
      </ui-nav-button>

      <ui-nav-button href="https://help.fanspicy.com/">
        <template #icon>
          <img src="~@/assets/icons/new-design/nd-support.svg"/>
        </template>
        <template #text>
          {{ $t("general.support") }}
        </template>
      </ui-nav-button>
    </b-nav>

    <div class="nd-left-sidebar-lang">
      <UiSwitchLanguage />
    </div>

    <div class="nd-left-sidebar-logout">
      <div class="nd-logout-btn" @click.prevent="logOut">
        <img src="~@/assets/icons/new-design/nd-logout.svg"/>
        {{ $t("general.logout") }}
      </div>
    </div>

  </b-sidebar>
</template>
<style lang="scss">
.nd-left-sidebar {

.nd-left-sidebar-counters {
  display: flex;
  padding: 0 20px 20px 20px;
  > div:first-of-type {
    border-right: 1px solid #D6F4FF;
    margin-left: -8px;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 8px;
  }

  .nd-count {
    width: max-content;
    padding: 0 9px;
    background: #D6F4FF;
    border-radius: 30px;  
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #1E76FF;
    margin-bottom: 4px;
  }
  .nd-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    color: #959FAF;
  }
}


  .b-sidebar {
    background: #FFFFFF;
    box-shadow: -6px 4px 30px rgba(164, 174, 189, 0.33);
    border-radius: 0px 30px 30px 0px;
  }

  .nd-left-sidebar-header {
    position: relative;
    display: flex;
    padding: 20px 20px 10px 20px;
    height: 103px;

    .nd-cell-avatar {
      height: 72px;
      margin-right: 8px;
    }
  
    .nd-cell-name {
      height: 72px;
      display: flex;
      align-items: center;
      .nd-username-link {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #333333;
      }
  
      .nd-username-line2 {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        color: #959FAF;
      }
    }
  
    .nd-avatar {
      width: 72px;
      height: 72px;
  
      .nd-avatar-main-menu {
        margin-top: 0;
      }
    }
  
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 40px;
      color: #959FAF;
    }
  }

  .nd-left-sidebar-menu {
    padding: 22px;
    .nav-item {
      .nav-link {
        padding: 0;
        margin-bottom: 24px;
        align-items: center;

        .nav-item-text {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          color: #333333;
          margin-left: 14px;
        }
      }
      
      &:last-of-type {
        .nav-link {
          margin-bottom: 0!important;
        }
      }
    }

  }

  .nd-left-sidebar-lang {
    padding: 20px 20px 24px 20px;
    .nd-language-select {
      width: 100%;
    }
  }

  .nd-left-sidebar-logout {
    padding-left: 20px;

    .nd-logout-btn {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #02B5F5;
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        width: 20px;
        height: 16px;
        margin-right: 10px;
      }
    }
  }

}
</style>
<script>
import UiUsername from "../ui/UiUsername.vue";
import UiSwitchLanguage from "../ui/UiSwitchLanguage.vue";
import UiNavButton from "@/components/ui/UiNavButton";
import UiAvatar from "../ui/UiAvatar";
export default {
  components: {UiAvatar, UiNavButton, UiUsername, UiSwitchLanguage },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },

    isFan() {
      return this.$store.state.currentUser?.role === 0
    }
  },
  methods: {
    logOut() {
      this.$post(
        "/auth/logout",
        {},
        () => {},
        (errors) => {
          console.log(errors);
        }
      );
      this.$saveToken(null);
      this.$saveUser(null);
      //this.$router.replace("/");
      window.location.href = '/?logout'
      //location = process.env.VUE_APP_APP_URL;
    },
  },
};
</script>
