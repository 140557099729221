<template>
  <b-row class="nd-messages-chat">
    <b-col class="nd-chat">

      <div class="nd-main-header-container sticky-top">
        <div class="nd-background nd-main-header">
          <b-link @click="$router.go(-1)">
            <img src="~@/assets/icons/new-design/nd-arrow-left.svg"/>
            <template v-if="party">
              {{ party.name}}
            </template>
            <template v-else>
              &nbsp;
            </template>
          </b-link>

          <b-dropdown class="nd-button-dropdown" no-caret right variant="link">
            <template slot="button-content"><i class="bi-three-dots-vertical" /></template>
            <b-dropdown-item @click.prevent="copyLink">{{ $t("general.copy-link-to-profile") }}</b-dropdown-item>
            <b-dropdown-item @click.prevent="addToList(party)">{{ $t("general.add-to-list") }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="nd-chat-container nd-background">
        <div class="nd-chat-messages" ref="conversation">
          <div class="nd-wrapper">
            <div v-for="(message, index) in messages" :key="index">
              <template v-if="isShowMessages">
                <div class="nd-timeline"
                  v-if="
                    index == messages.length - 1 ||
                    (index < messages.length - 1 &&
                      !messages[index + 1].created_at.isSame(
                        message.created_at,
                        'day'
                      ))
                  "
                >
                  <div class="line"></div>
                  <div class="text">{{ $t(message.dayRelated) }}</div>
                  <div class="line"></div>
                </div>
                <ui-message v-model="messages[index]" @setTranslateMessage="setTranslateMessage" @setTranslateManual="setTranslateManual" />
              </template>

            </div>
          </div>
        </div>

        <div class="nd-chat-bottom-panel">
          <ui-media-uploader v-model="media" ref="uploader" class="nd-uploader" v-show="!isHiddenUploader" />

          <div>
            <div class="nd-chat-controls-panel">
              <div class="nd-chat-input">
                <b-form-input
                  v-model="message"
                  type="text"
                  aria-describedby="errors"
                  :placeholder="$t('general.type-message')"
                  :state="Object.keys(errors).length > 0 ? false : null"
                />
              </div>
              <div class="nd-chat-button">  
                <b-button @click.prevent="sendMessage">
                  <img src="~@/assets/icons/new-design/nd-send.svg"/>
                </b-button>
              </div>
            </div>

            <div class="nd-chat-errors" v-if="noAccessMessage || errors._">
              <template v-if="errors._.length">
                <div v-for="(value, key) in errors._" :key="key">
                  <div v-for="(error, k) in value" :key="k">
                    {{ error[0] }} 
                  </div>
                </div>
              </template>

              <div v-if="noAccessMessage">
                {{ noAccessMessage }}
              </div>
              <img src="~@/assets/icons/new-design/nd-errors.svg"/>
            </div>

            <div class="nd-chat-func-btns">
              <div v-if="party && party.role !== 0" class="nd-chat-func-btn" @click="tip">
                <i class="bi-coin" />
              </div>
              <div class="nd-chat-func-btn" @click="mediaDropzoneClick">
                <i class="bi-image" />
              </div>
              <div v-if="currentUser.isCreator" class="nd-chat-func-btn" v-b-modal.modalPrice>
                <i class="bi-tag" />
              </div>

              <div class="nd-chat-func-btn"  v-if="price > 0">
                <ui-post-option-info :info="priceFormat()" @close="priceRemove()" />
              </div>
              <div class="nd-chat-func-btn">
                <UiSwitchButton />
              </div>

              <div class="nd-chat-func-btn">
                <img src="@/assets/icons/google-translate.svg" class="nd-lang-img"/>
                <div>
                  <img v-if="isTranslateTurnOn" @click="isTranslateTurnOn = false" src="~@/assets/icons/new-design/nd-switch-on.svg" />
                  <img v-else @click="isTranslateTurnOn = true"  src="~@/assets/icons/new-design/nd-switch-off.svg" />
                </div>
              </div>

            </div>

          </div>
          <b-modal
            id="modalPrice"
            centered
            ok-only
            @ok="setPrice"
            :title="$t('general.message-price')"
          >
            <b-input-group :prepend="currency">
              <b-form-input
                id="price"
                name="price"
                :placeholder="$t('general.free')"
                v-model="madalPriceAmount"
              />
            </b-input-group>
            <div style="font-size: 80%; color: #dc3545;margin-top: 2px;">{{errorText}}</div>
          </b-modal>
        </div>
      </div>

    </b-col>
  </b-row>
</template>

<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.nd-messages-chat {
  margin-right: -30px;
  margin-left: -30px; 
}
.nd-chat {
  margin-left: 6px;
  .nd-chat-container {
    min-height: calc(100vh - 187px);
    max-height: calc(100vh - 187px);
    overflow: hidden;
    .nd-chat-messages {
      min-height: calc(100vh - 291px);
      max-height: calc(100vh - 291px);
      flex-wrap: wrap;
      overflow-x: hidden;
      overflow-y: auto;
      overscroll-behavior: contain;
      flex: 1;
      display: flex;
      .nd-wrapper {
        width: 100% !important;
        flex-direction: column-reverse;
        display: flex;
        margin: 20px 20px 20px 20px;
      }
     
    }
    .nd-chat-bottom-panel {
      position: relative;
      height: 105px;
      background: #FFFFFF;
      border-top: 1px solid #E9EBF0;
      .nd-uploader {
        position: absolute;
        top: -124px;
        background: white;
        padding-bottom: 16px;
        border-top: 1px solid #E9EBF0;
        .dropzonePreviewContent {
          min-width: 90px;
          .dz-preview{
              flex: 0 0 90px;
              width: 90px;
              height: 90px;
          }
        }
      }
      .nd-chat-controls-panel {
        display: flex;
        flex-wrap: nowrap;
        padding: 12px 20px 5px 20px;
        .nd-chat-input {
          width: 100%;
          input {
            height: 32px;
            width: 100%;
            border: 0;
            background: #E9EBF0;
            border-radius: 30px;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 13px;
            color: #333333;
          }
        }
        .nd-chat-button {
          button {
            background: none;
            border: 0;
            width: 50px;
            padding-right: 0;
            margin-right: -9px;
            height: 32px;
            padding: 0 0 0 17px;
            &:focus, .btn-secondary.focus {
                color: #fff;
                background-color: transparent;
                border-color:  transparent;
                box-shadow: none;
            }
          }
        }
      }
      .nd-chat-errors {
        color: #AA0742;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        padding: 0 20px;
        img {
          margin-left: 8px;
        }
      }
    }
    .nd-chat-func-btns {
      display: flex;
      width: 100%;
      align-items: center;
      margin: 0 -4px;
      padding: 5px 20px 0px 20px;
      .nd-chat-func-btn {
        padding: 0px 4px;
        color: #1E76FF; 
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        &:hover {
          color: #02B5F5;
        }
        
        .nd-lang-img {
          width: 14px;
          height: 14px;
          filter: invert(64%) sepia(22%) saturate(217%) hue-rotate(178deg) brightness(97%) contrast(83%);
          margin-right: 10px;
        }
      }
    }
    .nd-timeline {
      position: relative;
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 13px;
      color: #333333;
      text-align: center;
      //margin: 35px -20px;
      margin: 35px 0;
      display: flex;
      align-items: center;
      .text {
        background: white;
        padding: 0 25px;
        width: 135px;
      }
      .line {
        width: 100%;
        height: 1px;
        background: #E9EBF0;
      }
    }
  }
}
@media (max-width: 1200px) {
  .nd-messages-chat {
    margin: 0 -16px 0px -16px;
  }
  .nd-chat {
    margin-left: 0;
    .nd-chat-container {
      min-height: calc(100vh - 158px);
      max-height: calc(100vh - 158px);
      overflow: inherit;
      .nd-chat-messages {
        min-height: calc(100vh - 182px);
        max-height: calc(100vh - 182px);
        .nd-wrapper {
          margin: 20px 8px 90px 8px;
        }
      }
      .nd-chat-bottom-panel {
      position: sticky;
      bottom: 0;
      height: 101px;
      margin-left: -13px;
      margin-right: -13px;
      z-index: 10;
      .nd-uploader {
        position: absolute;
        top: -124px;
        background: white;
        padding-bottom: 16px;
        border-top: 1px solid #E9EBF0;
        .dropzonePreviewContent {
          min-width: 90px;
          .dz-preview{
              flex: 0 0 90px;
              width: 90px;
              height: 90px;
          }
        }
      }
      .nd-chat-controls-panel {
        display: flex;
        flex-wrap: nowrap;
        padding: 12px 20px 5px 20px;
        .nd-chat-button {
          button {
            margin-right: -9px;
            padding: 0 0 0 8px;
            &:focus, .btn-secondary.focus {
                color: #fff;
                background-color: transparent;
                border-color:  transparent;
                box-shadow: none;
            }
          }
        }
      }
      .nd-chat-errors {
          font-weight: 400;
          font-size: 12px;
          line-height: 16px;
          padding: 0 20px;
          img {
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>

<script>
import Message from "../models/Message";
import UiMessage from "../ui/UiMessage.vue";
import UiSwitchButton from "../ui/UiSwitchButton.vue";
import UiMediaUploader from "../ui/UiMediaUploader.vue";
import UiPostOptionInfo from "../ui/UiPostOptionInfo.vue";
import User from "../models/User";
import Payment from "../models/Payment";
export default {
  props: {
    value: Array,
    chatId: Number,
  },
  data: function () {
    return {
      errorText: '',
      messages: [],
      price: null,
      madalPriceAmount: null,
      errors: {},
      message: "",
      media: [],
      party: null,
      page: 1,
      hasMore: false,
      isLoading: false,
      addedManually: 0,
      noAccessMessage: "",
      isShowMessages: true,
      isHiddenUploader: true
    };
  },
  computed: {
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    isTranslateTurnOn: {
      get() {
        return this.$store.state.isLanguageTranslateTurnOn;
      },
      set(value) {
        this.$store.commit("SET_LANGUAGE_TRANSLATE_STATUS", value);
        if (value) {
          this.$root.$emit("languageSwitched", 'ciao');
        }
      }
    },
    chats: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  watch: {
    chatId: function () {
      this.init();
    },
    madalPriceAmount() {
      if(Number(this.madalPriceAmount) < 3 || Number(this.madalPriceAmount) > 100)
        this.errorText = `The value must be between ${3} and ${100}`
      else 
        this.errorText = ''
    },
    'media.length'() {
      this.isHiddenUploader = !this.media.length ? true : false      
    }
  },
  mounted() {
    this.init();
    this.$refs.conversation.addEventListener("scroll", this.updateScroll);
  },
  components: {
    UiMessage,
    UiMediaUploader,
    UiPostOptionInfo,
    UiSwitchButton
  },
  methods: {
    setPrice(event) {   
      if(this.errorText) event.preventDefault()
      else {
        this.price = this.madalPriceAmount
        this.madalPriceAmount = null
      }
    },
    setTranslateManual(message) {
      //{id: this.message.id, isLanguageTranslateManual: isLanguageTranslateManual}
      let foundMessage = this.messages.find(item => item.id === message.id)
      if(foundMessage) {
        foundMessage.isLanguageTranslateManual = message.isLanguageTranslateManual
      }
    },
    setTranslateMessage(message) {
      //{id: this.message.id, translatedMessage: translated}
      let foundMessage = this.messages.find(item => item.id === message.id)
      if(foundMessage) {
        foundMessage.translatedMessage = message.translatedMessage
      }
    },
    init() {
      this.messages = [];
      this.price = null;
      this.errors = {};
      this.message = "";
      this.media = [];
      this.party = null;
      this.noAccessMessage = "";
      let chats = [...this.chats];
      for (let c of chats) {
        if (c.party.id == this.chatId) {
          this.party = c.party;
          c.message.isRead = true;
        }
      }
      this.page = 1;
      this.hasMore = false;
      this.isLoading = false;
      this.chats = chats;
      this.loadMessages();
    },
    updateScroll() {
      if (
        this.$refs.conversation &&
        this.$refs.conversation.scrollTop <= 200 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    readMessages() {
      for (let m of this.messages) {
        m.isRead = true;
      }
    },
    loadMessages(page) {
      this.isLoading = true;
      this.$get(
        "/messages/" + this.chatId + "?page=" + (page ? page : this.page),
         (data) => {
          const recent =
            this.messages.length > 0 ? this.messages[0].created_at : null;
          parser: for (let obj of data.messages.data) {
            const m = new Message(obj);
            for (let mm of this.messages) {
              if (mm.id == m.id) {
                continue parser;
              }
            }
            if (!recent || m.created_at.isBefore(recent)) {
              this.messages.push(m);
            } else {
              this.addMessage(m);
            }
          }
          this.isLoading = false;
          if (!page) {
            this.hasMore = data.messages.next_page_url != null;
          }
          this.party = this.party ? this.party : new User(data.party);
          let scrollToBottom = () => {
            this.$refs.conversation.scrollTo({
              top: this.$refs.conversation.scrollHeight,
              behavior: "instant",
            });
          }
          if (this.page == 1) {
            this.isShowMessages = false
            this.$nextTick(function () {
              this.isShowMessages = true
               setTimeout(() => { scrollToBottom() }, 0)
            });
          }
          else {
            this.isShowMessages = false
            this.$nextTick(()=>{
              this.isShowMessages = true
               //setTimeout(()=>{scrollToBottom()},0)
            })
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    reloadFirst() {
      this.loadMessages(1);
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        if (this.addedManually >= 20) {
          this.page = this.page + 1;
          this.addedManually = 0;
        }
        this.loadMessages();
      }
    },
    mediaDropzoneClick() {
      this.$refs.uploader.click();
      setTimeout(()=> {
        this.isHiddenUploader = false
      }, 500)
    },
    priceFormat() {
      return this.currency + this.price;
    },
    priceRemove() {
      this.price = null;
    },
    addMessage(message) {
      this.messages.unshift(message);
      if (message.user.id != this.$store.state.currentUser.id) {
        message.isRead = true;
      }
      let valid = [];
      let chats = [...this.chats];
      let found = false;
      for (let c of chats) {
        if (c.party.id == this.party.id) {
          c.message = message;
          found = true;
          valid.unshift(c);
        } else {
          valid.push(c);
        }
      }
      if (!found) {
        valid.unshift({
          party: this.party,
          message: message,
        });
      }
      this.chats = valid;
      let scrollToBottom = () => {
        this.$refs.conversation.scrollTo({
          top: this.$refs.conversation.scrollHeight,
          behavior: "instant",
        });
      }
      this.isShowMessages = false
      this.$nextTick(()=>{
        this.isShowMessages = true
          setTimeout(() => { scrollToBottom() }, 0)
      })
     /* this.$nextTick(function () {
        this.$refs.conversation.scrollTo({
          top: this.$refs.conversation.scrollHeight,
          behavior: "smooth",
        });
      });*/
      this.addedManually++;
    },
    sendMessage() {
      if(this.isLoading) return
      this.isLoading = true
      this.errors = {};
      let media = [];
      for (let m of this.media) {
        media.push({
          id: m.id,
          screenshot: m.scr ? m.scr.id : null,
        });
      }
      let fields = { message: this.message };
      if (media.length > 0) fields.media = media;
      
      if (this.price) fields.price = this.price;
      
      this.$post(
        "/messages/" + this.party.id,
        fields,
        (data) => {
          this.isHiddenUploader = true
          let message = new Message(data);
          this.addMessage(message);
          this.$refs.uploader.clean();
          this.message = "";
          this.media = [];
          this.price = null;
          this.noAccessMessage = "";
          this.isLoading = false
        },
        (errors) => {
          this.isLoading = false
          this.errors = errors;
          if (errors[Symbol.for("response")]?.response?.status === 403) {
            this.noAccessMessage = errors[Symbol.for("response")].response.data.message;
          }
        }
      );
    },
    tip() {
      if(this.party.role === 0) return
      this.$buyItem({
        type: Payment.TYPE_TIP,
        user: this.party,
        success: () => {
          this.loadMessages(1)
        }
      });
    },
  },
};
</script>