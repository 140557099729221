<template>
  <div class="nd-subscriptions" >
      <b-row class="sticky-top">
          <div class="nd-main-header-container">
            <div class="nd-background nd-main-header nd-main-header-2lines-mobile">
              <b-link @click="$router.go(-1)">
                <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
                {{ $t("general.subscriptions") }}
              </b-link>


              <div class="nd-main-header-controls">
                <b-input-group class="nd-main-header-search">
                  <b-form-input placeholder="Search..." v-model="searchText" @update="handleSearch" type="text"></b-form-input>
                  <template #append>
                    <b-input-group-text>
                      <img src="~@/assets/icons/new-design/nd-search.svg">
                    </b-input-group-text>
                  </template>
                </b-input-group>


                <div class="nd-button-dropdown">
                  <b-dropdown no-caret right variant="link">
                    <template slot="button-content">
                      <i class="bi-three-dots-vertical"/>
                    </template>

                    <b-form-radio-group
                      v-model="filterSubs.selected"
                      :options="filterSubs.options"
                      name="filter-subs"
                      stacked
                    />

                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
      </b-row>

      <b-row class="nd-subscriptions-items nd-background">
        <b-col cols="12">
          <div class="nd-filter-tags">
            <div class="nd-filter-tag" @click="changeFilterValue('all')" :class="{active: filterSubs.selected === 'all' }">{{$t("general.all") + " " + subs.length }}</div>
            <div class="nd-filter-tag" @click="changeFilterValue('active')" :class="{active: filterSubs.selected === 'active' }">{{$t("admin.active")  + " " + activeSubsCount }}</div>
            <div class="nd-filter-tag" @click="changeFilterValue('expired')" :class="{active: filterSubs.selected === 'expired' }">{{$t("general.expired")  + " " + expiredSubsCount }}</div>
          </div>
        </b-col>
        <b-col
          xl="3"
          lg="4"
          cols="6"
          v-for="(item, index) in filteredSubs"
          :key="index"
          class="nd-subscriptions-col"
        >
          <ui-user 
            :user="item.sub"
            :subscription="item"
            @unsubscribe="unsubscribe"
            @subscribe="subscribe"
          />
        </b-col>
      </b-row>
  </div>
</template>
<style lang="scss">

.nd-main-header-controls {
  display: flex;
  align-content: center;
}

.nd-main-header-search {
    height: 33px;
    background: #E9EBF0;
    border-radius: 30px;
    overflow: hidden;
    align-items: center;
    .form-control {
      border: 0;
      background: inherit;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      padding-left: 20px;
      height: inherit;
      padding-top: 0;
      padding-bottom: 0;

      &:focus {
        color: #495057;
        background-color: inherit;
        border: 0;
        outline: 0;
        box-shadow: none;
      }
    }

    .input-group-append {
      .input-group-text {
        border: 0;
        padding-right: 18px;

        img {
          filter: brightness(0.4);
          width: 19px;
          height: auto;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .nd-main-header.nd-main-header-2lines-mobile {
      flex-wrap: wrap;
      height: 88px;

      .nd-main-header-controls {
        width: 100%;
      }
    }


    .nd-main-header-search {
        height: 33px;
        margin-right: 15px;
    }

    .nd-main-header-search .form-control {
      font-size: 12px;
      line-height: 16px;
      padding-left: 20px;
    }
  }

.nd-subscriptions {

  .nd-filter-tags {
    margin-bottom: 26px;
    display: flex;
    align-items: center;
    .nd-filter-tag {
      cursor: pointer;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #FFFFFF;
      background: #959FAF;
      border-radius: 30px;
      height: 24px;
      padding: 3px 11px;
      margin-right: 10px;

      &.active {
        background: linear-gradient(90.01deg, #AA0742 0.01%, #9E005D 37.03%, #ED1C24 94.3%);
      }
    }
  }
  .nd-subscriptions-items {
    padding: 23px 5px;

    display: flex;
    flex-wrap: wrap;

    .nd-subscriptions-col {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 1200px) { 
  .nd-subscriptions {
    padding: 0 16px 120px 16px;

    .nd-subscriptions-items {
      padding: 16px 4px; 

      .nd-subscriptions-col {
        padding-right: 8px;
        padding-left: 8px;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
<script>
import Subscription from "../models/Subscription";
import UiUser from "../ui/UiUser.vue";
import dayjs from "dayjs";
export default {
  components: { UiUser },
  data: function () {
    return {
      subs: [],
      page: 1,
      hasMore: false,
      isLoading: false,
      filterSubs: {
        selected: 'all',
        options: [
          { text: this.$t("general.all"), value: 'all' },
          { text: this.$t("admin.active"), value: 'active' },
          { text: this.$t("admin.expired"), value: 'expired' },
          { text: this.$t("general.free"), value: 'free' }
        ]
      },
      searchText: "",
    };
  },
  mounted() {
    this.loadList();
    window.addEventListener("scroll", this.updateScroll);
  },
  computed: {
    activeSubs() {
      return this.subs.filter(sub => {
        return sub.active || !sub.isExpired;
      });
    },
    expiredSubs() {
      return this.subs.filter(sub => {
        return !sub.active && sub.isExpired;
      });
    },
    filteredSubs() {
      switch (this.filterSubs.selected) {
        case "active":
          return this.activeSubs;

        case "expired":
          return this.expiredSubs;

        case "free":
          return this.subs.filter(sub => {
            return sub.amount === 0 && sub.active
          });
      }
      return this.subs;
    },
    activeSubsCount() {
      return this.activeSubs.length;
    },
    expiredSubsCount() {
      return this.expiredSubs.length;
    }
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadList() {
      if (this.isLoading) return;
      
      this.isLoading = true;
      if (this.page == 1) {
        this.$showSpinner();
      }

      if (this.searchText) {
        this.$post(
            "/subscriptions/search",
            {
              value: this.searchText,
              page: this.page
            },
            (data) => {
              this.showSubs(data.sub);
              this.isLoading = false;
            },
            (errors) => {
              console.error(errors);
              this.isLoading = false;
            }
        );
      } else {
        this.$get(
            "/subscriptions?page=" + this.page,
            (data) => {
              this.showSubs(data.subs)
              this.isLoading = false;
            },
            (errors) => {
              console.log(errors);
              this.isLoading = false;
            }
        );
      }
    },
    handleSearch() {
      this.subs = [];
      this.page = 1;
      this.loadList();
    },
    showSubs(subsToShow) {
      let subs = [...this.subs];
      for (let s of subsToShow.data) {
        subs.push(new Subscription(s));
      }
      this.subs = subs;
      this.hasMore = subsToShow.next_page_url != null;
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadList();
      }
    },
    unsubscribe(subscription) {
      this.$deleteRequest(
        "/subscriptions/" + subscription.sub.id,
        {},
        (updatedSub) => {
          const index = this.subs.indexOf(subscription);
          if (index === -1) return;

          if (updatedSub.subscription) {
            this.subs[index].amount = updatedSub.subscription.amount / 100;
            this.subs[index].active = updatedSub.subscription.active;
            this.subs[index].expires = updatedSub.subscription.expires ? dayjs(updatedSub.subscription.expires) : null;
            this.subs[index].created_at = dayjs(updatedSub.subscription.created_at);
          } else {
            this.subs.splice(index, 1);
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    subscribe(subscription) {
      if ((!subscription.active || subscription.isExpired) && subscription.price) {
        const fields = {};

        fields.sub_id = subscription.sub.id;
        fields.amount = subscription.price;
        fields.type = 1;
        this.$post(
            "/payments/",
            fields,
            (updatedSub) => {
              const index = this.subs.indexOf(subscription);
              if (index === -1) return;

              if (updatedSub.subscription) {
                this.subs[index].amount = updatedSub.subscription.amount / 100;
                this.subs[index].active = updatedSub.subscription.active;
                this.subs[index].expires = updatedSub.subscription.expires ? dayjs(updatedSub.subscription.expires) : null;
                this.subs[index].created_at = dayjs(updatedSub.subscription.created_at);
              }

              window.location.reload();
            },
            (errors) => {
              console.log(errors);

              this.$bvToast.toast(this.$t("general.error"), {
                autoHideDelay: 2000,
                title: this.$t("general.error"),
                solid: true,
                toaster: "b-toaster-bottom-left",
              })
            }
        );
      } else {
        this.$post(
          "/subscribe/" + subscription.sub.id,
          {},

          (data) => {
            let subs = [...this.subs];
            let sub = new Subscription(data);
            for (let i in subs) {
              if (subs[i].id == subscription.id) {
                if (sub) {
                  subs[i] = sub;
                  break;
                }
              }
            }
            this.subs = subs;
          },
          (errors) => {
            console.log(errors);

            console.log(errors);
            this.$bvToast.toast(this.$t("general.error"), {
              autoHideDelay: 2000,
              title: this.$t("general.error"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            })
          }
        );
      }
    },
    changeFilterValue(value) {
      this.filterSubs.selected = value;
    }
  },
};
</script>
