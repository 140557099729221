<template>
  <b-form class="nd-payment-form">
    <div v-if="errors._ && errors._.length" class="w-100">
        <div class="nd-error-message" v-for="error in errors._" :key="error">
          {{ error }}
        </div>
    </div>

    <ui-form-input
      id="card_holder"
      v-model="card_holder"
      @input="setCardInfoErrors('card_holder')"
      type="text"
      name="card_holder"
      :errors="errors"
      placeholder="Enter Card Holder Name"
      :label="$t('general.name-on-card')"
    />
    <ui-form-input
      id="card_number"
      name="card_number"
      v-model="card_number"
      @input="setCardInfoErrors('card_number')"
      type="text"
      mask="#### #### #### ####"
      :errors="errors"
      placeholder="Enter Card Number"
      :label="$t('general.card-number')"
    />

    <b-form-group
      :label="$t('general.expiry-date')"
      label-for="expiration_month"
      class="mb-3"
    >
      <b-input-group class="grouping-mobile">
        <b-form-input
          id="expiration_month"
          v-model="expiration_month"
          @input="setCardInfoErrors('expiration_month')"
          class="expiration-input"
          type="text"
          v-mask="'##'"
          placeholder="MM"
          :state="errors['expiration_month'] ? false : null"
        />
        <b-form-input
          id="expiration_year"
          v-model="expiration_year"
          @input="setCardInfoErrors('expiration_year')"
          type="text"
          v-mask="'##'"
          ref="year"
          placeholder="YY"
          :state="errors['expiration_year'] ? false : null"
        />

        <b-form-input
          id="cvc"
          v-model="cvc"
          @input="setCardInfoErrors('cvc')"
          type="text"
          name="cvc"
          v-mask="'###'"
          ref="cvc"
          :placeholder="$t('general.cvc')"
          :state="errors['cvc'] ? false : null"
        />
      </b-input-group>
    </b-form-group>
    <b-alert v-if="serverMessage" show variant="danger">{{serverMessage}}</b-alert>
  </b-form>
</template>
<script>
import UiFormInput from "./UiFormInput.vue";
export default {
  components: { UiFormInput },
  data: function () {
    return {
      card_number: "",
      card_holder: "",
      expiration_month: "",
      expiration_year: "",
      cvc: "",
      errors: {},

      token: "",
      vat: 0,
      previousCardNumber: "",
      vatLoading: false,
      serverMessage: ''
    };
  },
  emit: ['handleCardInfo', 'setLoading'],
  props: {
    isVatCalculated: {
      type: Boolean,
      default: false
    },

    isAutoCalcVAT: {
      type: Boolean,
      default: false
    },

    onlyShowVat: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    reset() {
      this.card_number = "";
      this.card_holder = "";
      this.expiration_month = "";
      this.expiration_year = "";
      this.cvc = "";
      this.errors = {};

      this.token = "";
      this.cardCountry = "";
      this.vat = 0;
    },

    async save() { 
      // Валидируем поля формы и сохраняем ошибки
      // Если isAutoCalcVAT === true то форма уже ранее провалидирована
      if(!this.isAutoCalcVAT) this.setCardInfoErrors('all')

      // Проверяем валидна ли форма
      if (!this.isCardInfoValid()) return

      // TODO: Эмитим событие чтобы залочить кнопку Сохранить
      //this.$emit()

      // Зачитываем карты
      let data = await this.loadMethods()

      // Если у пользователя  есть карты то добавление новой должно быть через эндпоинт /payments/card/add
      if(data?.methods?.length) {
        await this.addCard()
        this.$emit('setLoading', false)
      }
      // Если у пользователя нет карт то отправляем на securionpay для добавления первой карты
      else {
        this.getToken().then(() => {
          this.calcVAT(() => {
            this.emitCardInfo()
          })
        })
      }
    },

    async loadMethods() {
        const url = process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + '/payments/method'
        const response = await fetch(url, {
          method: 'GET', 
          headers: {
            'Authorization': "Bearer " + this.$store.state?.token
          }
        })
        return await response.json()
    },
    
    setCardInfoErrors(key) {
      if(this.isAutoCalcVAT) key = 'all'

      let setAllErrors = () => {
        this.errors = {}

        if (this.expiration_month > 12 || this.expiration_month === 0 || this.expiration_month === '') 
          this.errors.expiration_month = []
        else delete this.errors.expiration_month

        if (
          this.expiration_year === '' || 
          this.expiration_year.length !== 2 || 
          this.expiration_year < Number(new Date().getFullYear().toString().slice(-2))
        ) 
        this.errors.expiration_year = []
        else delete this.errors.expiration_year

        if (this.cvc === '' || this.cvc.length !== 3) 
          this.errors.cvc = []
        else delete this.errors.cvc

        if (this.card_number === '' || this.card_number.replaceAll(' ', '').length < 14) 
          this.errors.card_number = []
        else delete this.errors.card_number

        if (this.card_holder === '' || this.card_holder.length < 3) 
          this.errors.card_holder = []
        else
        delete this.errors.card_holder
      }

      switch(key) {
        case 'expiration_month':
          if (this.expiration_month > 12 || this.expiration_month === 0 || this.expiration_month === '') 
            this.errors.expiration_month = []
          else delete this.errors.expiration_month
        break

        case 'expiration_year':
          if (
            this.expiration_year === '' || 
            this.expiration_year.length !== 2 || 
            this.expiration_year < Number(new Date().getFullYear().toString().slice(-2))
          ) 
            this.errors.expiration_year = []
          else delete this.errors.expiration_year
        break

        case 'cvc':
          if (this.cvc === '' || this.cvc.length !== 3) 
            this.errors.cvc = []
          else delete this.errors.cvc
        break

        case 'card_number':
          if (this.card_number === '' || this.card_number.replaceAll(' ', '').length < 14) 
            this.errors.card_number = []
          else delete this.errors.card_number
        break

        case 'card_holder':
          if (this.card_holder === '' || this.card_holder.length < 3) 
            this.errors.card_holder = []
          else
          delete this.errors.card_holder
        break

        case 'all':
          setAllErrors()
        break
      }

      if(this.isAutoCalcVAT && this.isCardInfoValid()) 
        this.getToken().then(() => {
          this.calcVAT(() => {
            this.$emit('setVAT', this.vat)
          })
        })
    },

    isCardInfoValid() {
      if (Object.keys(this.errors).length !== 0) return false
      
      return true
    },

   /* autoCalculateVat() {
      this.setCardInfoErrors('all');
      if (!this.isCardInfoValid()) return;

      this.calcVatAndGetToken(true);
    },

    calcVatAndGetToken(onlyShowVat) {
      this.getToken().then(() => {
        if (!this.isCardInfoValid()) return;

        this.calcVAT(() => {
          this.emitCardInfo(onlyShowVat);

        });
      });
    },*/

    calcVAT(callback) {
      if (this.previousCardNumber === this.card_number) {
        callback();
        return;
      }

      this.$post(
          // Makes a request with any price, because we only need the VAT percent
          `/get-vat?country=${this.cardCountry}`,
          {},
          (data) => {
            this.vat = data.vat || 0; // data.vat can be 'false'
            this.previousCardNumber = this.card_number;

            callback();
          },
          (errors) => {
            if (errors.price) {
              this.errors.amount = errors.price
            }

            if (errors.cardNumber) {
              this.errors.card_number = errors.cardNumber
            }

            callback()
          }
      );
    },

    async addCard() {
      this.serverMessage = ''
      const dataToSecure = {
        number: this.card_number.replaceAll(" ", ""),
        expYear: this.expiration_year,
        expMonth: this.expiration_month,
        cvc: this.cvc,
        cardholderName: this.card_holder,
      }

      const url = process.env.VUE_APP_API_URL + '/' + process.env.VUE_APP_API_VERSION + '/payments/card/add' +
      `?number=${dataToSecure.number}&expYear=${dataToSecure.expYear}&expMonth=${dataToSecure.expMonth}&cvc=${dataToSecure.cvc}&cardholderName=${dataToSecure.cardholderName}`
      const response = await fetch(url, {
        method: 'POST', 
        headers: {
          'Authorization': "Bearer " + this.$store.state?.token
        }
      })
      let result = await response.json()
      if('errors' in result) {
       /* let errors = {}
        for(const key in result.errors)
        {
          let item = result.errors[key]
          if(key === 'number') errors = {...errors, ...{card_number: item} }
          if(key === 'expYear') errors = {...errors, ...{expiration_year: item} }
          if(key === 'expMonth') errors = {...errors, ...{expiration_month: item} }
          if(key === 'cvc') errors = {...errors, ...{cvc: item} }
          if(key === 'cardholderName') errors = {...errors, ...{card_holder: item} }
        }*/
        
        this.errors = result.errors
      }

      if('message' in result) this.serverMessage = result.message

      // Если нет ошибок обновляем список карт эмитом
      if('title' in result) this.$emit('updateMethods')
  },

   async getToken() {
      // const TOKEN = process.env.VUE_SECURIOPAY;
      const dataToSecure = {
        number: this.card_number.replaceAll(" ", ""),
        expYear: this.expiration_year,
        expMonth: this.expiration_month,
        cvc: this.cvc,
        cardholderName: this.card_holder,
      };

      await fetch("https://api.securionpay.com/tokens", {
        body:  new URLSearchParams(dataToSecure),
        // TODO Replace hardcoded token with token from process.env.VUE_SECURIOPAY;
        headers: {
          Authorization: "Basic cGtfbGl2ZV9UWVNYYkVsZ0RlbXR2SkFTV0RHV2t3MEU6",
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
        })
        .then(response => response.json())
        .then((response) => {
          this.token = response.id;
          this.cardCountry = response.country;
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("errors.payment-method"), {
            autoHideDelay: 2000,
            title: this.$t("general.error"),
            solid: true,
            toaster: "b-toaster-bottom-left",
          });
        });
    },

    emitCardInfo() {
      const cardMask = "****" + this.card_number.slice(-4)

      this.$emit(
        "handleCardInfo",
          cardMask,
          this.token,
          this.vat,
          this.onlyShowVat
      )
    }
  },
}
</script>

<style lang="scss">

.nd-payment-form {

    .form-group {
      margin-bottom: 20px!important;
    }

    .input-group {
      flex-wrap:nowrap;
    }

    label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        margin-bottom: 6px;
      }

    input {
      border: 1px solid #CCD2E3;
      border-radius: 30px!important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      padding: 15px 19px;
      height: 48px;

      &::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #959FAF;
      }
    }

    #expiration_month, #expiration_year {
      min-width: 148px;
      margin-right: 20px;

      &::placeholder {
        text-align: center;
      }
    }

    #cvc {
      width: 100%;

      &::placeholder {
        text-align: center;
      }
    }
  }

  @media (max-width: 1200px) {
    .nd-payment-form {
      input {
        font-size: 14px;
        line-height: 18px;
          padding: 9px 18px;
          height: 32px;
      }

      .grouping-mobile {
        flex-wrap: wrap;
        #expiration_month {
          margin-right: 20px;
        }

        #expiration_year {
          margin-right: 0px;
        }

        #cvc {
          margin-top: 8px;
        }
      }
    } 

  }
</style>