<template>
  <div class="nd-avatar" @click="stopClick">
    <b-avatar
        :src="_user.avatar ? storageUrl + _user.avatar : _user.avatar"
        :text="_user.initials"
        :to="_user.url"
        :size="size"
        :badge-left="!!_user.active_offer"
        :class="contentClass"
    >
      <template #badge v-if="!!_user.active_offer">
        <img src="~@/assets/icons/new-design/nd-discount.png" alt="discount">
      </template>
    </b-avatar>
    <img src="~@/assets/icons/verified.svg" class="nd-is-creator" :isCreatorClass="[isCreatorClass]" v-if="_user.isCreator"/>
  </div>
</template>

<script>
export default {
  name: "UiAvatar",

  props: {
    user: {
      type: Object
    },
    size: {
      type: String,
    },
    contentClass: {default: '', type: String},
    isCreatorClass: {default: '', type: String}
  },

  data() {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },

  computed:{
    _user() {
      return this.user || this.$store.getters['user'];
    },

    currentUser() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    stopClick(event) {
       event.stopPropagation()
    },

  },

}
</script>
