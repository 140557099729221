<template>
  <div>
    <div class="processing process-animation" v-if="type==='processing'">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <p style="font-size: 20px; color: grey" v-show="type === 'processing'">Processing...</p>
    </div>

    <div v-if="type==='skeleton' || type==='loading'" class="post" style="padding-top: 20px">

      <!--      post skeleton (default) -->
      <div class="skeleton-post">

        <div v-show="type !== 'loading'" class="flex w-100 align-items-start nowrap">
          <b-skeleton type="avatar" size="48px" style="margin-bottom: 30px"/>
          <div class="flex-grow-1 px-2 pt-1 overflow-hidden d-block align-items-center">
            <div class="flex align-items-start black">
              <div class="username font-weight-bold">
                <b-skeleton width="150px"/>
              </div>
              <div></div>
            </div>
            <div class="fz-12 black username flex justify-content-start">
              <b-skeleton width="50px"/>
            </div>
          </div>
          <div class="flex pt-1 align-items-center">
            <div class="fz-12 mr-2">
              <b-skeleton width="50px"/>
            </div>
          </div>
        </div>

        <div v-show="type !== 'loading'" class="px-3 pb-2 w-100 black overflow-hidden nl2br wrap flex justify-content-start">
          <b-skeleton width="250px"/>
        </div>

        <div class="grid-loading" :class="classes">
          <div v-for="(item, key) in itemsNormalized" :key="key"
               :class="{'single-item': itemsNormalized === 1, 'double-item': itemsNormalized === 2, 'multigrid-item': itemsNormalized > 2}"
               style="height: 100%">
            <b-skeleton-img height="100%" no-aspect/>
          </div>
        </div>

        <div v-show="type !== 'loading'" class="flex align-items-center py-3 w-100">
          <div class="ml-3 mr-2">
            <b-skeleton width="30px"/>
          </div>
          <div class="mx-2">
            <b-skeleton width="30px"/>
          </div>
          <div class="ml-2 mr-3 ml-auto">
            <b-skeleton width="30px"/>
          </div>
        </div>

        <div v-show="type !== 'loading'" class="flex align-items-center pb-2 px-3">
          <b-skeleton width="130px"/>
        </div>
      </div>
      <!--      post skeleton end-->

    </div>

    <!--      profile skeleton -->
    <div v-if="type==='profile'">
      <div class="rounded user border w-100 pb-2">
        <div class="header">
          <div class="bg-light cover w-100 position-relative rounded-top">
            <div class="buttons m-2">
              <div class="dropdown b-dropdown btn-group flex justify-content-center">
                <b-skeleton width="30px"/>
              </div>
            </div>
          </div>
        </div>
        <div class="subprofile w-100 flex flex-column">
          <div class="avatar rounded-circle flex justify-content-center">
            <b-skeleton type="avatar" size="72px"/>
          </div>
          <div class="mt-1 overflow-hidden w-100">
            <div class="flex align-items-center black card-grid-name">
              <div class="username font-weight-bold flex justify-content-center w-100 mb-3">
                <b-skeleton width="70px"/>
              </div>
            </div>
            <div class="mt-2 mb-2 small username black weight-500 fz-12 flex justify-content-center w-100">
              <b-skeleton width="100px" />
            </div>
          </div>
        </div>
        <div class="px-2 mb-2">
          <div class="btn w-100 text-uppercase fz-12 weight-700 btn-primary flex justify-content-center w-100">
            <b-skeleton width="110px"/>
          </div>
        </div>
      </div>
    </div>
    <!--      profile skeleton -->
  </div>
</template>

<script>
export default {
  name: "UiProcessing",
  props: {
    type: {
      type: String,
      default: 'processing',
      validator(value) {
        // The value must match one of these strings
        return ['processing', 'loading', 'skeleton', 'profile', 'initial'].includes(value)
      }
    },
    classes: {
      type: [Object, Array]
    },
    items: {
      type: Number,
      default: 3
    }
  },

  computed: {
    randomRows() {
      return Array.from(Array(15).keys()).map(num => Math.random() * num * 50)
    },
    itemsNormalized() {
      return this.items > 0 ? this.items : 1
    },
  }
}
</script>

<style scoped>
.flex{
  display: flex;
}
.processing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  z-index: 100;
  background: #F8F9FA;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.grid-loading {
  /*display: grid;*/
  /*gap: 5px;*/
  /*grid-template-rows: 290px 290px;*/
}

.grid-loading.multigrid-with-access.single-post{
  display: flex;
}

.grid-loading.multigrid-with-access.single-post,
.grid-loading.multigrid-with-access.single-post > div,
::v-deep .grid-loading.multigrid-with-access.single-post .b-skeleton-img{
  min-height: 512px;
  flex: 1;
}

.grid-loading > div:nth-child(1) {

}

.grid-loading > div:nth-child(2) {

}

.grid-loading > div:nth-child(3) {

}

>>> .b-aspect {
  height: 100%;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.skeleton-avatar {
  display: flex;
}

.single-item{
  height: 512px !important;
}

.double-item{
  height: 100% !important;
}
</style>