<template>
  <b-row class="nd-messages">
    <b-col
      lg="5"
      cols="12"
      class="chats"
      :class="showChatId != null || isComposing ? 'd-none d-lg-block' : ''"
    >
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ $t("general.messages") }}
        </b-link>
        <b-link to="/messages/compose" v-if="currentUser.isCreator === 'delete-this-string-when-u-need-to-activate-mass-messages'">
            <i class="bi-plus-circle" />
          </b-link>
      </div>
    </div>
   
      <b-row v-if="mass" class="border-bottom small p-3">
        {{
          mass.recipients_count > 0
            ? mass.mass_complete
              ? $t("general.mass-message-complete", [
                  mass.messageTrimmed,
                  mass.recipients_count,
                ])
              : $t("general.mass-message-sending", [
                  mass.messageTrimmed,
                  mass.recipients_count,
                ])
            : $t("general.mass-message-started", [mass.messageTrimmed])
        }}
      </b-row>

      <div class="nd-chat-list nd-background" v-if="chats.length">
        <b-link class="nd-chat-item" v-for="(chat, index) in chats" :key="index" :to="'/messages/' + chat.party.id">
          <div class="nd-chat-item-line1">
            <div class="nd-chat-item-avatar">
              <UiAvatar :user="chat.party"/>
            </div>
            <div class="nd-chat-item-name">
              <div class="name-line1">{{chat.party.name}}</div>
              <div class="name-line2">@{{chat.party.username}}</div>
            </div>
          </div>
          <div class="nd-chat-item-line2">
            {{ chat.message.message }}
          </div>

          <div class="nd-chat-item-right-block">
            <div class="nd-chat-dot" v-if="!chat.message.isRead && !isOwner(chat.message)">
              <i class="bi-dot"/>
            </div>
            <!--:class="!chat.message.isRead && !isOwner(chat.message) ? 'text-primary' : 'text-muted'"-->
            <div class="nd-chat-item-date">
                {{ chat.message.timeRelated ? $t(chat.message.timeRelated) : chat.message.timeNormal }}
            </div>
            <div class="nd-x" @click.prevent="cleanChat(chat.party.id)">
              <i class="bi-x" />
            </div>
          </div>
        </b-link>
      </div>
      <div class="nd-background nd-no-messages-container" v-else>
        <div class="nd-no-messages-block">
          <img src="~@/assets/icons/new-design/nd-smile-sad.svg" />
          <div class="nd-no-mess">no messages</div>
          To write a message, go to the user page
        </div>
      </div>
    </b-col>

    <b-col lg="7" cols="12" :class="showChatId != null || isComposing ? '' : 'd-none d-xl-block'">
      <template v-if="showChatId">
        <page-chat v-if="showChatId != null && !isComposing" ref="chat" :chatId="showChatId" v-model="chats"/>
        <page-mass-message v-model="mass" v-if="isComposing" />
      </template>
      <template v-else>
        <div class="nd-main-header-container sticky-top nd-no-mess-fix-margin">
          <div class="nd-background nd-main-header">
          </div>
        </div>
        <div class="nd-background nd-no-messages-container chat">
          <div class="nd-no-messages-block">
            <div class="nd-no-mess">No conversations</div>
            Select a user to start a conversation!
          </div>
        </div>
      </template>
    </b-col>
  </b-row>
</template>
<style lang="scss">
@import "~@/assets/scss/_variables.scss";
.nd-messages {
  margin-right: -30px;
  margin-left: -30px; 

  .nd-no-mess-fix-margin {
    margin-left: -8px;
    margin-right: -15px;
    width: auto;
  }

  .nd-no-messages-container {
    padding: 20px;
    min-height: calc(100vh - 187px);

    &.chat{
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-left: -8px;
      margin-right: -15px;
      
      .nd-no-messages-block {
        font-size: 16px;
        background: none;
        .nd-no-mess {
          font-size: 20px;
          line-height: 18px;
        }
      }
    }

    .nd-no-messages-block {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      background: rgba(30, 118, 255, 0.09);
      border-radius: 10px;
      height: 150px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      color: #000000;
      padding: 0 20px;

      img {
        margin-bottom: 10px;
      }

      .nd-no-mess {
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 10px;
      }
    }
  }


  
  .nd-chat-list {
    min-height: calc(100vh - 187px);

    .nd-chat-item {
      display: block;
      position: relative;
      padding: 30px 20px 16px 20px;
      border-bottom: 1px solid #E9EBF0;

      .nd-chat-item-line1 {
        display: flex;
        align-items: center;

        .nd-chat-item-avatar {
          margin-right: 10px;
          width: 48px;
          height: 48px;
          .nd-avatar {
            width: 48px;
            height: 48px;

            .b-avatar {
              width: 48px;
              height: 48px;
            }
            .nd-is-creator {
              width: 13px;
              height: 18px;
            }

            .b-avatar-badge {
              img {
                height: 18px;
              }
            }
          }
        }

        .nd-chat-item-name {
          .name-line1 {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 18px;
            color: #333333;
          }

          .name-line2 {
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #959FAF;
          }
        }
      }

      .nd-chat-item-line2 {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 10px;
      }

      .nd-chat-item-right-block {
        display: flex;
        position: absolute;
        top: 10px;
        right: 2px;
        align-items: center;
        max-height: 30px;
        overflow: hidden;
      }

      .nd-chat-item-date {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        color: #959FAF;
      }

      .nd-x {
        color: #959FAF;
        padding: 0 10px;
        font-size: 20px;
      }

      .nd-chat-dot {
        color: #1E76FF;
        font-size: 36px;
        i {
          background: none;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .nd-messages  {
    margin: 0 -16px 76px -16px;

    .nd-no-messages-container{
      padding: 16px 8px;
      min-height: calc(100vh - 155px);
    }

    .nd-chat-list {
      min-height: calc(100vh - 210px);
    }
  }
}
</style>

<script>
import Message from "../models/Message";
import User from "../models/User";
import PageChat from "./PageChat.vue";
import PageMassMessage from "./PageMassMessage.vue";
import UiAvatar from "../ui/UiAvatar";
export default {
  components: {
    UiAvatar,
    PageChat,
    PageMassMessage,
  },
  data: function () {
    return {
      chats: [],
      mass: null,
      showChatId: null,
      doCompose: false,
    };
  },
  computed: {
    chatId() {
      return this.$route.params.id && this.$route.params.id != "compose"
        ? this.$route.params.id * 1
        : null;
    },
    isComposing() {
      return this.$route.params.id == "compose";
    },
    pusher() {
      return this.$store.state.pusher;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    chatId(v) {
      this.showChatId = v;
    },
    pusher() {
      this.listen();
    },
  },
  mounted() {
    this.$verifyEmail()
    this.loadChats();
    this.listen();
  },
  beforeDestroy() {
    this.unlisten();
  },
  methods: {
    listen() {
      if (this.pusher) {
        this.pusher.bind("message", this.handleNewMessage);
        this.pusher.bind("message.read", this.handleMessageRead);
        this.pusher.bind(
          "message.mass.complete",
          this.handleMassMessageComplete
        );
      }
    },
    unlisten() {
      if (this.pusher) {
        this.pusher.unbind("message", this.handleNewMessage);
        this.pusher.unbind("message.read", this.handleMessageRead);
        this.pusher.unbind(
          "message.mass.complete",
          this.handleMassMessageComplete
        );
      }
    },
    handleNewMessage(data) {
      const newMessage = new Message(data.message);
      if (newMessage.user.id == this.showChatId) {
        this.$refs.chat.reloadFirst();
      } else {
        let valid = [];
        let chats = [...this.chats];
        let found = false;
        for (let c of chats) {
          if (c.party.id == newMessage.user.id) {
            c.message = newMessage;
            found = true;
            valid.unshift(c);
          } else {
            valid.push(c);
          }
        }
        if (!found) {
          valid.unshift({
            party: newMessage.user,
            message: newMessage,
          });
        }

        this.chats = valid;
      }
    },
    handleMessageRead(data) {
      if (data.id == this.showChatId) {
        this.$refs.chat.readMessages();
      }
    },
    handleMassMessageComplete(data) {
      const m = new Message(data.message);
      m.recipients_count = data.message.recipients_count;
      m.mass_complete = true;
      this.mass = m;
    },
    isOwner(message) {
      return message.user.id == this.$store.state.currentUser.id;
    },
    loadChats() {
      if (this.chats.length == 0) {
        this.$showSpinner();
      }
      this.$get(
        "/messages",
        (data) => {
          let chats = [];
          for (let obj of data.chats) {
            chats.push({
              party: new User(obj.party),
              message: new Message(obj),
            });
          }
          this.chats = chats;
          if (data.mass) {
            this.mass = new Message(data.mass);
            this.mass.recipients_count = data.mass.recipients_count;
          }
          this.$nextTick(function () {
            this.showChatId = this.chatId;
          });
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    cleanChat(id) {
      this.$post(
        "/messages/" + id,
        {
          _method: "DELETE",
        },
        () => {
          this.loadChats();
          this.$router.replace("/messages");
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>
