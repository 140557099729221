<template>
  <b-card :header="$t('general.payment-methods')" class="nd-background nd-payment-card">
    <div v-if="!adding">
        <div class="nd-payment-card-line mobile-block" v-for="(item, index) in methods" :key="index">
          <div class="d-flex">
            <img src="~@/assets/icons/new-design/nd-credit-card.svg" />
            <span class="nd-payment-card-number">{{ item.title }}</span>
            <span class="nd-payment-card-is-main" v-if="item.main">[{{ $t("general.main") }}]</span>
          </div>

          <div class="d-flex btns" v-if="!item.main">
            <div class="nd-button blue nd-payment-card-btn" @click.prevent="main(item)">{{ $t("general.make-main") }}</div>
            <div class="nd-button gray nd-payment-card-btn" @click.prevent="del(item)">{{ $t("general.delete")}}</div>
          </div>
        </div>
      <div class="mt-20">
        <div class="nd-button nd-payment-card-btn-custome" @click.prevent="add">{{ $t("general.add") }}</div>
      </div>
    </div>

    <div v-else>
      <b-form-invalid-feedback class="d-block" v-if="errors['_'] && errors['_'].length > 0">
        <div v-for="error in errors['_']" :key="error">{{ error }}</div>
      </b-form-invalid-feedback>

      <ui-credit-card ref="cc" @handleCardInfo="handleCardInfo" @updateMethods="updateMethods" :is-vat-calculated="true" />

      <div class="custome-btns">
        <div class="nd-button gray inline nd-payment-card-btn-custome" @click.prevent="unadd">{{ $t("general.cancel") }}</div>
        <div class="nd-button inline nd-payment-card-btn-custome" @click.prevent="save">{{ $t("general.save") }}</div>
      </div>
    </div>
  </b-card>
</template>
<script>
import PaymentMethod from "../models/PaymentMethod";
import UiCreditCard from "./UiCreditCard.vue";

export default {
  components: { UiCreditCard },
  data: function () {
    return {
      methods: [],
      errors: {},
      adding: false,
    };
  },
  mounted() {
    this.loadMethods();
  },
  methods: {
    updateMethods() {
        this.adding = false
        this.loadMethods()
    },

    add() {
      if(!this.$store.state.currentUser.isVerify) {
        this.$verifyEmail()
        return
      }
      this.adding = true;
    },
    
    unadd() {
      this.adding = false;
    },

    loadMethods() {
      this.$get(
        "/payments/method",
        (data) => {
          this.methods = [];
          for (let m of data.methods) {
            this.methods.push(new PaymentMethod(m));
          }
        },
        () => {}
      );
    },

    handleCardInfo(title, token, vat) {
      this.errors = {};
      this.$showSpinner();
      this.$post(
        "/payments/method",
        {
          token: token,
          title: title,
          vat: vat
        },
        (data) => {
          this.methods.push(new PaymentMethod(data));
          this.unadd();
        },
        (errors) => {
          this.errors = errors;
        }
      );
    },

    save() {
      this.$refs.cc.save();
    },

    del(method) {
      if(method.main) return
      
      this.$post(
        "/payments/method/" + method.id,
        {
          _method: "DELETE",
        },
        (data) => {
          this.methods = [];
          for (let m of data.methods) {
            this.methods.push(new PaymentMethod(m));
          }
        },
        () => {}
      );
    },

    main(method) {
      this.$post(
        "/payments/method/" + method.id,
        {
          _method: "PUT",
        },
        (data) => {
          this.methods = [];
          for (let m of data.methods) {
            this.methods.push(new PaymentMethod(m));
          }
        },
        () => {}
      );
    },
  },
};
</script>