import Vue from 'vue'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueClipboard from 'vue-clipboard2'
import VueMask from 'v-mask'
import Misc from './plugins/Misc'
import Toasted from 'vue-toasted';
import PortalVue from 'portal-vue'

import "@/assets/scss/_theming.scss";
import i18n from './i18n'

import routes from './components/helpers/Router';
const router = new VueRouter({
  routes,
  mode: 'history'
  //mode: 'hash'
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = localStorage.getItem('token')

    // trello №76 isPublicPage
    if (to.name !== 'home' && !isAuthenticated && !to.meta?.isPublicPage) {
      // Запоминаем страницу на которую человек хотел перейти,
      // чтоб после авторизации сделать редирект на нее.
      localStorage.setItem('redirectTo', to.fullPath)

      //if(to.meta?.isUserPage) next()
      //else next({ path: '/' })
      next({ path: '/' })
    }
    else next()
  })

Vue.use(Vuex)
Vue.use(Toasted)

import stores from './components/helpers/Store';
import './registerServiceWorker'
const store = new Vuex.Store(stores);

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.use(VueClipboard)
Vue.use(Misc);
Vue.use(VueMask);
Vue.use(PortalVue);

new Vue({
  router,
  i18n,
  store,
  Toasted,
  render: h => h(App),
    mounted() {
        this.$isStandalone() ?
        document.body.classList.add('standalone') :
        document.body.classList.add('browser');

    // 9 / 16 screen = 0.5625
    let aspect = window.screen.width / window.screen.height < 0.5625 ? 'aspect-wide' : 'aspect-default'
    document.body.classList.add(aspect);
    }
}).$mount('#app')
