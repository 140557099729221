<template>
  <div class="nd-become-a-model">
    <b-row class="sticky-top">
        <div class="nd-main-header-container">
          <div class="nd-background nd-main-header">
            <b-link @click="$router.go(-1)">
              <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
              Become A Model
            </b-link>
          </div>
        </div>
    </b-row>

    <div class="nd-become-a-model-container nd-background">
      <b-row>
        <b-col cols="12">
          <div class="nd-become-header">
            <div class="nd-bg-img"></div>
            <div class="nd-bg-gradient"></div>
            <div class="nd-become-text">
              Apply now and be a model on fanspicy and we will do the rest for you!<br> 
              By becoming a fanspicy model you will earn a minimum amount of<br> <span class="blue">2000$</span> and the limit is the sky.<br> 
              Our top models earn <span class="blue">100k $</span> monthly on fanspicy!
              <b-link v-if="isLoggedIn" class="nd-become-button" to="/payouts">Application</b-link>
              <b-link v-else class="nd-become-button" to="/signup">Application</b-link>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" xl="6" >
          <div class="nd-become-box">
            <div class="nd-become-box-img"><img src="~@/assets/icons/new-design/nd-dollar.svg" /></div>
            <div class="nd-become-box-title">80% is yours</div>
            <div class="nd-become-box-text">Fanspicy takes a 20% fee of your earnings that includes proccesing and marketing to target your right fans. You will earn 80% of your subscription and post sales earnings plus your DMs.</div>
          </div>
        </b-col>

        <b-col cols="12" xl="6" >
          <div class="nd-become-box">
            <div class="nd-become-box-img"><img src="~@/assets/icons/new-design/nd-speech-bubble.svg" /></div>
            <div class="nd-become-box-title">You can talk in all the languages!</div>
            <div class="nd-become-box-text">With our autotranslate feature you can talk to anyone on this planet while typing with your language. For example you will be texting people in Portuguese and they will get your text with the exact translation in their own languages!</div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="nd-faq-title">Becoming a Model FAQ</div>

          <div class="nd-become-collapse">
            <div class="nd-become-collapse-title" v-b-toggle.collapse-1>
              How much money will I earn with Fanspicy?
              <img src="~@/assets/icons/new-design/nd-become-plus.svg" />
            </div>
            <div>
              <b-collapse visible id="collapse-1">
                <div class="nd-become-collapse-text">You will earn 80% of your subscription and post sales earnings plus your DMs.</div>
              </b-collapse>
            </div>
          </div>

          <div class="nd-become-collapse">
            <div class="nd-become-collapse-title" v-b-toggle.collapse-2>
              What payouts methods are used for payouts?
              <img src="~@/assets/icons/new-design/nd-become-plus.svg" />
            </div>
            <div>
              <b-collapse id="collapse-2">
                <div class="nd-become-collapse-text">We can send your money by wire ( bank account ) by cash in some countries, and by paxum.</div>
              </b-collapse>
            </div>
          </div>

          <div class="nd-become-collapse">
            <div class="nd-become-collapse-title" v-b-toggle.collapse-3>
              Can i use the affiliate system even if i am not verified?
              <img src="~@/assets/icons/new-design/nd-become-plus.svg" />
            </div>
            <div>
              <b-collapse id="collapse-3">
                <div class="nd-become-collapse-text">Yes u can use the affiliate system even if your not verified you can send links and push traffic to earn 1% of what they spend or 5% of what creators earn ( soon ).</div>
              </b-collapse>
            </div>
          </div>

          <div class="nd-become-collapse">
            <div class="nd-become-collapse-title" v-b-toggle.collapse-4>
              How are my earning calculated?
              <img src="~@/assets/icons/new-design/nd-become-plus.svg" />
            </div>
            <div>
              <b-collapse id="collapse-4">
                <div class="nd-become-collapse-text">Your earnings can be retrieved after one week after payment, for example if u sold a video for 20$ on the 1st of march u will be able to take this 20$ on the 8 of march, after deducting 20% fees.</div>
              </b-collapse>
            </div>
          </div>

          <div class="nd-become-collapse">
            <div class="nd-become-collapse-title" v-b-toggle.collapse-5>
              How frequents are payouts?
              <img src="~@/assets/icons/new-design/nd-become-plus.svg" />
            </div>
            <div>
              <b-collapse id="collapse-5">
                <div class="nd-become-collapse-text">Payouts are manual, you ask for payout any time you want.</div>
              </b-collapse>
            </div>
          </div>


        </b-col>
      </b-row>    
    </div>
  </div>
</template>
<style lang="scss">
/*@keyframes animation-close {
  from {transform: rotate(0deg);}
  to {transform: rotate(44deg);}
}

@keyframes animation-open {
  from {transform: rotate(44deg);}
  to {transform: rotate(0deg);}
}*/

.nd-become-a-model {
  .nd-become-a-model-container {
    padding: 30px 20px;
    margin: 0 -15px;

    .nd-become-header {
      display: flex;
      justify-content: center;
      align-items: flex-end;
      overflow: hidden;
      position: relative;
      border-radius: 10px;
      width: 100%;
      height: 356px;
      .nd-bg-img {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0px;
        top: 0px;
        background: url(~@/assets/icons/new-design/back-view-happy-female-friends-beach-party1.png);
        background-repeat: no-repeat;
        background-size: contain;
      }

      .nd-bg-gradient {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(358.42deg, rgba(0, 0, 0, 0) 29%, rgba(0, 0, 0, 0.63) 52.21%, rgba(0, 0, 0, 0) 77.12%);
      }

      .nd-become-text {
        position: absolute;
        width: 705px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        .blue {
          color: #1E76FF;
        }

        .nd-become-button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 270px;
          height: 48px;
          background: linear-gradient(90.01deg, #AA0742 0.01%, #9E005D 37.03%, #ED1C24 94.3%);
          border-radius: 30px;
          margin: 34px auto 57px auto;
          color: white;
        }
      }
    }

    .nd-become-box {
      background: #E9EBF0;
      border-radius: 10px;
      padding: 20px 20px 30px 20px;
      margin-top: 40px;
      margin-bottom: 60px;
      height: 227px;
      .nd-become-box-img {
        width: 32px;
        margin: 0 auto 20px auto;
        height: 26px;
      }
      .nd-become-box-title {
        width: 199px;
        min-height: 40px;
        margin: 0 auto;
        margin-bottom: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        text-align: center;
        text-transform: uppercase;
        color: #333333;
        text-align: center;
      }
      .nd-become-box-text {
        margin: 0 auto;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #333333;
        text-align: center;
      }
    }

    .nd-faq-title {
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      color: #333333;
      text-align: center;
    }

    .nd-become-collapse {
      margin-top: 20px;
      border: 1px solid #E9EBF0;
      border-radius: 10px;

      .nd-become-collapse-title {
        position: relative;
        padding: 20px 20px 14px 20px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 23px;
        color: #333333;
        border-bottom: 1px solid #E9EBF0;
        img {
          position: absolute;
          right: 17px;
          height: 28px;
          width: 28px;
          margin-top: -4px;
          transform: rotate(44deg);
          /*animation-name: animation-open;
          animation-duration: 1s;*/
        }

        &.collapsed {
          border-bottom: 0;
          img {
            transform: rotate(0deg);
           /* animation-name: animation-close;
            animation-duration: 1s;*/
          }
        }
      } 

      .nd-become-collapse-text {
        padding: 16px 47px;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
      }
    }

  }
}

@media (max-width: 1200px) { 
  .nd-become-a-model {
    padding: 0 16px 120px 16px;

    .nd-become-a-model-container {
      padding: 21px 8px;


      .nd-become-header {

        .nd-bg-img {
          background: url(~@/assets/icons/new-design/back-view-happy-female-friends-beach-party-mobile1.png);
          background-repeat: no-repeat;
          background-size: 100%;
        }

        .nd-bg-gradient {

        }

        .nd-become-text {
          width: 295px;
          font-size: 14px;
          line-height: 18px;
          .nd-become-button {
            width: 173px;
            height: 32px;
            font-size: 14px;
            line-height: 18px;
            margin: 24px auto 52px auto;
          }
        }
      }

      .nd-become-box {
        margin-top: 30px;
        margin-bottom: 0;
        padding: 16px 16px 24px 16px;
        height: auto;

        .nd-become-box-img {

        }
        .nd-become-box-title {
          font-size: 16px;
          line-height: 18px;
        }
        .nd-become-box-text {

        }
      }

      .nd-faq-title {
        margin-top: 56px;
        font-size: 16px;
      }

      .nd-become-collapse {
        margin-top: 16px;

        .nd-become-collapse-title {
          padding: 16px 61px 8px 16px;
          font-size: 14px;
          line-height: 18px;
          height: 64px;
          display: flex;
          align-items: center;

          img {
            top: 19px;
            margin-top: 0;
          }

          &.collapsed {
            img {

            }
          }
        } 

        .nd-become-collapse-text {
          padding: 6px 32px 16px 32px;
        }
      }

    }
  }
}
</style>

<script>

export default {
  data: function () {
    return {

    };
  },
  computed: {
    isLoggedIn: function () {
      return this.$store.state.token != null;
    },
  },
  mounted() {

  },
  methods: {

  },
};
</script>
