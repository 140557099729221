<template>
  <div class="nd-background nd-form-uni nd-start-form nd-login">
    <div>
      <h1>Sign in</h1>
      <div v-if="errors._ && errors._.length > 0">
        <div class="nd-error-message" v-for="error in errors._" :key="error">
          {{ error }}
        </div>
      </div>
      <div v-if="errors['g-recaptcha-response']">
        <div class="nd-error-message" v-for="error in errors['g-recaptcha-response']" :key="error">
          {{ error }}
        </div>
      </div>

      <b-form class="nd-form-uni-body" @submit.prevent="beforeSubmit" id="login_form">
        <ui-form-input
          type="text"
          name="email"
          v-model="email"
          :errors="errors"
          :label="$t('general.email')"
        />
  
        <ui-password-input
          type="password"
          name="password"
          v-model="password"
          :errors="errors"
          :label="$t('general.password')"
        />

        <div class="form-group">
          <a class="nd-login-link" href="/forgot">{{ $t("general.forgot-password") }}</a>
        </div>
  
        <div class="form-group">
          <div class="nd-button big-btn w-100" @click="beforeSubmit">
            <span>{{ $t("general.login") }}</span>
          </div>
        </div>

        <div class="form-group">
          <GoogleLogin :params="google.params" :onSuccess="googleSuccess" :onFailure="failure" class="btn-google w-100">
            <b-img :src="google.icon" />
            {{ $t("general.sign-in-with-google") }}
          </GoogleLogin>
        </div>

        <div class="form-group text-center">
         <span class="nd-login-link-text">Don’t have an account?</span>  <a class="nd-login-link" href="/signup">{{ $t("general.create-new-account") }}</a>
        </div>

        <div class="form-group">
          <UiSwitchLanguage class="w-100" />
        </div>

        <div class="g-recaptcha"
             :data-sitekey="recaptcha"
             data-callback="_onFormSubmit"
             data-size="invisible"
        ></div>
      </b-form>
    </div>
  </div>
</template>
<style scoped lang="scss">
.btn-google {
  height: 48px;
  border: 1px solid #CCD2E3;
  border-radius: 30px;
  position: relative;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-transform: uppercase;
  color: #2B2B2B;
  cursor: pointer;

    img {
      margin-right: 10px;
    }
}
.nd-login-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #1E76FF;
}

.nd-login-link-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2B2B2B;
}

@media (max-width: 1200px) {
  .nd-login-link {
    font-size: 12px;
    line-height: 16px;
  }

  .nd-login-link-text {
    font-size: 12px;
  }

  .btn-google {
    height: 32px;
    font-weight: 700;
    font-size: 14px;

    img {
      margin-right: 6px;
      width: 16px;
      height: 16px;
    }
  }
}

</style>

<script>
import GoogleLogin from "vue-google-login";
import User from "../models/User";
import UiFormInput from "../ui/UiFormInput.vue";
import UiSwitchLanguage from "../ui/UiSwitchLanguage.vue";
import UiPasswordInput from "@/components/ui/UiPasswordInput.vue";
export default {
  components: {UiPasswordInput, UiFormInput, GoogleLogin, UiSwitchLanguage },
  data() {
    return {
      email: "",
      password: "",
      errors: {},
    };
  },
  emits: ["handleError"],
  created() {
    window._onFormSubmit = this.submitForm
  },

  computed: {
    google() {
      return {
        params: {
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          scope: "profile email",
        },
        renderParams: {
          longtitle: true,
          height: 36,
          theme: "dark",
        },
        icon: require("@/assets/google.svg"),
      };
    },
    recaptcha() {
      return process.env.VUE_APP_RECAPTCHAV2_SITEKEY;
    }
  },

  methods: {
    beforeSubmit() {
      window.grecaptcha.execute();
    },
    submitForm(responseToken) {
      this.login(User.CHANNEL_EMAIL, responseToken);
    },
    googleSuccess(googleUser) {
      this.login(
        User.CHANNEL_GOOGLE,
        googleUser.getAuthResponse().access_token
      );
    },
    login(type, token) {
      this.errors = {};
      let fields = {
        channel_type: type,
      };
      if (type == User.CHANNEL_EMAIL) {
        fields.email = this.email;
        fields.password = this.password;
        fields.action = 'login';
        fields['g-recaptcha-response'] = token;
      } else {
        fields.token = token;
      }

      this.$post(
          "/auth/login",
        fields,
        (data) => {
          this.$saveToken(data.token);
          this.$saveUser(data.user);

          // После успешной авторизации делаем редирект на страницу
          // на которую пользователь хотел перейти до авторизации.
          const redirectTo = localStorage.getItem('redirectTo')
          if(redirectTo) {
            this.$router.push(redirectTo)
            localStorage.setItem('redirectTo', '')
          }

        },
        (errors) => {
          this.errors = errors;
          this.$emit("handleError");
          window.grecaptcha.reset();
        }
      );
    },
    failure() {
      this.$bvToast.toast(this.$t("general.login-failed"), {
        autoHideDelay: 2000,
        title: this.$t("general.error"),
        solid: true,
        toaster: "b-toaster-bottom-left",
      });
    },
  },
};
</script>
