<template>
  <div class="nd-user">
    <div class="header">
      <div class="cover w-100 position-relative">
        <b-img
          v-if="user && user.cover != null"
          :src="storageUrl + user.cover"
        />
        <div v-if="false" class="buttons m-2">
          <b-dropdown no-caret right variant="link">
            <template slot="button-content"
              ><i class="bi-three-dots-vertical text-white"
            /></template>
            <b-dropdown-item @click.prevent="copyLink">{{
              $t("general.copy-link-to-profile")
            }}</b-dropdown-item>
            <b-dropdown-item @click.prevent="addToList(user)">{{
              $t("general.add-to-list")
            }}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
    <div class="subprofile w-100 d-flex flex-column">
      <div class="avatar">
        <UiAvatar :user="user"/>
      </div>
      <div class="nd-user-username">
        <ui-username :user="user" :card-grid-name="true"/>
        <b-link :to="user.url" class="nd-user-username-line2">{{
          "@" + user.username
        }}</b-link>
      </div>
    </div>
    <div class="nd-button-line">
        <b-link class="nd-button" :to="user.url">{{$t("general.view-profile")}}</b-link>
    </div>

    <div class="nd-user-line" v-if="subscription">
        <div class="nd-button" 
          :class="{gray: !subscription.active && !subscription.isExpired}"
          @click.prevent="unsubscribe">
          {{ subscriptionButtonContent }}
        </div>

        <div class="nd-date">
          {{ subscriptionDate }}
        </div>

        <div class="nd-subscription-status">
        <div v-if="subscription.active">
          <i class="bi-check"/> {{$t("general.subscription-active")}}
        </div>
        <div v-else class="non-active">
          <i class="bi-x"/> {{$t("general.subscription-inactive")}}
        </div>
      </div>
    </div>

  

    <b-modal
      centered
      :title="$t('general.unsubscribe')"
      ref="modal"
      :ok-title="$t('general.unsubscribe')"
      @ok="doUnsubscribe()"
    >
      {{ $t("general.are-you-sure-unsubscribe", [user.name]) }}
    </b-modal>
  </div>
</template>
<style lang="scss">
.nd-user {
  background: #F5F5FB;
  border-radius: 10px;
  padding-bottom: 15px;

  .nd-subscription-status {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    color: #02B5F5;
    text-align: center;

    .non-active {
      color: #959FAF;
    }
  }

  .nd-date {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #959FAF;
    margin-top: 8px;
    text-align: center;
  }

  .nd-user-username {
    overflow: hidden;
    width: 100%;
    margin-top: 8px;

    .username {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #333333;
      height: 19px;
    }

    .nd-user-username-line2 {
      display: flex;
      justify-content: center;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #959FAF;
    }
  }

  .cover {
    height: 80px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    z-index: 1;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background:  #959FAF;
      pointer-events: none;
      content: "";
      border-top-left-radius: 10px !important;
      border-top-right-radius: 10px !important;
    }
    img {
      z-index: 2;
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }

  .nd-button-line {
    padding: 8px 12px;
    .nd-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      background: #1E76FF;
      border-radius: 30px;
      height: 32px;
         
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      color: #FFFFFF;
        
      
    }
  }

  .nd-user-line {
    padding: 0px 12px;
    .nd-button {
      width: 100%;
      height: 24px; 
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      background: #02B5F5;
      text-transform: inherit;
    }
  }

  .buttons {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 11;
    button {
      padding: 0;
    }
  }

  .avatar {
    margin-top: -50px;
    display: flex;
    justify-content: center;
    z-index: 2;

    .nd-avatar {
      width: 87px;
      height: 87px;

      .nd-is-creator {
        width: 16px;
        height: 23px;
        right: 7px;
      }

      .b-avatar-badge {
        img {
          height: 23px;
        }
      }

      .b-avatar {
        width: 87px;
        height: 87px;
        border: 3px solid white;

        &.badge-secondary {
          background: #E3E3EF;
        }
        .b-avatar-text {
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 31px;
          color: #1E76FF;
        }
      }
    }

  }

  .box-blue {
    display: inline-block;
    background: #E3F8FF;
    border-radius: 3px;
    font-size: 10px;
    line-height: 10px;
    padding: 4px 15px 4px 10px;
    &:last-child {
      margin-bottom: 5px;
    }
    &-num {
      color: #02B5F5;
    }
  }
  .subscription-status {
    font-size: 10px;
    line-height: 14px;
    font-weight: 500;
    text-align: center;
  }
  .unsubscribe-button {
    font-size: 12px;
    font-weight: 500;
  }
}

@media (max-width: 1200px) {
  .nd-user {
    .nd-user-line {
      padding: 0px 12px;
      .nd-button {
        font-weight: 500;
        font-size: 10px;
        line-height: 13px;
      }
    }
  }
}
</style>
<script>
import Subscription from "../models/Subscription";
import User from "../models/User";
import UiUsername from "./UiUsername.vue";
import UiAvatar from "./UiAvatar";
import Payment from "../models/Payment";
export default {
  components: {UiAvatar, UiUsername },
  props: {
    user: User,
    subscription: Subscription,
  },
  data() {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL
    }
  },
  computed: {
    subscriptionDate() {
      if (this.subscription.active || this.subscription.amount === 0) {
        return this.$t("general.started-on", [this.subscription.startedFormatted])
      }

      if (this.subscription.isExpired) {
        return this.$t("general.expired-on", [this.subscription.expiresFormatted])
      }

      return this.$t("general.expires-on", [this.subscription.expiresFormatted])
    },
    subscriptionButtonContent() {
      const amount = this.subscription.amount;
      const price = this.subscription.price;

      if (amount === 0 && price > 0) return this.$t("general.free-trial");

      if (this.subscription.active || !this.subscription.isExpired) {
        if (amount + price === 0) return this.$t("general.subscribed-for-x", [this.$t("general.free")]);

        return this.$t("general.subscribed-for-x", [process.env.VUE_APP_CURRENCY_SIGN + amount]);
      } else {
        return this.$t("general.re-subscribe");
      }
    }
  },
  methods: {
    subscribe(bundle, type = Payment.TYPE_SUBSCRIPTION_NEW) {
      if (type === Payment.TYPE_SUBSCRIPTION_PROMOTION) {
        return this.$buyItem({
          type: Payment.TYPE_SUBSCRIPTION_PROMOTION,
          user: this.user,
        });
      }

      if (this.user.isFree) {
        this.$post(
          "/subscribe/" + this.user.id,
          {},
          () => {
            this.reset();
            this.loadUser();
          },
          (errors) => {
            console.log(errors);
          }
        );
      } else {
        switch (type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$buyItem({
              type: Payment.TYPE_SUBSCRIPTION_NEW,
              user: this.user,
              bundle: bundle,
            });
        }
      }
    },

    copyLink() {
      const link = `${process.env.VUE_APP_APP_URL}${this.user.url}`;
      this.$copyText(link);
    },
    addToList(user) {
      this.$store.state.addToListUser = user;
    },
    unsubscribe() {
      if (!this.subscription.active && !this.subscription.isExpired) return;

      if (this.subscription.active) {
        this.$refs["modal"].show();
      } else {
        this.subscribe()
        //this.$emit("subscribe", this.subscription);
      }
    },
    doUnsubscribe() {
      this.$emit("unsubscribe", this.subscription);
    },
  },
};
</script>
