<template>
  <div class="nd-payments">
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ $t("general.payments") }}
        </b-link>
      </div>
    </div>

    <ui-payment-methods />
    <ui-payments-history />
  </div>
</template>
<script>
import UiPaymentMethods from "../ui/UiPaymentMethods.vue";
import UiPaymentsHistory from "../ui/UiPaymentsHistory.vue";
export default {
  components: { UiPaymentsHistory, UiPaymentMethods },
};
</script>

<style lang="scss">
.nd-payments {
  margin-left: -15px;
  margin-right: -15px;

  .nd-payment-card {
    margin-bottom: 20px;

    .card-header {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      text-transform: uppercase;
      color: #333333;
      border: 0;
      background: none;
      padding: 20px;
    }

    .card-body {
      padding: 0 20px 20px 20px;

      .nd-payment-card-line {
        display: flex;
        align-items: center;
        padding: 12px 0;

        img {
          margin-right: 12px;
        }

        .nd-payment-card-number {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          color: #333333;
          margin-right: 12px;
        }

        .nd-payment-card-is-main {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 18px;
          color: #959FAF;
        }

        .nd-payment-card-btn {
          font-style: normal;
          font-weight: 700;
          font-size: 12px;
          line-height: 12px;
          text-transform: uppercase;
          color: #FFFFFF;
          margin-right: 12px;
          height: 24px;
          width: 110px;
        }
      }

      .nd-payment-card-btn-custome {
          width: 132px;
          height: 48px;
          font-weight: 700;
          font-size: 18px;
          line-height: 23px;
          margin-right: 20px;
        }
    }

    &.history {
      .card-body {
        padding: 0 0 20px 0;
      }
    }
  }
}

@media (max-width: 1200px) {
  .nd-payments {
    margin-bottom: 150px;
    margin-left: 1px;
    margin-right: 1px;

    .nd-payment-card-line {
      &.mobile-block {
        display: block!important;

        .btns {
          margin-top: 15px;
        }
      }
    }

    .nd-payment-card {
      margin-bottom: 16px;
      .card-header {
        padding: 16px 9px;
      }
      .card-body {
        padding: 0 9px 16px 9px;
        .nd-payment-card-btn-custome {
            height: 32px;
            width: 100%;
            font-size: 14px;
            line-height: 18px;
            &:last-of-type {
              margin-right: 0;
            }
          }

          .custome-btns {
            display: flex;
          }
      }
    }



    
  }
}
</style>
