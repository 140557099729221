<template>
<div class="nd-background payouts-status">
  <div class="card">
    <div class="card-header flex">
      <div>{{ $t('general.pending-balance') }}: {{$formatAmount(stats.balance)}}</div> 
      <div class="payouts-status-icon" @click="payoutsStatusInfo = !payoutsStatusInfo">
        <svg width="17" height="17" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 0C2.6862 0 0 2.6862 0 6C0 9.3138 2.6862 12 6 12C9.3138 12 12 9.3138 12 6C12 2.6862 9.3138 0 6 0ZM6.6 9H5.4V7.8H6.6V9ZM6.6 6.6H5.4V3H6.6V6.6Z" fill="#1E76FF"/>
        </svg>
        <div v-if="payoutsStatusInfo" class="payouts-status-info">Earnings will pend one week till you can withraw them, for example if you get a subscriber on the 1st of july, you will be able to withrdraw the amount on the 8th of july</div>
      </div>
    </div>
  </div>
  <b-card
    :header="$t('general.current-balance') + ': ' + $formatAmount(stats.currentBalance)"
    class="no-border-radius-top bg-current-balance">
    <div>
      {{ $t("general.minimum-withdrawal") }} {{ $formatAmount(settings.payout) }}
      
    </div>
    <div v-if="stats.withdraw" class="text-primary">
      <i class="bi-clock-history" />
      {{ $t("general.pending-withdrawal") }} {{ $formatAmount(stats.withdraw.amount) }}
    </div>
    <div v-if="!stats.withdraw && canWithdraw" class="mt-3">
      <b-form inline>
        <b-form-input
          id="amount"
          v-model="amount"
          type="text"
          :state="errors['amount'] ? false : null"
          :placeholder="$t('general.amount')"
          style="width: 6rem"
          class="mr-2 mb-2"
        />

        <b-button
          variant="primary"
          @click.prevent="submitRequest"
          class="mb-2"
          >{{ $t("general.request-withdrawal") }}</b-button
        >
      </b-form>
    </div>
    <b-form-invalid-feedback class="d-block" v-if="errors._.length">
      <div v-for="error in errors._" :key="error">
        {{ error }}
      </div>
    </b-form-invalid-feedback>
  </b-card>
</div>
</template>

<script>
import Payout from "../models/Payout";
export default {
  props: ["value", "settings", "isPending"],
  data() {
    return {
      amount: "",
      errors: {_: []},
      payoutsStatusInfo: false
    };
  },
  computed: {
    canWithdraw() {
      return this.stats.currentBalance >= this.settings.payout;
    },
    stats: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },

  methods: {
    submitRequest() {
      this.errors._ = []
      if(this.amount > (this.stats.currentBalance / 100)) {
        this.errors._ = ['Insufficient funds']
        return
      }

      this.$post(
        "/payouts",
        {
          amount: this.amount,
        },
        (data) => {
          let s = { ...this.stats };
          s.withdraw = new Payout(data);
          this.stats = s;
          this.$emit('updatePyouts')
        },
        (errors) => {
          this.errors = errors;
        }
      );
    },
  },
};
</script>

<style lang="scss">
.payouts-status {
  .card {
    border: 0;
    border-radius: 10px;
  }

  .card-header {
    background: transparent;
  }

  .card-header, .card-body {
    border: 0;
    padding: 16px 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
    color: #333333;

    &:first-child {
      border-radius: 0;
    }
  }



  .payouts-status-info {
    font-weight: 500;
    font-size: 10px;
    line-height: 13px;
    background: #E9EBF0;
    border-radius: 10px;
    text-transform: uppercase;
    width: 222px;
    height: 84px;
    padding: 10px 8px;
    position: absolute;
    bottom: 16px;
    left: 22px;
    z-index: 9999;
    color: #333333;
  }

  @media (max-width: 768px) {
      .payouts-status-info {
        font-size: 6px;
        line-height: 8px;
        width: 154px;
        height: 57px;
        padding: 9px;
        bottom: 12px;
        left: 22px;
        padding: 5px 6px;

      }
  }

  .flex {
    display: flex;
  }

  .payouts-status-icon {
    cursor: pointer;
    position: relative;
    padding: 0 6px;
    margin-top: -1px;
    svg {
      position: relative;
      top: -1.5px;
    }
  }

  .no-border-bottom {
    border-bottom: 0;
  }
  .no-border-radius-bottom {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .no-border-radius-top {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .bg-current-balance {
    .card-header {
      background: #D6F4FF;
    }
  }
}

@media (max-width: 1200px) {
  .payouts-status {
    .card-header,  .card-body {
      font-size: 14px;
    }
  }

}
</style>
