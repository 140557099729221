<template>
  <div v-if="post != null" class="nd-page-post">
    <div class="nd-main-header-container sticky-top">
        <div class="nd-background nd-main-header">
          <b-link @click="$router.go(-1)">
            <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
            {{ $t("general.post") }}
          </b-link>
        </div>
      </div>

      <div v-if="post != null">
        <ui-post v-model="post" @del="del" />
        <ui-comments :postId="post.id" v-if="post.hasAccess" class="nd-background" />
      </div>

  </div>
</template>

<style lang="scss">
.nd-page-post {
  margin: 0 -15px;
}

@media (max-width: 1200px) {
  .nd-page-post {
    margin: 0 1px;

    .post {
      margin-bottom: 16px!important;
    }
  }

}
</style>

<script>
import Post from "../models/Post";
import UiPost from "../ui/UiPost.vue";
import UiComments from "../ui/UiComments.vue";
export default {
  data: function () {
    return {
      post: null,
    };
  },
  components: {
    UiPost,
    UiComments,
  },
  mounted() {
    this.loadPost();
    this.$verifyEmail()
  },
  methods: {
    loadPost() {
      this.$get(
        "/posts/" + this.$route.params.id,
        (data) => {
          this.post = new Post(data);
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    del() {
      this.$router.replace("/");
    },
  },
};
</script>
