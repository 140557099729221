<template>
  <div :class="{'encoding': encoding}">

    <vue-plyr :options="options"
              ref="plyr"
    >
      <video
          preload="none"
          controls
          playsinline
          :poster="media.screenshot"
      >
        <source v-for="(source, key) in sources"
                :size="key"
                :src="source ? storageUrl + source : source"
                :key="key"
                type="video/mp4"
        />
      </video>
    </vue-plyr>

  </div>
</template>

<script>
import first from 'lodash/first'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'

export default {
  name: "UiPlayer",

  props: {
    //object containing information about photo/video
    media: {
      type: Object,
    },

    //flag if encoding process is executing
    encoding: {
      type: Boolean,
      default: false
    },

    //flag if video is trailer
    isTrailer: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      storageUrl: process.env.VUE_APP_STORAGE_URL,
    }
  },
  
  components: {VuePlyr},

  created() {

  },

  beforeDestroy() {
    //Destroy the instance and garbage collect any elements.
    this.$refs.plyr.player.destroy()
  },

  mounted() {
    // Only one video is playing. Set another players on pause
    this.$root.$on('play', (id) => {
      if (this._uid !== id) {
        this.$refs.plyr.player.pause()
      }
    })

    this.$nextTick(() => {
      //onplay
      this.$refs.plyr.player.on('play', this.onPlay)

      //full screen entering
      this.$refs.plyr.player.on('enterfullscreen', this.onEnterFullScreen)

      //full screen exit
      this.$refs.plyr.player.on('exitfullscreen', this.onExitFullScreen)

      //background blurred image shown when video is playing
      if (this.media.screenshot_blur) {
        if (this.$el.querySelector('.plyr__video-wrapper'))
            // this.$el.querySelector('.plyr__video-wrapper').style.backgroundImage = 'url(https://picsum.photos/536/354)' //testing
          this.$el.querySelector('.plyr__video-wrapper').style.backgroundImage = 'url(' + this.media.screenshot_blur + ')'
      }

      this.setPlayerStyles()
    })

  },

  computed: {
    options: function () {
      return {
        previewThumbnails: {
          enabled: !!this.media.screenshot,
          src: this.media.screenshot || ''
        },
        fullscreen: {enabled: true, fallback: true, iosNative: true, container: null}
      }
    },

    sources: function () {
      if(this.isTrailer)
        return this.media.trailers

      return Object.keys(this.media.urls).length > 0 ? this.media.urls : [this.media.url]
    }
  },

  methods: {
    onPlay: function () {
      this.$root.$emit('play', this._uid)
    },

    onEnterFullScreen: function () {
      first(document.getElementsByTagName('html')).classList.add("fullscreen-open");
      this.setPlayerStyles()
    },

    onExitFullScreen: function () {
      first(document.getElementsByTagName('html')).classList.remove("fullscreen-open");
      this.setPlayerStyles()
    },

    setPlayerStyles: function() {

      let [volume, volumeControl, pipControl, airplayControl] = [
        this.$el.querySelector('.plyr__volume'),
        this.$el.querySelector('input[data-plyr="volume"]'),
        this.$el.querySelector('.plyr__control[data-plyr="pip"]'),
        this.$el.querySelector('.plyr__control[data-plyr="airplay"]'),
      ]

      if(this.$refs.plyr.player.fullscreen.active) {

        volumeControl ? volumeControl.style.display = 'block' : false
        pipControl ? pipControl.style.display = 'block' : false
        airplayControl ? airplayControl.style.display = 'block' : false
        volume ? volume.classList.remove('short') : false

      } else {

        volumeControl ? volumeControl.style.display = 'none' : false
        pipControl ? pipControl.style.display = 'none' : false
        airplayControl ? airplayControl.style.display = 'none' : false
        volume ? volume.classList.add('short') : false

      }
    }
  }

}
</script>

<style lang="scss">
.plyr{
  min-width: 180px;
}
.plyr:fullscreen .plyr__video-wrapper, .plyr:-webkit-full-screen .plyr__video-wrapper{
  height: 100% !important;
  max-height: unset !important;
}
.plyr__video-wrapper {
  background-size: cover;
  -webkit-background-size: cover;

  .fullscreen-open & {
    height: 100% !important;
    max-height: unset !important;
  }
}
.fullscreen-open .single-post__item .video-wrapper-multiple .plyr__video-wrapper{
  height: 100% !important;
}

.plyr__volume {
  &.short{
    width: auto;
    min-width: unset;
  }

  &.vertical{
    min-width: 30px;
    position: absolute;
    flex-direction: column;
    transform: rotate(-90deg) translate(10px, 10px);
  }
}

.plyr--video .plyr__controls{
  @media all and (max-width: 767px){
    padding: 15px 0 5px 0;
  }
}
</style>