<template>
  <div class="nd-form-uni nd-price-page">
    <div class="nd-main-header-container sticky-top">
      <div class="nd-background nd-main-header">
        <b-link @click="$router.go(-1)">
          <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
          {{ $t('general.bundles')}}
        </b-link>
      </div>
    </div>

    <div>
  
        <div class="nd-background">
          <div class="nd-form-uni-body">
            <h3 class="nd-price-page-title">{{ $t('general.sub-price') }}</h3>
            <div v-if="errors._ && errors._.length > 0">
              <div class="nd-error-message" v-for="error in errors._" :key="error">
                {{ error }}
              </div>
            </div>
            <b-form @submit.prevent="savePrice">
              <ui-form-input
                contentClass="nd-has-refix"
                type="text"
                name="price"
                v-model="user.price"
                :errors="errors"
                :prepend="currency"
                :label="$t('general.price-per-month')"
              />
              <div @click="savePrice" class="nd-button big-btn nd-price-page-form-btn">
                {{$t("general.save")}}
              </div>
            </b-form>
          </div>
        </div>

        <div class="nd-background nd-price-page-block">
          <div class="nd-form-uni-body">
            <h3 class="nd-price-page-title">{{ $t('general.subscription-bundles') }}</h3>
            <h4 class="nd-price-page-title-little">{{ $t('general.sub-price-description') }}</h4>

            <div>
              <div>
                <div
                    v-for="(item, index) in user.bundles"
                    :key="index"
                    class="mb-3 p-2 rounded border d-flex"
                >
                  <div class="flex-grow-1 mr-2">
                    {{ $t("general.get-x-months-for-y-z-off", item.title(user)) }}
                  </div>
                  <b-link @click.prevent="removeBundle(item)">
                    <i class="bi-x-circle-fill"/>
                  </b-link>
                </div>
              </div>

              <div>
                <div class="d-flex justify-content-center w-100 add-bundle-block">
                  <div class="w-100">
                    <div class="nd-price-page-text text-center">{{ $t('general.sub-bundles') }}</div>
                    <div class="mt-8">
                      <div @click="openAddBundleSettings(true)" class="nd-button big-btn nd-price-page-form-btn">
                        {{ $t("general.add") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!--bundle settings-->
              <b-modal
                  id="modalAddBundle"
                  ref="modalAddBundle"
                  dialog-class="nd-main-modal"
                  centered
                  :title="$t('general.add-bundle')"
                  v-model="addBundleSettingsOpened"
                  hide-footer
              >
                <div id="bundle_settings">
                  <div v-if="errorsBundles._ && errorsBundles._.length > 0">
                    <div class="nd-error-message" v-for="error in errorsBundles._" :key="error">
                      {{ error }}
                    </div>
                  </div>

                  <div>
                    <b-form @submit.prevent="addBundle">
                      <div class="nd-price-page-modal-form-row">
                        <b-form-select v-model="newBundle.discount" :options="options.discount" class="first"></b-form-select>
                        <b-form-select v-model="newBundle.months" :options="options.months"></b-form-select>
                      </div>
                      <div class="nd-price-page-modal-btns">
                        <div @click="openAddBundleSettings(false)" class="nd-button big-btn gray nd-price-page-modal-btn first-mobile">
                          {{ $t('general.cancel') }}
                        </div>

                        <div @click="addBundle" class="nd-button big-btn nd-price-page-modal-btn">
                          {{ $t('general.save') }}
                        </div>
                      </div>
                    </b-form>
                  </div>
                </div>
              </b-modal>
              <!--bundle settings end-->

            </div>
          </div>
        </div>

        <div class="nd-background nd-price-page-block">
          <div class="nd-form-uni-body">
            <h3 class="nd-price-page-title">{{ $t('general.promo-campaign') }}</h3>
            <h4 class="nd-price-page-title-little">{{ $t('general.promo-campaign-description') }}</h4>
            <div>
              <div>
              <!--
                 <div class="position-relative ml-3 rounded-circle d-flex align-items-center mb-3">
                  <UiAvatar size="72px"/>
                  <ui-username :user="user" :asLink="false" class="ml-3"/>
                </div>
              -->

                <div v-if="user.active_offer" class="mb-3 p-2 rounded border d-flex">
                  <div class="flex-grow-1 mr-2">
                    {{user.active_offer.message}}
                  </div>
                  <b-link @click.prevent="removeCampaign()">
                    <i class="bi-x-circle-fill"/>
                  </b-link>
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-center w-100 add-bundle-block">
                  <div class="w-100">
                    <div class="nd-price-page-text text-center">{{ $t('general.add-campaign') }}</div>
                    <div class="mt-8">
                      <div @click="openAddCampaignSettings(true)" class="nd-button big-btn nd-price-page-form-btn">
                        {{$t("general.add")}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--Profile Promotion Campaign settings-->
            <b-modal
                id="modalAddCampaignSettings"
                ref="modalAddCampaignSettings"
                dialog-class="nd-main-modal"
                centered
                :title="$t('general.promo-campaign')"
                v-model="addCampaignSettingsOpened"
                hide-footer
            >
              <div id="campaign_settings">
                <div v-if="errorsCampains._ && errorsCampains._.length > 0">
                  <div class="nd-error-message" v-for="error in errorsCampains._" :key="error">
                    {{ error }}
                  </div>
                </div>

                <div>
                  <b-form inline @submit.prevent="addCampaign" id="addCampaignSettings" style="display: block">
                      <div class="nd-price-page-modal-form-row">
                        <div>
                          <div class="radio-btn block">
                            <input type="radio" name="model" :id="Offer.FREE_TRIAL" :value="Offer.FREE_TRIAL"
                                  v-model="newBundle.selectedCampaignOption">
                            <label :for="Offer.FREE_TRIAL">{{ $t('general.free-trial') }} </label>
                          </div>
                          <div class="radio-btn block">
                            <input type="radio" name="model" :id="Offer.DISCOUNT_TRIAL"
                                  :value="Offer.DISCOUNT_TRIAL" v-model="newBundle.selectedCampaignOption">
                            <label :for="Offer.DISCOUNT_TRIAL">{{ $t('general.discounted-trial') }}</label>
                          </div>
                          <div class="radio-btn block">
                            <input type="radio" name="model" :id="Offer.DISCOUNT_MONTH"
                                  :value="Offer.DISCOUNT_MONTH" v-model="newBundle.selectedCampaignOption">
                            <label :for="Offer.DISCOUNT_MONTH">{{ $t('general.discounted-month') }}</label>
                          </div>
                        </div>  
                      </div>

                      <div class="nd-price-page-modal-form-row block">
                        <div class="row-margin-bottom">
                          <!-- trial of days till rebill (trial_days) -->
                          <UiSelect 
                            v-if="newBundle.selectedCampaignOption === Offer.DISCOUNT_TRIAL || newBundle.selectedCampaignOption === Offer.FREE_TRIAL"
                            :label="$t('general.till-rebill')"
                            v-model="newBundle.trialDays"
                            :options="duration"
                            name="trial_days"
                          />
                        </div>

                        <div class="row-margin-bottom">
                          <!-- price of trial days (price) -->  
                          <ui-form-input
                            contentClass="nd-has-refix" 
                            :label="$t('general.price-trial-days')"
                            v-model="newBundle.trialPrice"
                            v-if="newBundle.selectedCampaignOption === Offer.DISCOUNT_TRIAL"
                            :errors="errors"
                            :prepend="currency"
                            name="price"
                          />
                        </div>

                        <div class="row-margin-bottom">
                          <!-- Offer expiration (offer_days) -->
                          <UiSelect :label="$t('general.offer-expiration')"
                            v-model="newBundle.offerDays"
                            :options="duration"
                            name="offer_days"
                          />
                        </div>

                        <div class="row-margin-bottom">
                          <!-- First discounted month till rebill (percent)-->
                          <UiSelect :label="$t('general.month-till-rebill')"
                            v-model="newBundle.promotionDiscountPercent"
                            v-if="newBundle.selectedCampaignOption === Offer.DISCOUNT_MONTH"
                            :options="options.promotionDiscountPercent"
                            name="discount"
                          />
                        </div>

                        <div class="row-margin-bottom">
                          <legend>
                            <div v-if="newBundle.selectedCampaignOption === Offer.DISCOUNT_TRIAL || newBundle.selectedCampaignOption === Offer.FREE_TRIAL">
                              {{ $tc('general.x-days', newBundle.trialDays) }} for {{ $tc('general.x-dollars', newBundle.trialPrice) }}
                              {{ $t('general.sub-hint') }} "{{ $t('general.offer-last') }} {{ $tc('general.x-days', newBundle.offerDays) }}"
                            </div>
                            <div v-else>
                              {{$t('general.first-month-discounted')}} {{newBundle.promotionDiscountPercent}}% {{ $t('general.sub-hint') }}
                              "{{ $t('general.offer-last') }} {{ $tc('general.x-days', newBundle.offerDays) }}"
                            </div>
                          </legend>
                        </div>
                      </div>

                      <div class="nd-price-page-modal-btns">
                        <div @click="openAddCampaignSettings(false)" class="nd-button big-btn gray nd-price-page-modal-btn first-mobile">
                          {{ $t('general.cancel') }}
                        </div>

                        <div @click="addCampaign" class="nd-button big-btn nd-price-page-modal-btn">
                          {{ $t('general.save') }}
                        </div>
                      </div>
                  </b-form>
                </div>
              </div>
            </b-modal>
            <!--Profile Promotion Campaign settings end-->
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import Offer from '../models/Offer';
import UiFormInput from "../ui/UiFormInput.vue";
import UiSelect from "../ui/UiSelect";
/*import UiUsername from "../ui/UiUsername";
import UiAvatar from "../ui/UiAvatar";*/

export default {
  data: function () {
    return {
      addBundleSettingsOpened: false,
      addCampaignSettingsOpened: false,
      errors: {},
      newBundle: {
        discount: null,
        months: null,
        selectedSubsOption: 'new',
        selectedCampaignOption: Offer.FREE_TRIAL,
        trialDays: 1,
        offerDays: 7,
        trialDuration: 7,
        promotionDiscountPercent: 30,
        trialPrice: 0
      },


      options: {
        discount: {
          null: this.$t("general.discount"),
          10: "10%",
          20: "20%",
          30: "30%",
          40: "40%",
          50: "50%",
        },
        promotionDiscountPercent: {
          10: "10% Discount",
          20: "20% Discount",
          30: "30% Discount",
          40: "40% Discount",
          50: "50% Discount",
        },
        months: {
          null: this.$t("general.months"),
          3: this.$tc("general.x-months", 3),
          6: this.$tc("general.x-months", 6),
          12: this.$tc("general.x-months", 12),
        },
        subs: [
          {text: 'New subs..', value: 'new'},
          {text: 'Expired subs..', value: 'expired'},
          {text: 'Both new and expired', value: 'both'},
        ],
        campaign: [
          {text: 'Free trial..', value: 'free'},
          {text: 'First month discount', value: 'month'},
        ],
      },

      campaignMessage: '',
      errorsBundles: {},
      errorsCampains: {}
    };
  },

  created() {
    if(this.user?.role === 0) this.$router.go(-1)
    this.Offer = Offer;
  },

  computed: {
    user() {
      return this.$store.state.currentUser;
    },
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    duration() {
      return [...Array(30).keys()].map(x => ({
        value: ++x,
        text: this.$tc('general.x-days', x)
      }));
    }
  },

  components: {
   // UiAvatar,
   //UiUsername,
    UiSelect,
    UiFormInput,
  },

  watch: {
    'user.price'(){
      if(Number(this.user.price) === 0 && this.user.price !== ''){
        delete this.errors.price
      }
      else if (Number(this.user.price) < 4.99 || Number(this.user.price) > 100) {
        this.errors.price = [ `The value must be between ${4.99} and ${100}` ]
      }
      else {
        delete this.errors.price
      }
    }
  },

  methods: {
    savePrice() {
      if (this.errors?.price?.length) return   

      this.errors = {};
      this.$post(
          "/price",
          {
            price: this.user.price,
          },

          (data) => {
            this.$saveUser(data);
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            });
          },
          (errors) => {
            this.errors = errors;
          }
      );
    },
    openAddBundleSettings(bool) {
      this.errorsBundles = {}
      this.addBundleSettingsOpened = bool;
    },
    openAddCampaignSettings(bool) {
      this.errorsCampains = {}
      this.addCampaignSettingsOpened = bool;
    },
    addBundle() {
      this.errorsBundles = {}
      this.$post(
          "/price/bundle",
          {
            discount: this.newBundle.discount,
            months: this.newBundle.months,
          },

          (data) => {
            this.$saveUser(data);
            this.$refs.modalAddBundle.hide()
          },
          (errors) => {
            this.errorsBundles = errors
            console.log(errors);
          }
      );
    },
    removeBundle(bundle) {
      this.errorsBundles = {}
      this.$post(
          "/price/bundle/" + bundle.id,
          {
            _method: "DELETE",
          },

          (data) => {
            this.$saveUser(data);
            this.newBundle.discount = null;
            this.newBundle.months = null;
          },
          (errors) => {
            this.errorsBundles = errors
            console.log(errors);
          }
      );
    },
    addCampaign() {
      this.errorsCampains = {}
      this.$post(
          "/offer/create",
          Object.fromEntries(new FormData(document.getElementById('addCampaignSettings'))),

          (data) => {
            //this.user.price = this.user.price * 100 
            // this.$saveUser({...this.user, ...{active_offer: data.offer}});
            // this.$saveUser не подходит, потому что она ожидает другую структуру данных

            let currentUser = {...this.user, ...{ active_offer: data.offer }}
            localStorage.currentUser = JSON.stringify(currentUser)
            this.$store.commit('setUser', currentUser)

            this.$refs.modalAddCampaignSettings.hide()
            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            })
            // setTimeout(() => {
            //   location.reload()
            // }, 2000)
          },
          (errors) => {
            this.errorsCampains = errors
            console.log(errors);
          }
      );
    },

    removeCampaign() {
      this.errorsCampains = {}
      this.$post(
          "/offer/delete/" + this.user.active_offer.id,
          null,
          () => {
            //this.$saveUser({...this.user, ...{active_offer: null}});
            
            let currentUser = {...this.user, ...{ active_offer: null }}
            localStorage.currentUser = JSON.stringify(currentUser)
            this.$store.commit('setUser', currentUser)

            this.$bvToast.toast(this.$t("general.settings-saved"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            })

            // setTimeout(() => {
            //   location.reload()
            // }, 2000)
          },
          (errors) => {
            this.errorsCampains = errors
            console.log(errors);
          }
      );
    },

  },
};
</script>

<style lang="scss">

.nd-price-page-modal-form-row {
    display: flex;
    flex-direction: row;
    margin-bottom: 32px;

    .row-margin-bottom {
      margin-bottom: 20px;

      legend {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #333333;
        padding-bottom: 6px;
      }

      .form-group {
        display: block;
      }

      .input-group {
          .input-group-append {
            position: absolute;
            right: 10px;
            background: transparent;
            height: 100%;
            > div {
              border: 0;
            }
          }
        }

        label {
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          padding-bottom: 6px;
          margin-bottom: 0;
        }

        input:not([type='file']) {
          border: 1px solid #CCD2E3;
          border-radius: 30px!important;
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          color: #333333;
          padding: 15px 19px;
          height: 32px;
        }

      .nd-has-refix {
        .input-group {
          .input-group-prepend {
            position: absolute;
            z-index: 4;
            height: 100%;
            left: 6px;

            .input-group-text {
              font-style: normal;
              font-weight: 500;
              font-size: 14px;
              line-height: 18px;
              color: #333333;
              border: 0;
              background: none;
            }
          }

          .form-control {
            padding-left: 38px;
          }
        }
      }
    }

    &.block {
      display: block;
    }

    select {
      margin: 0!important;
      border: 1px solid #CCD2E3;
      border-radius: 30px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #333333;

      &.first {
        margin-right: 16px!important;
      }
    }
  }

  .nd-price-page-modal-btns {
    display: flex;
    justify-content: right;

    .nd-price-page-modal-btn {
      width: 132px;
      margin-left: 20px;
    }
  }

@media (max-width: 1200px) {
  .nd-price-page-modal-form-row {
      flex-direction: column;
      margin-bottom: 24px;

   

      .nd-has-refix {
        .input-group {
          .input-group-prepend {
            left: 17px!important;
          }
        }
      }


      select {

        &.first {
          margin-right: 16px !important;
          margin-bottom: 16px !important;
        }
      }
    }

    .nd-price-page-modal-btns {
      .nd-price-page-modal-btn {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        width: 100%;
        margin-left: 0;
        height: 32px;
        &.first-mobile {
          margin-right: 39px;
        }
      }
    }
}

.nd-price-page {
  color: #333333;

  .nd-price-page-block {
    margin-top: 20px;
  }

  .nd-price-page-title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  .nd-price-page-title-little {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
  }

  .nd-price-page-text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #333333;
  }

  .nd-price-page-form-btn {
    width: 270px;
    margin: 0 auto;
  }

  .add-bundle-block {
    margin-top: 50px;
  } 
}

@media (max-width: 1200px) {
  .nd-price-page {
    .nd-price-page-title-little {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  
    .nd-price-page-text {
      font-size: 12px;
      line-height: 16px;
    }
  
    .nd-price-page-form-btn {
      width: 100%;
      height: 32px;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
    }
  
    .add-bundle-block {
      margin-top: 16px;
    }
  }
}

</style>
