<template>
  <div class="nd-list" v-if="list != null">
    <b-row class="sticky-top">
        <div class="nd-main-header-container">
          <div class="nd-background nd-main-header">
            <b-link @click="$router.go(-1)">
              <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
              {{ list.title }}
            </b-link>
          </div>
        </div>
    </b-row>

    <b-row class="nd-list-items nd-background">
      <b-col
        xl="4"
        lg="4"
        cols="6"
        v-for="(item, index) in users"
        :key="index"
        class="nd-list-col"
      >
        <ui-user :user="item" />
      </b-col>
    </b-row>

  </div>
</template>
<style scoped lang="scss">
.nd-list {
  .nd-list-items {
    padding: 23px 5px;

    display: flex;
    flex-wrap: wrap;

    .nd-list-col {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 1200px) { 
  .nd-list {
    padding: 0 16px 120px 16px;

    .nd-list-items {
      padding: 16px 4px; 

      .nd-list-col {
        padding-right: 8px;
        padding-left: 8px;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
<script>
import List from "../models/List";
import User from "../models/User";
import UiUser from "../ui/UiUser.vue";
export default {
  components: { UiUser },
  data: function () {
    return {
      list: null,
      users: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadList();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadList() {
      this.isLoading = true;
      let users = [...this.users];
      this.$get(
        "/lists/" + this.$route.params.id + "?page=" + this.page,
        (data) => {
          this.list = new List(data.list, this);
          for (let u of data.users.data) {
            users.push(new User(u));
          }
          this.users = users;
          this.hasMore = data.users.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadList();
      }
    },
  },
};
</script>
