<template>
  <div v-if="comment != null" class="nd-comment">
    <div class="nd-comment-line1">
      <UiAvatar :user="comment.user"/>
      <div>
        <ui-username :user="comment.user"/>
        <div class="nd-username">@{{comment.user.username}}</div>
      </div>
    </div>

    <div>
      <div class="nd-comment-message" v-html="comment.messageParsed"></div>
      <div class="nd-comment-buttons">
        <div class="nd-comment-date">{{ comment.ago }}</div>
        <div class="nd-comment-likes" v-if="comment.likesCount">
          {{ $t("general.x-likes", [comment.likesCount]) }}
        </div>
        <a href="#" @click.prevent="reply">{{
          $t("general.reply")
        }}</a>
        <a href="#" @click.prevent="del" v-if="isOwner">{{
          $t("general.delete")
        }}</a>
        <a href="#" class="nd-comment-like" @click.prevent="like">
            <i class="bi-heart" v-if="comment.isLiked === false" />
            <i class="bi-heart-fill" v-else />
          </a>
      </div>
    </div>

  </div>
</template>

<style lang="scss">
.nd-comment {
  padding: 20px 20px 10px 20px;

  &:last-child {
    padding-bottom: 30px;
  }
  .nd-comment-line1 {
    display: flex;
    align-items: center;

    .nd-avatar {
      margin-right: 10px;
      width: 48px;
      height: 48px;
      .b-avatar {
        width: inherit;
        height: inherit;
      }

      .nd-is-creator {
          width: 13px;
          height: 18px;
      }

      .b-avatar-badge {
        img {
          height: 18px;
        }
      }
    }

    .nd-username {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #959FAF;
    }
  }

  .nd-comment-buttons {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    .nd-comment-date {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      color: #959FAF;
      margin-right: 16px;
    }

    .nd-comment-likes {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #1E76FF;
      margin-right: 16px;
    }

    a { 
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #1E76FF;
      margin-right: 16px;
      &.nd-comment-like {
          position: absolute;
          right: 0;
          margin-right: 0;
          i::before {
            font-weight: 800!important;
          }
        }
    }

  }

  .nd-comment-message {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      color: #333333;
      margin: 10px 0;

      a {
        color: #1E76FF!important;
      }
    }

}

@media (max-width: 1200px) {
  .nd-comment {
    padding: 8px 8px 10px 8px;
  }

}
</style>

<script>
import Comment from "../models/Comment";
import UiUsername from "./UiUsername.vue";
import UiAvatar from "./UiAvatar";
export default {
  components: {UiAvatar, UiUsername },
  props: {
    value: Comment,
  },
  computed: {
    comment: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isOwner: function () {
      return (
        this.$store.state.currentUser.isAdmin ||
        (this.value && this.value.user.id == this.$store.state.currentUser.id)
      );
    },
  },
  methods: {
    reply() {
      this.$emit("reply", this.comment);
    },
    like() {
      this.$post(
        "/comments/" + this.comment.id + "/like",
        {},

        (data) => {
          this.comment.isLiked = data.is_liked;
          this.comment.likesCount = data.likesCount;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    del() {
      this.$post(
        "/comments/" + this.comment.id,
        {
          _method: "DELETE",
        },
        () => {
          this.comment = null;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>
