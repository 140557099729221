<template>
  <div>
    <vue-pull-refresh v-if="isStandaloneIOS" 
      :on-refresh="onRefresh"
      :config="vprConfig"
    >
      <layout-authorized v-if="isLoggedIn"/>
      <layout-user-public v-else-if="isUserPublicPage"/>
      <layout-blanc v-else-if="isBecomeAModelPage"/>
      <layout-landing v-else/>
    </vue-pull-refresh>
    <div v-else>
      <layout-authorized v-if="isLoggedIn"/>
      <layout-user-public v-else-if="isUserPublicPage"/>
      <layout-blanc v-else-if="isBecomeAModelPage"/>
      <layout-landing v-else/>
    </div>
  </div>
</template>
<script>
import VuePullRefresh from 'vue-pull-refresh';
import LayoutAuthorized from "./LayoutAuthorized.vue";
import LayoutUserPublic from "./LayoutUserPublic.vue";
import LayoutLanding from "./LayoutLanding.vue";
import LayoutBlanc from "./LayoutBlanc.vue";

export default {
  computed: {
    isStandaloneIOS() {
      return window.navigator.standalone === true
    },
    isLoggedIn: function () {
      return this.$store.state.token != null;
    },
    vprConfig() {
      return {
        errorLabel: this.$t('general.error'),
        startLabel: '',//this.$t('general.loading'),
        readyLabel: '', //this.$t('general.loading'),
        loadingLabel: '', //this.$t('general.loading'),
      }
    },
    isUserPublicPage() {
      return !this.isLoggedIn && (this.$route.meta?.isUserPage || false)
    },
    isBecomeAModelPage() {
      return !this.isLoggedIn && (this.$route.meta?.isBecomeAModelPage || false)
    }
  },
  components: {LayoutAuthorized, LayoutLanding, VuePullRefresh, LayoutUserPublic, LayoutBlanc},
  mounted() {
    if (this.isLoggedIn) {
      this.$get(
          "/auth/me",
          (data) => {
            this.$saveUser(data);
          },
          () => {
          }
      );
    }
  },
  methods: {
    onRefresh() {
      return new Promise(function (resolve) {
        setTimeout(function () {
          location.reload()
          resolve();
        }, 1000);
      });
    }
  }
};
</script>