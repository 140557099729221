<template>
  <b-row class="nd-notify">
    <b-col>
      <div class="nd-main-header-container sticky-top">
        <div class="nd-background nd-main-header">
          <b-link @click="$router.go(-1)">
            <img src="~@/assets/icons/new-design/nd-arrow-left.svg" />
            {{ $t("general.notifications") }}
          </b-link>
        </div>
      </div>

      <div class="nd-background" v-if="notifications.length">
          <div v-for="(item, index) in notifications" :key="index" class="nd-notify-item border-bottom">
            <div class="nd-ago">{{ item.ago }}</div>
            <b-link :to="item.url">
              <i
                class="bi-dot mt-1 ml-n2 text-primary"
                style="font-size: 200%; line-height: 1"
                v-if="!item.isViewed"
              />

              <div class="nd-avatar-name">
                <div class="nd-notify-avatar">
                  <UiAvatar :user="item.items.user"/>
                </div>
                <div class="w-100 overflow-hidden">
                  <ui-username
                    :user="item.items.user"
                    :full="false"
                  />
                  <div class="nd-username-line2">{{ "@" + item.items.user.username}}</div>
                </div>
              </div>
              <div class="md-description">
                {{ $t(item.description[0], item.description[1]) }}
              </div>
            </b-link>
          </div>
      </div>
      <div  class="nd-background nd-no-notify-container" v-else>
        <div class="nd-no-notify-block">
          <img src="~@/assets/icons/new-design/nd-smile-sad.svg" />
          Nothing to see here yet, your notifications will be displayed here!
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<style lang="scss">
.nd-notify {
  margin-right: -30px;
  margin-left: -30px;


  .nd-no-notify-container{
    padding: 20px;
    min-height: calc(100vh - 187px);
  }

  .nd-no-notify-block {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background: rgba(30, 118, 255, 0.09);
    border-radius: 10px;
    height: 150px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #000000;
    padding: 0 20px;
    img {
      margin-bottom: 16px;
    }

  }

  .nd-notify-item {
    position: relative;
    padding: 20px 28px;

    .nd-ago {
      position: absolute;
      right: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #959FAF;
    }
  }

  .md-description {
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #333333;
  }

  .nd-avatar-name {
    display: flex;
    align-items: center;
  }

  .nd-notify-avatar {
    margin-right: 10px;
    width: 48px;
    height: 48px;
    .nd-avatar {
      width: inherit;
      height: inherit;
      .b-avatar {
        width: inherit;
        height: inherit;
        &.badge-secondary {
            background-color: #E3E3EF;
        }

        .b-avatar-text {
          color: #1E76FF;
        }

      }

      .nd-is-creator {
        width: 13px;
        height: 18px;
      }

      .b-avatar-badge {
        img {
          height: 18px;
        }
      }
    }
  }

  .nd-username-line2 {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #959FAF;
  }

  .nd-username-link {
    font-weight: 700;
  }

}

@media (max-width: 1200px) {
  .nd-notify {
    margin: 0 -16px 100px -16px;

    .nd-no-notify-container{
      padding: 16px 8px;
      min-height: calc(100vh - 220px);
    }

    .nd-notify-item {
        position: relative;
        padding: 8px 15px;
    }

    .md-description {
      margin-top: 8px;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
<script>
import Notification from "../models/Notification";
import UiUsername from "../ui/UiUsername.vue";
import UiAvatar from "../ui/UiAvatar";
export default {
  components: {UiAvatar, UiUsername },
  data: function () {
    return {
      notifications: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadNotifications();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadNotifications() {
      this.isLoading = true;
      if (this.page == 1) {
        this.$showSpinner();
      }
      this.$get(
        "/notifications?page=" + this.page,
        (data) => {
          let ns = [...this.notifications];
          for (let obj of data.data) {
            ns.push(new Notification(obj));
          }
          this.notifications = ns;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log("error");
          console.log(errors);
        }
      );
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadNotifications();
      }
    },
  },
};
</script>
