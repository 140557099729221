<template>
  <div class="nd-switch-language">
    <select v-model="currentLanguage" @change="onChange($event)">
      <option
        v-for="(name, lang) in langs"
        :key="`lang-${lang}`"
        :value="lang">
          {{ lang }}
      </option>
    </select>
  </div>
</template>

<style lang="scss">
.nd-switch-language {
  height: 20px;
  display: flex;
  
  select {
    margin: 0 16px!important;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 18px;
    color: #959FAF;
    height: 20px;
    width: 50px;
    background: #FFFFFF;
    border: 1px solid #CCD2E3;
    border-radius: 10px;
    padding-left: 5px;
  }
}
</style>

<script>
export default {
  name: 'SwitchLocale',
  data() {
    return {
      langs: {'en':'English', 'ar':'عربية', 'pt':'Portugeese', 'ro':'Romania'},
      currentLanguage: this.$i18n.locale || "en"
    }
  },
  methods:{
    onChange: function (event){
       this.$store.commit("SET_LANGUAGE_TO_TRANSLATE", event.target.value);
       this.$root.$emit("languageSwitched", 'ciao');
    }
  }
}
</script>
<style>
select {
    width: 100%;
    height: 20px;
    font-size: 10px;
    outline: 0;
    text-transform: uppercase;
    margin: 20px auto !important;
    margin-right: 20px;
}
select option { font-size: 14px; }


</style>
