<template>
  <div class="wrapper wrapper--auth">
    <b-container class="container-xl">
      <b-row>
        <b-col xl="3" class="pl-0 d-none d-xl-block" style="padding-right: 20px">
          <div class="sticky-top" style="top:20px">
            <router-link class="nd-button big-btn w-100" to="/become-a-model"><span>Become a model</span></router-link>
          </div>
        </b-col>
        
        <b-col :xl="6" cols="12">
          <router-view></router-view>
        </b-col>
        <b-col xl="3" class="pr-0 d-none d-xl-block bg-sticky-padding-top" style="padding-left: 20px">
          <div class="sticky-top" style="top:20px">
            <h5 class="nd-subscription-right">{{ $t("general.subscription") }}</h5>
            <div v-if="user" class="nd-button blue-bordered big-btn w-100" @click.prevent="subscribe">
              {{ $t("general.subscribe-for-x", [ user.isFree ? $t("general.free") : user.priceFormatted, ]) }}
            </div>

            <div class="nd-user-public-footer">
              <ul>
                <li><router-link class="black" to="/terms" target="_blank">{{ $t("general.terms") }}</router-link></li>
                <li><router-link class="black" to="/cookie" target="_blank">Cookie Notice</router-link></li>
                <li><router-link class="black" to="/privacy" target="_blank">{{ $t("general.privacy") }}</router-link></li>
              </ul>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
      content-class="nd-modal-buy nd-modal-login"
      id="modalLogin"
      static
      centered
      hide-footer
      v-model="visibleModalLogin"
      title=""
      ref="modal"
    >
    <template #modal-header-close>
      <img src="@/assets/icons/new-design/nd-close.svg" alt="close">
    </template>
    <div class="nd-modal-buy-content">
      <page-login />
    </div>
    </b-modal>
  </div>
</template>

<style lang="scss">
.nd-modal-login{
  .nd-background {
    box-shadow: none;
  }

  .nd-login{
    width: auto;
    height: auto;
  }
}


.nd-user-public-footer {
  ul {
    display: flex;
    list-style: none;
    padding:0;
    margin: 0;
    font-size: 12px;
    justify-content: space-between;
    margin-top: 8px;
  }
}

.nd-subscription-right{
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
  color: #333333;
}
.nd-dot {
  display: inline-block;
  background: #D6F4FF;
  border-radius: 100%; 
  position: relative;

  &.nd-dot-desktop {
    top: 2px;
    width: 16px;
    height: 16px;
  }
  
  &.nd-dot-mobile {
    top: -38px;
    left: 13px;
    width: 8px;
    height: 8px;
  }
}
</style>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";


@include media-breakpoint-down(md) {
.padding-bottom-15 {
  padding-bottom: 15px; 
} ;

  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
.sidebar-avatar {
  width: 72px;
  height: 72px;
  @include media-breakpoint-down(md) {
    height: 48px;
    width: 48px;
  }
}

.btn {
  padding: 0.575rem 0.75rem;
}

.nd-main-menu-block {
  .nav-link {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 0 0 20px 3px;
  color: #333333;
    &.router-link-active {
      &::before {
        display: block;
        content: ' ';
        position: absolute;
        width: 3px;
        height: 46px;
        background: #1E76FF;
        left: 0;
        top: -11px;
        border-radius: 10px;
        margin-left: -20px;
      }
        color: #1E76FF !important;
        .icon img {
          filter: invert(32%) sepia(91%) saturate(2106%) hue-rotate(207deg) brightness(100%) contrast(101%);
        }
    }
  }
}


.icon {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  .bi-dot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 3.5rem;
    line-height: 1;
    margin: -1.8rem;
  }
  .icon-footer {
    width: 24px;
    height: 24px;
  }
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
</style>
<script>
import PageLogin from "../../pages/PageLogin.vue";

export default {
  data() {
    return {
      visibleModalLogin: false,
    }
  },

  components: { PageLogin },

  computed: {
    user() {
      return this.$store.state.publicUser
    },

    spinner() {
      return this.$store.state.spinner == true;
    },
    
    updates() {
      return this.$store.state.updates;
    },
  },

  methods: {
    showModal() {
      this.visibleModalLogin = true
    },

    subscribe() {
      this.showModal()
    },
 
  },

};
</script>


