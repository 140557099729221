<template>
  <div>
    <div class="nd-pay-to-unlock">
      <img src="@/assets/icons/new-design/nd-lock.svg"/>
      Pay to unlock
    </div>
    <div class="locked-block test-icons">
      <img v-if="isPicture(type)" src="@/assets/icons/new-design/nd-camera.svg" class="icon">
      <img v-else-if="isVideo(type)" src="@/assets/icons/new-design/nd-video-camera.svg" class="icon">
      <span
          v-if="media.length > (isObject(media.trailers) ? 3 : 4) && key === (isObject(media.trailers) ? 2 : 3)"
          class="locked-available"
      >
      +{{ media.length - (isObject(media.trailers) ? 2 : 3) }}
    </span>
    </div>
  </div>
</template>

<script>
import isObject from 'lodash/isObject'

export default {
  name: "UiLocked",
  props: ['type', 'media'],
  created() {
    this.isObject = isObject
  }
}
</script>

<style lang="scss">
.nd-pay-to-unlock {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 16;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 137px;
  height: 38px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #FFFFFF;
  background: #02B5F5;
  border-radius: 10px 0px;

  img {
    width: 20px!important;
    height: 20px!important;
    margin-right: 8px;
  }
}
</style>