import { render, staticRenderFns } from "./PageMassMessage.vue?vue&type=template&id=24b3b4fb&scoped=true&"
import script from "./PageMassMessage.vue?vue&type=script&lang=js&"
export * from "./PageMassMessage.vue?vue&type=script&lang=js&"
import style0 from "./PageMassMessage.vue?vue&type=style&index=0&id=24b3b4fb&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24b3b4fb",
  null
  
)

export default component.exports