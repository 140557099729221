<template>
  <div class="charge-backs-table">
    <div class="charge-backs-header">
      <div class="date">{{$t('general.cb-payment-date')}}</div>
      <div class="date">{{$t('general.cb-dispute-date')}}</div>
      <div class="status">{{$t('general.cb-status-penalty')}}</div>
      <div class="value">{{$t('general.cb-value')}}</div>
    </div>
    <div class="charge-backs-row" v-for="(item, index) in chargeBacks" :key="index">
    <div>
      <div class="date">{{item.created_at.format("DD/MM/YYYY h:mm a")}}</div>
      <div class="date">{{item.updated_at.format("DD/MM/YYYY h:mm a")}}</div>
      <div class="status">
        <div>{{item.status_name}}</div>
        <div>{{formatAmount(item.penalty)}}</div>   
      </div>
      <div class="value">{{formatAmount(item.amount)}}</div>
    </div>
    <div class="user">{{$t('general.cb-from-user')}} {{item.username}}</div>
    </div>
  </div>
</template>
<script>
import ChargeBack from "../models/ChargeBack";
export default {
  data: function () {
    return {
      chargeBacks: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadChargeBacks();
    window.addEventListener("scroll", this.updateScroll);
  },

  beforeDestroy() {
      window.removeEventListener("scroll", this.updateScroll);
  },

  methods: {
    formatAmount(value) {
      if(!value && isNaN(value)) return ''
      return (value/100).toFixed(1) + process.env.VUE_APP_CURRENCY_SIGN
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadChargeBacks();
      }
    },
    loadChargeBacks() {
      this.isLoading = true;
      this.$get(
        "/earnings/refunded?page=" + this.page,
        (data) => {
          let chargeBacks = [...this.chargeBacks];
          for (let obj of data.data) {
            chargeBacks.push(new ChargeBack(obj));
          }
          this.chargeBacks = chargeBacks;
          this.hasMore = data.next_page_url != null;
          this.isLoading = false;
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
  },
};
</script>

<style lang="scss">
.charge-backs-table {
  .charge-backs-header {
    display: flex;
    border-bottom: 1px solid #D9D9D9;
    padding: 10px 0;
  }

  .charge-backs-row {
    border-bottom: 1px solid #D9D9D9;
    padding: 10px 0;
    > div {
      display: flex;
    }
  }


  > div {
    overflow: hidden;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    padding: 0 5px;
    
    
  }

  .date {
    width: 85px;
    text-align: center;
    padding-left: 0;
  }

  .value {
    width: 55px;
    padding-right: 0;
  }

  .status {
    min-width: 45%;
    width: auto;
    word-break: break-all;
    text-align: center;
  }
  .user {
    margin-top: 10px ;
  }
}

@media (max-width: 576px) { 
  .charge-backs-table {
    .charge-backs-header, .charge-backs-row {
      padding: 5px 0;
    }


    > div {
      font-size: 70%;
      line-height: 12px;
    }

    .date {
      width: 70px;
      text-align: center;
      padding-left: 0;
    }

    .value {
      width: 50px;
      padding-right: 0;
    }

    .status {
      min-width: 125px;
    }
  }
}


</style>
