<template>
  <div class="wrapper wrapper--auth">
    <b-container class="container-xl d-none d-xl-block">
      <b-row class="nd-header">
        <b-col xl="3" class="pl-0">
            <img src="~@/assets/logo-fanspicy.svg" class="nd-logo"/>
        </b-col>
        <b-col xl="6">
        </b-col>
        <b-col xl="3" class="pr-0">
          <div class="nd-header-right-block">
            <div>
              <router-link class="nd-settings-btn" to="/settings"><img src="~@/assets/icons/new-design/nd-gear.svg"/></router-link>
            </div>
            <div>
              <UiSwitchLanguage />
            </div>
            <div>
              <div class="nd-logout-btn" @click.prevent="logOut()"><img src="~@/assets/icons/new-design/nd-logout.svg"/>{{ $t("general.logout") }}</div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>



    <b-container class="container-xl">
      <b-row>
        <b-col xl="3" class="pl-0 d-none d-xl-block" style="padding-right: 20px">
          <div class="sticky-top bg-sticky-padding-top">
            <div class="nd-background d-flex flex-column w-100 align-items-center mb-20">
              <UiAvatar content-class="nd-avatar-main-menu"/>
              <div class="nd-user-name" v-b-toggle.sidebar>{{currentUser.name}}</div>
            </div>
            <div class="nd-background nd-main-menu-block">
              <b-nav class="" vertical>
                <b-nav-item to="/" exact>
                  <div class="icon d-inline-block">
                  <img src="~@/assets/icons/new-design/nd-home.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.home")
                  }}</span>
                </b-nav-item>
                <b-nav-item to="/notifications">
                  <div class="icon d-inline-block">
                    <img src="~@/assets/icons/new-design/nd-notifications.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.notifications")
                  }}</span>
                  <span v-if="updates.notifications > 0" class="nd-dot nd-dot-desktop ml-8"></span>
                </b-nav-item>
                <b-nav-item to="/messages">
                  <div class="icon d-inline-block">
                    <img src="~@/assets/icons/new-design/nd-messages.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{ $t("general.messages")}}</span>
                  <span v-if="updates.messages > 0" class="nd-dot nd-dot-desktop ml-8"></span>
                </b-nav-item>
                <b-nav-item to="/bookmarks" exact>
                  <div class="icon d-inline-block">
                  <img src="~@/assets/icons/new-design/nd-bookmark.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.bookmarks")
                  }}</span>
                </b-nav-item>
                <b-nav-item to="/lists">
                  <div class="icon d-inline-block">
                  <img src="~@/assets/icons/new-design/nd-list.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.lists")
                  }}</span>
                </b-nav-item>
                <b-nav-item to="/subscriptions">
                  <div class="icon d-inline-block">
                  <img src="~@/assets/icons/new-design/nd-subscriptions.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.subscriptions")
                  }}</span>
                </b-nav-item>
                <b-nav-item :to="currentUser.url" exact>
                  <div class="icon d-inline-block">
                  <img src="~@/assets/icons/new-design/nd-myprofile.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.my-profile")
                  }}</span>
                </b-nav-item>
                <b-nav-item v-b-toggle.sidebar>
                  <div class="icon d-inline-block">
                  <img src="~@/assets/icons/new-design/nd-more.svg">
                  </div>
                  <span class="d-none d-lg-inline ml-3">{{
                    $t("general.more")
                  }}</span>
                </b-nav-item>
  
              </b-nav>
              
              <div @click.stop="addPost" class="nd-main-menu-new-post-btn w-100">
                <img src="~@/assets/icons/plus.svg" class="mr-2 mb-1">
                <span class="">{{ $t("general.new-post") }}</span>
              </div>
                 <!-- <b-button
                    class="w-100 d-md-block d-sm-block d-lg-none p-2 accent-background accent-border"
                    :to="currentUser.isCreator ? '/posts/create' : '/payouts'"
                    variant="primary"
                  >
                    <div class="icon d-inline-block">
                      <i class="bi-plus" style="font-size: 150%; line-height: 1" />
                    </div>
                  </b-button>-->
            </div>
          </div>

        </b-col>
        <!--
                    :lg="
            $route.name == 'subscriptions' ||
            $route.name == 'list' ||
            $route.name == 'messages' ||
            $route.name == 'chat'
              ? 9
              : 6
          "
          md="9"
          sm="9"
          cols="12"
          class="border-left border-right content"
          для блока ниже
        -->
        <b-col :xl="
            $route.name == 'become-a-model' ||
            $route.name == 'subscriptions' ||
            $route.name == 'list' ||
            $route.name == 'messages' ||
            $route.name == 'chat' ||
            $route.name == 'payouts'
              ? 9
              : 6
          " cols="12">
          <router-view></router-view>
          <div class="spinner" v-if="spinner">
            <template v-if="['home', 'notifications', 'messages', 'bookmarks'].includes($route.name)">
              <b-spinner variant="secondary" />
            </template>
          </div>
        </b-col>
        <b-col xl="3" class="pr-0 d-none d-xl-block bg-sticky-padding-top" style="padding-left: 20px">
          <router-view name="side"></router-view>
        </b-col>
      </b-row>
    </b-container>

    <b-nav v-if="$route.name !== 'messages'" class="position-fixed footer w-100 nd-footer-mobile">
      <b-nav-item to="/" exact>
        <div>
          <img class="icon-footer" src="~@/assets/icons/new-design/nd-home.svg"/>
          <div>Home</div>
        </div>
      </b-nav-item>
      <b-nav-item to="/messages">
          <div>
            <img class="icon-footer" src="~@/assets/icons/new-design/nd-messages.svg">
            <div>Messages</div>
            <span v-if="updates.messages > 0" class="nd-dot nd-dot-mobile"></span>
          </div>
      </b-nav-item>
      <b-nav-item :to="currentUser.isCreator ? '/posts/create' : '/payouts'">
        <div class="nd-add-post-footer-btn">
          <img src="~@/assets/icons/new-design/nd-plus.svg">
        </div>
      </b-nav-item>
      <b-nav-item to="/notifications">
          <div>
            <img class="icon-footer" src="~@/assets/icons/new-design/nd-notifications.svg">
            <div>Notifications</div>
          </div>
          <span v-if="updates.notifications > 0" class="nd-dot nd-dot-mobile"></span> 
      </b-nav-item>
      <b-nav-item to="/search">
        <div>
            <img class="icon-footer" src="~@/assets/icons/new-design/nd-search.svg">
            <div>Search</div>
          </div>
      </b-nav-item>
    </b-nav>
    <app-menu />
  </div>
</template>

<style lang="scss">
.nd-dot {
  display: inline-block;
  background: #D6F4FF;
  border-radius: 100%; 
  position: relative;

  &.nd-dot-desktop {
    top: 2px;
    width: 16px;
    height: 16px;
  }
  
  &.nd-dot-mobile {
    top: -38px;
    left: 13px;
    width: 8px;
    height: 8px;
  }
}
</style>

<style scoped lang="scss">
@import "~@/assets/scss/_variables.scss";


@include media-breakpoint-down(md) {
.padding-bottom-15 {
  padding-bottom: 15px; 
} ;

  .nav {
    text-align: center;
  }
}
@include media-breakpoint-up(lg) {
  .nav {
    text-align: left;
  }
}
.sidebar-avatar {
  width: 72px;
  height: 72px;
  @include media-breakpoint-down(md) {
    height: 48px;
    width: 48px;
  }
}

.btn {
  padding: 0.575rem 0.75rem;
}

.nd-main-menu-block {
  .nav-link {
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 18px;
  padding: 0 0 20px 3px;
  color: #333333;
    &.router-link-active {
      &::before {
        display: block;
        content: ' ';
        position: absolute;
        width: 3px;
        height: 46px;
        background: #1E76FF;
        left: 0;
        top: -11px;
        border-radius: 10px;
        margin-left: -20px;
      }
        color: #1E76FF !important;
        .icon img {
          filter: invert(32%) sepia(91%) saturate(2106%) hue-rotate(207deg) brightness(100%) contrast(101%);
        }
    }
  }
}


.icon {
  position: relative;
  font-size: 1.4rem;
  line-height: 1.4rem;
  .bi-dot {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 3.5rem;
    line-height: 1;
    margin: -1.8rem;
  }
  .icon-footer {
    width: 24px;
    height: 24px;
  }
}
.footer {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
</style>
<script>
import AppMenu from "../AppMenu.vue";
import UiAvatar from "../../ui/UiAvatar";
import UiSwitchLanguage from "../../ui/UiSwitchLanguage.vue";

export default {
  computed: {
    spinner() {
      return this.$store.state.spinner == true;
    },
    currentUser() {
      return this.$store.state.currentUser;
    },
    updates() {
      return this.$store.state.updates;
    },
  },

  methods: {
    logOut() {
      this.$post(
        "/auth/logout",
        {},
        () => {},
        (errors) => {
          console.log(errors);
        }
      );
      this.$saveToken(null);
      this.$saveUser(null);
      //this.$router.replace("/");
      window.location.href = '/?logout'
      //location = process.env.VUE_APP_APP_URL;
    },

    addPost() {
      if(!this.$store.state.currentUser.isVerify) {
        this.$verifyEmail()
        return
      }
      else
      this.$router.push(this.currentUser.isCreator ? '/posts/create' : '/payouts')
    }
  },

  components: {UiSwitchLanguage, UiAvatar, AppMenu },
};
</script>


