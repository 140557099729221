<template>
  <!--<b-form-select class="nd-language-select" v-model="$i18n.locale" :options="langs" @change="onChange">
  </b-form-select>-->
<div class="nd-language-select" @click.stop="show">
    <div>{{lang}}</div>
  <ul v-if="isShow">
    <li v-for="lang in langs" 
      :key="lang.value"
      @click.stop="setLanguage(lang.value)"> {{lang.text}}</li>
  </ul>
</div>

</template>
<script>
export default {
  name: 'SwitchLocale',
  data() {
    let language = localStorage.getItem('language');
    if(language && language!==this.$i18n.locale){
      this.$i18n.locale = language;
    }

    return { 
      isShow: false,
      langs: [
        { text: 'عربية', value: 'ar' },
        { text: 'English', value: 'en' },
        { text: 'Portugeese', value: 'pt' },
        { text: 'Romania', value: 'ro' },
      ]
     }
  },

  computed: {
    lang() {
      let lang = this.langs.find(lang => lang.value === this.$i18n.locale)
      if(lang) return lang.text
      return this.$i18n.locale
    }
  },

  methods:{
    show() {
      this.isShow = !this.isShow
    },

    setLanguage(value) {
      this.show(false)
       this.$i18n.locale = localStorage.language = value
       this.$root.$emit("languageSwitched", 'ciao')
       this.$language('post')
       
    }
  }
}
</script>

