  <template>
  <b-form @submit.prevent="search" class="nd-app-search">
    <b-input-group>
      <b-form-input
        id="search"
        v-model="q"
        type="text"
        :placeholder="$t('general.search-posts')"
      />
      <b-input-group-append>
        <b-button type="submit" variant="icon" :disabled="this.q.length < 3">
          <img src="~@/assets/icons/new-design/nd-search.svg"/>
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </b-form>
</template>

<script>
export default {
  props: ["value"],
  computed: {
    q: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    search() {
      if (this.q.length >= 3) {
        this.$emit("search");
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .nd-app-search {
    margin-bottom: 10px;
    .form-control {
      border: 0;
      border-bottom: 1px solid #CCD2E3;
      border-radius: 0;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #959FAF;
    }
    .btn {
      border-bottom: 1px solid #CCD2E3;
      border-radius: 0;

      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      color: #959FAF;

      img {
        height: 21px;
        width: auto;
      }

      .text-muted {
        color:#333333!important;
      }

      &:disabled {
        opacity: 1;
      }
    }
  }

</style>