<template>
  <div>
    <b-modal
      content-class="nd-modal-buy"
      id="modalBuy"
      centered
      v-model="visible"
      :title="title"
      @ok.prevent="buy"
      ref="modal"
      @show="init"
    >
    <template #modal-header-close>
      <img src="@/assets/icons/new-design/nd-close.svg" alt="close">
    </template>
      <div class="nd-modal-buy-content">
        <div v-if="errors._ && errors._.length">
          <div class="nd-error-message" v-for="error in errors._" :key="error">
            {{ error }}
          </div>
        </div>

        <div v-if="!isTip" class="nd-description">
            <div>{{ description }}</div>
        </div>
        <div v-else>
          <div class="nd-profile-block">
            <UiAvatar :user="item.user"/>
            <div class="nd-username overflow-hidden">
              <ui-username class="line1" :user="item.user" :asLink="false" />
              <span class="line2">
                {{ "@" + item.user.username }}
              </span>
            </div>
          </div>

          <div class="nd-form-uni">
            <div class="nd-form-uni-body">
              <ui-form-input
                ref="refAmount"
                type="number"
                name="amount"
                :prepend="currency"
                v-model="amount"
                :errors="errors"
                :placeholder="$t('general.amount')"
                class="nd-has-refix"
              />

              <div class="nd-vat-text">{{ $t("general.VAT-warning-description") }}</div>

              <ui-form-input
                type="text"
                name="message"
                v-model="message"
                :errors="errors"
                :placeholder="$t('general.message-optional')"
              />
            </div>
          </div>
        </div>
        
        <div v-if="method == null">
          <div class="mb-20">
            <b-form-radio v-for="(item, index) in gateways"
              v-model="gateway"
              name="gateway"
              class="d-flex"
              :value="item.id"
              :key="index"
            >
              <div class="gateway">
                <img v-if="item.id != 'cc'" :src="info[item.id].logo" />
                <div v-else class="d-flex align-items-center">
                  <img class="mr-1" src="@/assets/icons/bank-card.svg" alt="bank-card">
                  {{ $t("general.credit-card") }}
                </div>
              </div>
            </b-form-radio>
          </div>
          <ui-credit-card
            v-if="gateway == 'cc'"
            ref="cc"
            :loading="loading"
            :isAutoCalcVAT="true"
            @handleCardInfo="handleCardInfo"
            @setVAT="handleVat"
            @setLoading="setLoading"
          />
        </div>

        <div class="nd-info-text">
          {{ `${$t("general.amount-to-be-charged")} (${$t("general.inclVat")} ${vatAmount}%): $${ priceIncludingVatToDisplay}` }}
        </div>
  
        <div class="nd-terms">
          <div>
            <b-form-checkbox size="sm" v-model="isCheckTerms"></b-form-checkbox>
          </div>
          <div>
            <i18n path="general.terms-notification">
              <template v-slot:termsLink>
                <a target="_blank" href="https://fanspicy.com/#/terms">{{ $t("general.terms") }}</a>
              </template>
            </i18n>
            <template v-if="!hidePeriodText">
              <span v-if="isSubscription"> and will be charged every 30 days</span>
              <span v-if="isBundle"> and will be charged every {{item.bundle.months}} month</span>
              <span v-if="isSpecialOffer"> and will appear on the bank statement as high-level tech and will be charged every 30 days</span>
            </template>
            <div class="is-not-check-terms" v-if="isShowTerms === 0">
              <span>
               <span>
                <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.6793 11.8383L9.08154 0.862C8.85129 0.47875 8.44704 0.25 8.00004 0.25C7.55304 0.25 7.14879 0.47875 6.91929 0.86125L0.320785 11.8383C0.0867854 12.2275 0.0807853 12.7142 0.304285 13.1095C0.527035 13.5048 0.947785 13.75 1.40229 13.75H14.5985C15.0523 13.75 15.4738 13.5048 15.6965 13.1095C15.92 12.7142 15.9133 12.2275 15.6793 11.8383ZM8.75004 11.5H7.25004V10H8.75004V11.5ZM8.75004 8.5H7.25004V4.75H8.75004V8.5Z" fill="#CA4D4D"/>
                </svg>
                please tick the box to agree on our Terms and conditions</span> 
              </span>
            </div>
          </div>
        </div>
  
        <!--<div v-if="isSpecialOffer" class="info-text p-0 mb-2 mx-3">
          <i18n path="general.special-offer-expires-in-x">
            <template v-slot:days>
              {{ specialOfferExpiresDays }}
            </template>
            <template v-slot:price>
              ${{ totalDue }}
            </template>
          </i18n>
        </div>-->
  
        <div v-if="isSpecialOffer" class="nd-info-text">
          Special offer expires in {{specialOfferExpiresDays || 30}} days, after it will be converted to monthly subscription price {{totalDue}}
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Payment from "../models/Payment";
import User from "../models/User";
import UiCreditCard from "./UiCreditCard.vue";
import UiFormInput from "./UiFormInput.vue";
import UiUsername from "./UiUsername.vue";
import UiAvatar from "./UiAvatar";

export default {
  components: {UiAvatar, UiCreditCard, UiUsername, UiFormInput },
  data: function () {
    return {
      gateway: null,
      gateways: [],
      method: null,
      errors: {},
      amount: "",
      message: "",
      isVatMessageShown: false,
      vat: 0,
      loading: false,
      info: {
        paypal: {
          logo: require("@/assets/paypal.svg"),
        },
      },
      isShowTerms: 2,// 0 - галочка НЕ установлена и показать алерт, 1 - галочка установлена, 2 - галочка НЕ установлена и НЕ показать алерт
      isCheckTerms: false, 
    };
  },
  computed: {
    visible: {
      get() {
        return this.$store.state.buyItem != null;
      },
      set() {
        this.$store.state.buyItem = null;
      },
    },
    currency() {
      return process.env.VUE_APP_CURRENCY_SIGN;
    },
    item() {
      return this.$store.state.buyItem;
    },
    price() { // returns a number
      if (this.isTip) return Number.parseFloat(this.amount) || 0;

      if (this.item?.type === Payment.TYPE_POST) return this.item.post.price;

      if (typeof this.item?.bundle?.title !== "function") { // If it isn't subscriptions bundle

        const price = this.item?.user?.price;

        return price;
      }

      const bundleAmount = this.item.bundle.title(this.item.user)[1].replace( /^\D+/g, '');
      return Number.parseFloat(bundleAmount);
    },
    priceIncludingVatToDisplay() {
      if (this.isSpecialOffer) 
        return this.specialTotalDue

      if (this.isTip) {
        const taxAmount = this.price / 100 * this.vatAmount
        const totalDue = this.price + taxAmount
        return totalDue
      }

      if(this.$store.state?.buyItem && this.$store.state.buyItem?.type === Payment.TYPE_MESSAGE) 
        return Number((this.$store.state.buyItem.message.price + (this.$store.state.buyItem.message.price / 100 * this.vatAmount)).toFixed(2))
      
      return (this.totalDue || 0).toFixed(1)
    },
    vatAmount() {
      if (typeof this.method?.vat !== 'undefined') {
        return this.method.vat;
      }

      return this.vat;
    },
    totalDue() {
      if (!this.vatAmount) return this.price;
      if (!this.price) return "";
      if (this.isTip) return this.amount;

      const priceNumber = this.price;

      const taxAmount = priceNumber / 100 * this.vatAmount;
      return priceNumber + taxAmount;
    },
    specialTotalDue() {
      if (!this.isSpecialOffer) return 0;

      const taxAmount = this.specialOfferPrice / 100 * this.vatAmount;
      return this.specialOfferPrice + taxAmount;
    },
    formattedAmount() {
      const totalDue = this.specialTotalDue || this.totalDue;

      const decimalNum = totalDue.toString()[4];
      let finalTotalDue = Number(totalDue);

      if (decimalNum && decimalNum !== "0") {
         finalTotalDue = Math.trunc(finalTotalDue * 10) / 10;
        finalTotalDue = (finalTotalDue * 10 + 1) / 10;
      }

      if (!this.isTip) {
        finalTotalDue = Math.trunc(finalTotalDue * 100);
      }

      return finalTotalDue;
    },

    hidePeriodText() {
      return this.item && (this.item.type == Payment.TYPE_TIP || this.item.type == Payment.TYPE_POST || this.item.type == Payment.TYPE_MESSAGE)
    },

    isTip() {
      return (
        this.$store.state.buyItem &&
        this.$store.state.buyItem.type == Payment.TYPE_TIP
      );
    },

    isSubscription() {
      return !this.isSpecialOffer && !this.isBundle
    },

    isSpecialOffer() {
      return this.item?.type === Payment.TYPE_SUBSCRIPTION_PROMOTION && this.specialOffer != undefined;
    },

    isBundle() {
      if(this.item?.bundle && 'months' in this.item?.bundle) return true 
      return false
    },

    specialOffer() {
      return this.item?.user?.active_offer;
    },
    specialOfferPrice() {
      return (this.specialOffer?.price || 0) / 100;
    },
    specialOfferExpiresDays() {
      return this.specialOffer?.trial_days;
    },
    title() {
      if (this.item) {
        switch (this.item.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$t("general.subscribe");
          case Payment.TYPE_SUBSCRIPTION_PROMOTION:
            return this.specialOffer?.message || '';
          case Payment.TYPE_POST:
            return this.$t("general.unlock-post");
          case Payment.TYPE_MESSAGE:
            return this.$t("general.unlock-message");
          case Payment.TYPE_TIP:
            return this.$t("general.tip");
        }
      }
      return '';
    },
    description() {
      if (this.$store.state.buyItem) {
        switch (this.$store.state.buyItem.type) {
          case Payment.TYPE_SUBSCRIPTION_NEW:
            return this.$t("general.subscription-to-x", [
              this.$store.state.buyItem.user.name,
              this.$store.state.buyItem.bundle &&
              this.$store.state.buyItem.bundle.months
                ? this.$store.state.buyItem.bundle.months
                : "1",
            ]);
          case Payment.TYPE_SUBSCRIPTION_PROMOTION:
            return this.$t("general.subscription-to", [
              this.$store.state.buyItem.user.name,
              this.specialOffer?.message || ''
            ]);
          case Payment.TYPE_POST:
            return this.$t("general.unlock-post-from-x-for-y", [
              this.$store.state.buyItem.post.user.name,
              this.$store.state.buyItem.post.priceFormatted,
            ]);
          case Payment.TYPE_MESSAGE:
            return this.$t("general.unlock-message-from-x-for-y", [
              this.$store.state.buyItem.message.user.name,
              this.$store.state.buyItem.message.priceFormatted,
            ]);
        }
      }
      return '';
    },
  },

  watch: {
    isCheckTerms() {
      if(this.isCheckTerms) this.isShowTerms = 1
      else this.isShowTerms = 2                                                          
    }
  },

  methods: {
    init() {
      this.resetModalData();
      this.loadGateways(() => {
        if (this.$store.state.buyItem) {
          switch (this.$store.state.buyItem.type) {
            case Payment.TYPE_SUBSCRIPTION_NEW:
              return this.amount = this.$store.state.buyItem.bundle.price || ''

            case Payment.TYPE_POST:
              return this.amount = this.$store.state.buyItem.post.price || ''

            case Payment.TYPE_MESSAGE:
              return this.amount = this.$store.state.buyItem.message.price || ''
          }
        }
      });
    },
    loadGateways(cb) {
      let gateways = [];
      this.$get(
          "/gateways",
          (data) => {
            for (let g of data.gateways) {
              gateways.push(g);
            }
          this.gateways = gateways;
          this.gateway = this.gateways[0].id;
          this.method = data.method;

          if(cb && typeof cb === "function")
            cb()

        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    handleVat(vat) { // returns false if there is no vat
      this.vat = vat || this.vatAmount;
    },
    handleCardInfo(title, token, vat, onlyShowVat) {
      this.handleVat(vat);

      if (onlyShowVat) return;
      if(!this.handleErrors()) return;

      this.proceed({
        gateway: "cc",
        title: title,
        token: token,
      });
    },
    handleErrors() {
      this.errors = {}

      switch (this.item.type) {
        case Payment.TYPE_TIP:
            if (Number(this.amount) < 5 || Number(this.amount) > 250) {
              this.errors.amount = [ `The value must be between ${5} and ${250}` ]
            }
          break
      }

      if (this.gateway === null) this.gateway = "cc";

      if (Object.keys(this.errors).length !== 0) return false;
      return true;
    },

    setLoading(value) {
      this.loading = value
    },

    buy() {
      if(!this.handleErrors()) return;

      if (this.loading) {
        return
      }

      this.loading = true;

      if(this.isShowTerms === 2 || this.isShowTerms === 0) {
        this.isShowTerms = 0
        this.loading = false;
        return
      }

      this.handleVat();

      if (this.method != null) {
        this.proceed({});
      }
      else if (this.gateway == "cc") {
        this.$refs.cc.save();
      }
      else {
        this.proceed({ gateway: this.gateway });
      }
    },
    proceed(fields) {
      this.loading = true;

      fields.type = this.item.type;
      fields.vat = this.vatAmount;

      switch (this.item.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          fields.sub_id = this.item.user.id;
          fields.amount = this.formattedAmount;

          if (this.item.bundle) {
            fields.bundle_id = this.item.bundle.id;
          }
          break;
        case Payment.TYPE_SUBSCRIPTION_PROMOTION:
          fields.sub_id = this.item.user.id;
          fields.amount = this.formattedAmount;
          fields.type = 0;
          break;
        case Payment.TYPE_POST:
          fields.post_id = this.item.post.id;
          fields.amount = this.formattedAmount;

          break;
        case Payment.TYPE_MESSAGE:
          fields.message_id = this.item.message.id;
          fields.amount = this.formattedAmount;

          break;
        case Payment.TYPE_TIP:
          fields.amount = this.formattedAmount;
          fields.message = this.message;
          fields.to_id = this.item.user.id;
          if (this.item.post) {
            fields.post_id = this.item.post.id;
          }
          break;
      }

      this.$showSpinner();
      this.$post(
        "/payments",
        fields,
        (data) => {    
          this.isCheckTerms = false 

          if('errors' in data) {
            this.errors = data.errors
            this.loading = false
            return
          }

          this.$showSuccess('Payment is done successfully')


          if (data.tip) {
           /* this.$bvToast.toast(this.$t("general.tip-sent"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            })*/

            if(typeof this.item?.success === 'function' ) this.item.success()
            this.visible = false;
            this.loading = false;
            return

          } else if (data.post) {
            this.item.success(data.post);

            //trello №78
           /* if(data.post.user.price === 0 && data.post.has_access) {
              this.$router.push(`${data.post.id}/${data.post.user.username}`)
            }
            else if(data.post.user.price > 0 && data.post.user.is_subscribed) {
              this.$router.push(`${data.post.id}/${data.post.user.username}`)
            }*/

            this.visible = false;
            this.loading = false;
            return

          } else if (data.message) {
            this.item.success(data.message)

            this.visible = false;
            this.loading = false;
            return

          } else if (data.user) {
            let u = new User(data.user);
            this.$router.push(u.url).catch(() => {
              this.$router.go();
            });
          }  else if(data.subscription){
            if(data.subscription?.sub?.username) {
              this.$router.push(`/${data.subscription?.sub?.username}`)
              this.visible = false
              this.loading = false
              return
            }
          } else {
            this.$router.go();
          }

          if (this.item.type === Payment.TYPE_SUBSCRIPTION_NEW || this.item.type === Payment.TYPE_SUBSCRIPTION_PROMOTION) {

           /* this.$bvToast.toast(this.$t("general.subscribed"), {
              autoHideDelay: 2000,
              title: this.$t("general.success"),
              solid: true,
              toaster: "b-toaster-bottom-left",
            });*/
          }

          if (data.redirect) {
            window.location.replace(data.redirect);
          } else {
            window.location.reload();
          }

          this.visible = false;
          this.loading = false;
        },
        (errors) => {
          this.errors = errors;
          this.loading = false;
        }
      );
    },
    resetModalData() {
      this.isShowTerms = 2;
      this.isCheckTerms = false;
      this.vat = 0;
      this.errors = {};
      this.amount = "";
      this.message = "";
    }
  },
};
</script>