<template>
  <b-row>
    <b-col>
      <NDAppHeaderMobile/>
      <b-row class="sticky-top page-header nd-page-header">
        <b-col class="pl-0 pr-0 nd-background-body bg-sticky-padding-top bg-sticky-padding-bottom">
          <b-nav horizontal class="sticky-top justify-content-center main-menu nd-background">
            <b-nav-item to="/" exact class="text-uppercase page-chapter home">
              {{ $t("general.home") }}
            </b-nav-item>
            <b-nav-item to="/creators" class="text-uppercase page-chapter creators">
              {{ $t("general.creators") }}
            </b-nav-item>
          <!--  <b-nav-item to="/reels" class="text-uppercase page-chapter ">
              {{ $t("general.reels") }}
            </b-nav-item>-->
          </b-nav>
        </b-col>
      </b-row>

      <b-row class="nd-background nd-creators-block">
        <b-col class="nd-creators-main-col">
          <b-input-group class="nd-search-users">
            <b-form-input v-model="searchText" placeholder="Search Creators..." @update="handleSearch" type="text"></b-form-input>
            <template #append>
              <b-input-group-text>
                <img src="~@/assets/icons/new-design/nd-search.svg">
              </b-input-group-text>
            </template>
          </b-input-group>
           
          <b-row class="nd-creators">
            <b-col class="nd-creaters-col" xl="4" cols="6" v-for="(item, index) in creators" :key="index">
              <ui-user :user="item" />
            </b-col>
          </b-row>

          <!-- loader-->
          <template v-if="isLoading && !searchText">
            <b-row class="creators loader">
              <b-col xl="4"
                    cols="6"
                    class="col"
                    v-for="loader in loaders"
                    :key="loader"
              >
                <ui-processing :type="'profile'"/>
              </b-col>
            </b-row>
          </template>
          <template v-if="isLoading && searchText">
            <div style="width: 100%; display: flex; align-items: center; justify-content: center; min-height: 100px">
              <b-spinner variant="secondary" />
            </div>
          </template>
       <!-- loader-->

        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<style lang="scss">
.nd-creators {
  &.loader{
    padding-bottom: 0;
    padding-top: 0;
  }

  margin-left: 1px;
  margin-right: -19px;
  .nd-creaters-col {
    padding: 0;
    padding-right: 20px;
    padding-bottom: 20px;
  }
}

.nd-creators-block {
  padding: 20px;
  margin-bottom: 100px;

  .nd-search-users {
    margin-bottom: 20px;
    height: 48px;
    background: #E9EBF0;
    border-radius: 30px;
    overflow: hidden;
    align-items: center;
    .form-control {
      border: 0;
      background: inherit;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #959FAF;
      padding-left: 20px;

      &:focus {
        color: #495057;
        background-color: inherit;
        border: 0;
        outline: 0;
        box-shadow: none;
      }
    }

    .input-group-append {
      .input-group-text {
        border: 0;
        padding-right: 17px;

        img {
          filter: brightness(0.4);
        }
      }
    }
  }

  .nd-creators-main-col {
    padding: 0;
  }
}

@media (max-width: 1200px) {
  .nd-creators-block {
    margin: 0;
    margin-bottom: 100px;
    margin-right: 1px;
    margin-left: 1px;
  }

  .nd-creators-block {
    padding: 16px 8px;
  }

  .nd-creators-block .nd-search-users {
      margin-bottom: 16px;
      height: 32px;
  }

  .nd-creators-block .nd-search-users .form-control {
    font-size: 12px;
    line-height: 16px;
    padding-left: 20px;
  }

  .input-group-text {
    padding: 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
}

  .nd-creators .nd-creaters-col {
    padding-right: 16px;
    padding-bottom: 16px;
  }
  .nd-user {
    padding-bottom: 6px;
  }

  .nd-user .nd-button-line .nd-button {
      height: 24px;
      font-size: 12px;
      line-height: 16px;
  }
}
</style>
<script>
import UiUser from "../ui/UiUser.vue";
import User from "@/components/models/User";
import UiProcessing from "../ui/UiProcessing";
import NDAppHeaderMobile from "@/components/layout/NDAppHeaderMobile.vue";

export default {
  components: {UiProcessing, UiUser, NDAppHeaderMobile },
  data: function () {
    return {
      creators: [],
      page: 1,
      isLoading: false,
      init: false,
      searchText: "",
    };
  },
  mounted() {
    this.loadList();
    window.addEventListener("scroll", this.updateScroll);
    this.$verifyEmail()
  },
  computed: {
    hasItems() {
      return this.creators.length > 0
    },
    loaders() {
      return [...Array(9).keys()]
    },
  },
  methods: {
    isMobile() {
      //Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini
      if(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },
    loadList() {
      if (this.isLoading) return;

      let mobile = ''
      if(this.isMobile()) mobile = '&paginate=mobile' 

      this.isLoading = true;
      if (this.page == 1) {
        this.$showSpinner();
      }

      if (this.searchText) {
        this.$post(
            "/creators/search",
            {
              value: this.searchText,
              page: this.page
            },
            (data) => {
              this.showCreators(data.creators);
              this.isLoading = false;
            },
            (errors) => {
              console.error(errors);
              this.isLoading = false;
            }
        );
      } else {
        this.$get(
            "/creators?page=" + this.page + mobile,
            (data) => {
              this.showCreators(data.creators)
              this.isLoading = false;
            },
            (errors) => {
              console.error(errors);
              this.isLoading = false;
            }
        );
      }
    },
    handleSearch() {
      this.creators = [];
      this.page = 1;
      this.loadList();
    },
    showCreators(creatorsToShow) {
      let creators = [...this.creators];
      for (let s of creatorsToShow.data) {
        creators.push(new User(s));
      }
      this.creators = creators;
      this.hasMore = creatorsToShow.next_page_url != null;
    },
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
          document.body.offsetHeight &&
          scrollPosition &&
          document.body.offsetHeight - scrollPosition <= 1000 &&
          !this.isLoading &&
          this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page += 1;
        this.loadList();
      }
    },
  },
};
</script>