<template>
  <b-card :header="$t('general.history')" class="nd-background nd-payment-card history">
    <div v-for="(item, index) in payments" :key="index" class="nd-payment-history-line">
      <div class="nd-payment-history-line-stat">
        <div class="flex-grow-1 cell">
          <div class="title-label">
            Date
          </div>
          <div class="text">
            {{ item.created_at.format("ll") }}
          </div>
          <div class="text">{{ item.created_at.format("LT") }}</div>
        </div>
        <div class="flex-grow-1 cell bordered">
          <div class="title-label">
            {{ $t("general.amount") }}
          </div>
          <div class="text">{{ $formatAmount(item.amount) }}</div>
        </div>
        <div class="flex-grow-1 cell center">
          <div class="title-label">
            Status
          </div>
          <div class="text">{{ item.event_status }}</div>
        </div>
      </div>
      <div class="nd-payment-history-line-descr">
        <i18n :path="getDescription(item)" tag="span">
          <template v-slot:user>
            <b-link :to="item.user.url">{{ item.user.username }}</b-link>
          </template>
          <template v-slot:to>
            <b-link :to="item.to.url">{{ item.to.username }}</b-link>
          </template>
          <template v-slot:post v-if="item.items.post">
            <b-link :to="item.items.post.url">{{ $t("general.post") }}</b-link>
          </template>
          <template v-slot:message>
            {{ $t("general.private-message") }}
          </template>
        </i18n>
      </div>
    </div>
  </b-card>
</template>
<script>
import Payment from "../models/Payment";
export default {
  data: function () {
    return {
      payments: [],
      page: 1,
      hasMore: false,
      isLoading: false,
    };
  },
  mounted() {
    this.loadPayments();
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    updateScroll() {
      const scrollPosition = window.innerHeight + window.scrollY;
      if (
        document.body.offsetHeight &&
        scrollPosition &&
        document.body.offsetHeight - scrollPosition <= 1000 &&
        !this.isLoading &&
        this.hasMore
      ) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.hasMore) {
        this.page = this.page + 1;
        this.loadPayments();
      }
    },
    loadPayments() {
      this.isLoading = true;
      this.$get(
        "/payments?page=" + this.page,
        (data) => {
          try {
            let payments = [...this.payments];
            for (let obj of data.data) {
              payments.push(new Payment(obj));
            }
            this.payments = payments;
            this.hasMore = data.next_page_url != null;
            this.isLoading = false;
          } catch (e) {
            console.log(e);
          }
        },
        (errors) => {
          console.log(errors);
        }
      );
    },
    getDescription2(payment) {
      switch (payment.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          return this.$t("general.payment-subscription-to", [
            `<a href="${payment.to.url}">${payment.to.username}</a>`,
          ]);
        case Payment.TYPE_SUBSCRIPTION_RENEW:
          return this.$t("general.payment-subscription-renew-to", [
            `<a href="${payment.to.url}">${payment.to.username}</a>`,
          ]);
        case Payment.TYPE_POST:
          return this.$t("general.payment-unlocked", [
            `<a href="${payment.items.post.url}">${this.$t(
              "general.post"
            )}</a>`,
          ]);
        case Payment.TYPE_MESSAGE:
          return this.$t("general.payment-unlocked-from", [
            this.$t("general.private-message"),
            `<a href="${payment.to.url}">${payment.to.username}</a>`,
          ]);
      }
      return "";
    },
    getDescription(payment) {
      switch (payment.type) {
        case Payment.TYPE_SUBSCRIPTION_NEW:
          return "general.payment-subscription-to";
        case Payment.TYPE_SUBSCRIPTION_RENEW:
          return "general.payment-subscription-renew-to";
        case Payment.TYPE_POST:
          return "general.payment-post-unlocked";
        case Payment.TYPE_MESSAGE:
          return "general.payment-message-unlocked";
        case Payment.TYPE_TIP:
          return "general.payment-tip-to";
      }
      return "";
    },
  },
};
</script>

<style lang="scss">
.nd-payment-history-line {
  .nd-payment-history-line-stat {
    display: flex;
    border-top: 1px solid #E9EBF0;
    border-bottom: 1px solid #E9EBF0;
    
    .cell {
      padding: 15px 20px;
      &.bordered {
        border-left: 1px solid #E9EBF0;
        border-right: 1px solid #E9EBF0;
      }

      &.center {
        text-align: center;
      }

      .title-label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #333333;
        margin-bottom: 9px;
      }

      .text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #000000;
      }
    }
  }

  .nd-payment-history-line-descr {
    padding: 15px 20px;
    border-bottom: 1px solid #E9EBF0;
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #000000;

    a {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #1E76FF;
      margin-right: 5px;
    }
  }
}

@media (max-width: 1200px)  {
  .nd-payment-history-line .nd-payment-history-line-stat .cell {
    padding: 12px 14px;
  }

  .nd-payment-history-line .nd-payment-history-line-descr {
    padding: 12px 14px;
}
}
</style>
