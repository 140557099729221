import User from "../models/User";

export default {
    state: {
        swiperItems: [],
        swiperOptions: {},
        currentUser: localStorage.currentUser != null ? new User(JSON.parse(localStorage.currentUser)) : null,
        token: localStorage.token != null ? localStorage.token : null,
        language: localStorage.language != null ? localStorage.language : null,
        addToListUser: null,
        buyItem: null,
        updates: {
            notifications: 0,
            messages: 0
        },
        spinner: false,
        pusher: null,

        languageToTranslate: localStorage.language || "",
        isLanguageTranslateTurnOn: false,
        publicUser: null
    },
    getters: {
        user: state => state.currentUser
    },
    mutations: {
        SET_LANGUAGE_TO_TRANSLATE(state, language) {
            state.languageToTranslate = language;
        },
        SET_LANGUAGE_TRANSLATE_STATUS(state, status) {
            state.isLanguageTranslateTurnOn = status;
        },
        setUser(state, data) {
            state.currentUser = data
        },
        setPublicUser(state, data) {
            state.publicUser = data
        },

    }
};