<template>
  <div @click.prevent="close()">
    <div>{{ info }}</div>
    <i class="bi-x-circle-fill"></i>
  </div>
</template>
<script>
export default {
  props: {
    info: {
      type: String,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
