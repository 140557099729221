<template>
<div>
    <div v-if="isDataLoaded" class="nd-from-to">
        <div class="from-to">
            <div class="blue">FROM</div>
            <div>
                <input type="date" v-model="fromLocal" />
            </div>
            <div class="blue">TO</div>
            <div>
                <input type="date" v-model="toLocal" />
            </div>
        </div>

       <b-container>
           <b-row>
                <b-col cols="12" v-for="item in earningsCustomDates" :key="item.key">
                    <div class="category-item-inline" @click="selectEarnings(item)">
                        <div class="item-title">{{item.name}}</div>
                        <div class="image"><img :src="item.icon"/></div>
                        <div class="item-amount">{{formatNumber(item.net)}}{{currency}}</div>
                    </div>
                </b-col>
                <b-col cols="12">
                <div class="category-item-inline bold" style="border: none;">
                    <div>{{ $t('general.antotal') }}</div>
                    <div style="text-align: center">{{ $t('general.anbrut') }} {{formatNumber(brut)}}{{currency}}</div>
                    <div>{{ $t('general.annet') }} {{formatNumber(net)}}{{currency}}</div>
                </div>
               </b-col>
           </b-row>
       </b-container>

        <portal to="window-portal">
            <div class="popup-background" v-if="isShowPopup">
                <div class="popup-block">
                    <div class="popup-title">
                        <div class="popup-close" @click="isShowPopup = false">
                            <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.893226 0L0 0.893226L4.60677 5.5L0 10.1068L0.893226 11L5.5 6.39323L10.1068 11L11 10.1068L6.39323 5.5L11 0.893226L10.1068 0L5.5 4.60677L0.893226 0Z" fill="#D9D9D9"/>
                            </svg>
                        </div>
                    </div>
                    <div class="popup-body" v-if="isPopupDataLoaded">
                        <div class="table-line table-line-period" v-for="(item, key) in selectedEarnings.dataList" :key="key">
                            <div>{{item.date}}</div>
                            <div>{{formatNumber(item.net)}}{{currency}}</div>
                        </div>
                    </div>
                    <div class="popup-body" v-else>
                        <div class="loader">
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        </portal>

    </div> 
  <div v-else>Loading...</div>
</div>
 
</template>
<script>
import subscribtions from '@/assets/icons/new-design/nd-subscribtions.svg'
import posts from '@/assets/icons/new-design/nd-posts.svg'
import messages from '@/assets/icons/new-design/nd-messages-a.svg'
import referrals from '@/assets/icons/new-design/nd-referrals.svg'
import tips from '@/assets/icons/new-design/nd-tips.svg'


export default {
    props: ['from', 'to'],
    data: function () {
        let now = new Date()
        return {
            currency: process.env.VUE_APP_CURRENCY_SIGN.replace('\\', ''),
            fromLocal: `${now.getFullYear()}-${(now.getMonth()+1) < 10 ? '0'+(now.getMonth()+1) : (now.getMonth()+1)}-01`,
            toLocal: `${now.getFullYear()}-${(now.getMonth()+1) < 10 ? '0'+(now.getMonth()+1) : (now.getMonth()+1)}-${(now.getDate()) < 10 ? '0'+(now.getDate()) : (now.getDate())}`,
            isShowPopup: false,
            isDataLoaded: true,
            isPopupDataLoaded: true,
            selectedEarnings: null,
            brut:0,
            net:0,

            earningsCustomDates: [
                {
                    id: 0,
                    key: 'subscribtions',
                    name: this.$t("general.ansubscribtions"),
                    icon: subscribtions,
                    amount: 0,
                    brut: 0,
                    net: 0,
                    dataList: []
                },

                {
                    id: 10,
                    key: 'posts',
                    name: this.$t("general.anposts"),
                    icon: posts,
                    amount: 0,
                    brut: 0,
                    net: 0,
                    dataList: []
                },

                {
                    id: 11,
                    key: 'messages',
                    name: this.$t("general.anmessages"),
                    icon: messages,
                    amount: 0,
                    brut: 0,
                    net: 0,
                    dataList: []
                },

                {
                    id: -1,
                    key: 'referrals',
                    name: this.$t("general.anreferrals"),
                    icon: referrals,
                    amount: 0,
                    brut: 0,
                    net: 0,
                    dataList: []
                },

                {
                    id: 20,
                    key: 'tips',
                    name: this.$t("general.antips"),
                    icon: tips,
                    amount: 0,
                    brut: 0,
                    net: 0,
                    dataList: []
                },

               /* {
                    id: -2,
                    key: 'total today',
                    name: this.$t("general.antotaltoday"),
                    icon: totaltoday,
                    amount: 0,
                    brut: 0,
                    net: 0,
                    dataList: [],
                    noRender: true
                },*/
            ]
        };
    },

    watch:{
        fromLocal() {
            if(this.fromLocal && this.toLocal) this.loadData()
        },
        toLocal() {
            if(this.fromLocal && this.toLocal) this.loadData()
        }
    },

    created() {
        if(this.from)
            this.fromLocal = this.from

        if(this.to)
            this.toLocal = this.to
            
         if(this.fromLocal && this.toLocal)
            this.loadData()

        //window.addEventListener("scroll", this.updateScroll);
    },
    methods: {
        getEarningsTypeStr(typeId) {
            /*
            TYPE_SUBSCRIPTION_NEW = 0;
            TYPE_POST = 10;
            TYPE_MESSAGE = 11;
            TYPE_TIP = 20;
            */
            switch(typeId) {
                case 0:
                    return 'subscribed with Amount'
                case 10:
                    return 'paid post with Amount'
                case 11:
                    return 'paid Messages with Amount'
                case 20:
                    return 'paid Tips with Amount'
                default:
                    return typeId
            }
        },

        selectEarnings(selectedEarnings) {
            if(this.fromLocal && this.toLocal) {
                this.selectedEarnings = selectedEarnings
                this.isShowPopup = true
                this.loadDataDetail()
            }
        },

        showPopup() {
            this.isShowPopup = !this.isShowPopup
        },

        formatNumber(num) {
            return Number(Number(num).toFixed(2))
        },

        loadData() {
            this.isDataLoaded = false;
            this.$post(
                "/analytics/period",
                {
                    date_from: `${this.fromLocal} 00:00:00`,
                    date_to: `${this.toLocal} 23:59:59`
                },
                (data) => {
                    this.earningsCustomDates.forEach(item => { item.amount = 0;  item.brut = 0; item.net = 0})
                    this.net = 0
                    this.brut = 0
                    if(data?.data && data?.data?.length)  {
                        this.earningsCustomDates.forEach(item => {
                            let dataValue = data.data.find(itemValue => itemValue.type === item.id)
                            if(dataValue) {
                                //item.amount = dataValue.amount
                                item.brut = dataValue.brut
                                item.net = dataValue.net
                            }

                           /* if(item.id === -2) {
                                for(let itemData of data.data) {
                                    if(Object.keys(itemData).includes('total')) {
                                        item.amount = itemData.total
                                    }    
                                } 
                            }*/
                        })
                        let sumBrut = 0
                        let sumNet = 0
                        this.earningsCustomDates.forEach(item => {
                            sumBrut += Number(item.brut)
                            sumNet += Number(item.net)
                        })

                        this.brut = this.formatNumber(sumBrut) 
                        this.net = this.formatNumber(sumNet) 
                    }
                    this.isDataLoaded = true
                },
                (errors) => {
                    this.isDataLoaded = true
                    console.log(errors)
                }
            );
        },

        loadDataDetail() {
            this.isPopupDataLoaded = false;
            this.$post(
                "/analytics/period",
                {
                    date_from: `${this.fromLocal} 00:00:00`,
                    date_to: `${this.toLocal} 23:59:59`,
                    type: this.selectedEarnings.id,
                    day: 1
                },
                (data) => {
                    this.selectedEarnings.dataList = data?.data || []
                    this.isPopupDataLoaded = true;
                },
                (errors) => {
                    this.isPopupDataLoaded = true;
                console.log(errors)
                }
            );
        },
  },
};
</script>

<style lang="scss">
    .table-line-period {
        div {
            width: 100%!important;
        }
    }
</style>